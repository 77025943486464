import React, { useState } from 'react';
import './pprofile.css';
import line from '../../media/line.svg';
import upload from '../../media/upload.svg';
import edit from '../../media/edit.svg';
import impos from '../../media/impos.jpg';
import {
  initializeIcons,
  createTheme,
  DatePicker,
} from "@fluentui/react";


initializeIcons();

const theme = createTheme({
  palette: {
    themePrimary: "#169c7c",
    themeLighterAlt: "#f2fbf9",
    themeLighter: "#ceefe7",
    themeLight: "#a7e1d4",
    themeTertiary: "#5fc3ac",
    themeSecondary: "#29a78a",
    themeDarkAlt: "#148c70",
    themeDark: "#11765e",
    themeDarker: "#0c5746",
    neutralLighterAlt: "#323232",
    neutralLighter: "#3a3a3a",
    neutralLight: "#484848",
    neutralQuaternaryAlt: "#505050",
    neutralQuaternary: "#575757",
    neutralTertiaryAlt: "#747474",
    neutralTertiary: "#ececec",
    neutralSecondary: "#efefef",
    neutralPrimaryAlt: "#f2f2f2",
    neutralPrimary: "#e3e3e3",
    neutralDark: "#f9f9f9",
    black: "#fcfcfc",
    white: "#292929"
  }
})


const PProfile = () => {
  const [isPersonalDetailEditing, setPersonalDetailEditing] = useState(false);
  const [isAddressEditing, setAddressEditing] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    firstName: 'Ujjwal',
    lastName: 'Saini',
    email: 'ujjwalsaini411@gmail.com',
    phone: '8800899170',

  });

  const [addressDetails, setAddressDetails] = useState({
    address1: '   ---',
    address2: '   ---',
    landmark: '   ---',
    city: '   ---',
    state: 'NY',
  });

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'personalDetail') {
      setPersonalDetails({
        ...personalDetails,
        [name]: value,
      });
    } else if (section === 'addressDetail') {
      setAddressDetails({
        ...addressDetails,
        [name]: value,
      });
    }
  };

  const toggleEditMode = (section) => {
    if (section === 'personalDetail') {
      setPersonalDetailEditing(!isPersonalDetailEditing);
    } else if (section === 'addressDetail') {
      setAddressEditing(!isAddressEditing);
    }
  };

  const handleDateChange = (date) => {
    // Convert the date to YYYY-MM-DD format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
  };


  return (
    <section className="general-section">
      <h2 className="general-heading">Profile</h2>
      <img src={line} alt="Line" className="general-line" />
      <div className="main-div">
        <div className="cover-div">

          <div className="profile-name">
            <div className="profile-image-container">
              <div className="upload-img"><img src={upload} alt="" /></div>
              <div className="profile-image-upload">Upload</div>
              <img src={impos} alt="" className="profile-image" />
            </div>
            <div className="profile-detail">
              <h2 className={`user-name `}>
                {personalDetails.firstName} {personalDetails.lastName}
              </h2>
              <span className="email-id">{personalDetails.email}</span>
              <span className="number">{personalDetails.phone}</span>
            </div>
          </div>
          <div className="personal-detail">
            <div className="top-head">
              <h2 className="detail-heading">Personal Detail</h2>
              <div onClick={() => toggleEditMode('personalDetail')}>
                {isPersonalDetailEditing ? 'Save' : <img src={edit} />}
              </div>
            </div>
            <div className="names1">
              <div className="firstname">
                <input
                  type="text"
                  name="firstName"
                  value={personalDetails.firstName}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">First name</div>
              </div>
              <div className="lastname">
                <input
                  type="text"
                  name="lastName"
                  value={personalDetails.lastName}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">last name</div>

              </div>
            </div>
            <div className="dob-gender">

              <div className="dob">
                {/* <input
                  type="text"
                  name="dob"
                  placeholder='   --/--/--'
                  value={personalDetails.dob}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                /> */}
                <div className="date-pic-dob">

                  <DatePicker styles={{ theme }} placeholder=' --/--/--' value={personalDetails.dob} onSelectDate={handleDateChange} />
                </div>
                <div className="general-name">Date of Birth</div>

              </div>
              <div className="gender">
                <select placeholder='Gender'>

                  <option value="">  Male </option>
                  <option value="fruit">Female</option>

                  <option value="vegetable">Others</option>
                </select>
                <div className="general-name">Gender</div>

              </div>
            </div>
            <div className="names1">
              <div className="firstname">
                <input
                  type="text"
                  name="email Address"
                  value={personalDetails.email}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">Email Address"</div>
              </div>
              <div className="lastname">
                <input
                  type="text"
                  name="number"
                  value={personalDetails.phone}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">Mobile Number</div>

              </div>
            </div>
          </div>
          <div className="address-detail">
            <div className="top-head">
              <h2 className="detail-heading">Address Detail</h2>
              <div onClick={() => toggleEditMode('addressDetail')}>
                {isAddressEditing ? 'Save' : <img src={edit} />}
              </div>
            </div>
            <div className="address1">
              <input
                type="text"
                name="address1"
                value={addressDetails.address1}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
              />
              <div className="address-name">Address Line 1</div>


            </div>
            <div className="address2">
              <input
                type="text"
                name="address2"
                value={addressDetails.address2}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
              />
              <div className="address-name">Address Line 2(optional)</div>

            </div>
            <div className="landmark">
              <input
                type="text"
                name="landmark"
                value={addressDetails.landmark}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
              />
              <div className="address-name">Landmark</div>

            </div>

            <div className="State">
              {/* <select placeholder='Town'>

                <option value="">   ---</option>
                <option value="fruit">Town/City</option>

                <option value="vegetable">Others</option>
              </select> */}
              <input type="text"

              />
              <div className="address-name">Zip-Code</div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default PProfile;
