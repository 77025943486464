import React from 'react';
import PropTypes from 'prop-types';
import "./CustomButton.css"

const CustomButton = ({ text, onClick, style }) => {
  return (
    <button onClick={onClick}  className="custom-button">
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomButton;
