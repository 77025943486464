import React, { useState, useRef, useEffect } from 'react';
import './profile.css';
import line from '../../media/line.svg';
import upload from '../../media/upload.svg';
import edit from '../../media/edit.svg';
import impos from '../../media/impos.jpg';
import ProfileBar from '../ProfileNavbar/ProfileNavbar';
import {
  initializeIcons,
  createTheme,
  DatePicker,
} from "@fluentui/react";
import axios from 'axios';
import place from '../../media/place.jpg';
import VerifyOtp from './VerifyOtp';


initializeIcons();



const Profile = () => {
  const [isPersonalDetailEditing, setPersonalDetailEditing] = useState(false);
  const [isAddressEditing, setAddressEditing] = useState(false);
  const user = JSON.parse(localStorage.getItem("viewer"));
  const address = JSON.parse(localStorage.getItem("address"));
  const [imageSrc, setImageSrc] = useState(null);

  const theme = createTheme({
    palette: {
      themePrimary: "#169c7c",
      themeLighterAlt: "#f2fbf9",
      themeLighter: "#ceefe7",
      themeLight: "#a7e1d4",
      themeTertiary: "#5fc3ac",
      themeSecondary: "#29a78a",
      themeDarkAlt: "#148c70",
      themeDark: "#11765e",
      themeDarker: "#0c5746",
      neutralLighterAlt: "#323232",
      neutralLighter: "#3a3a3a",
      neutralLight: "#484848",
      neutralQuaternaryAlt: "#505050",
      neutralQuaternary: "#575757",
      neutralTertiaryAlt: "#747474",
      neutralTertiary: "#ececec",
      neutralSecondary: "#efefef",
      neutralPrimaryAlt: "#f2f2f2",
      neutralPrimary: "#e3e3e3",
      neutralDark: "#f9f9f9",
      black: "#fcfcfc",
      white: "#292929",

    }
  })


  const [personalDetails, setPersonalDetails] = useState({
    firstName: user.name.split(' ')[0],
    lastName: user.name.split(' ')[1],
    email: user.email,
    phone: user.phone || user.phone_number,
    dob: user.dob || null,
    gender: user.gender,
  });

  const [addressDetails, setAddressDetails] = useState({
    address1: (address && address.address1) || "",
    address2: (address && address.address2) || "",
    landmark: (address && address.landmark) || "",
    zipcode: (address && address.zipcode) || ""
  });

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'personalDetail') {
      if (name === 'firstName' || name === 'lastName') {
        setPersonalDetails({
          ...personalDetails,
          [name]: value,
          name: `${name === 'firstName' ? value : personalDetails.firstName} ${name === 'lastName' ? value : personalDetails.lastName}`,
        });
      } else {
        setPersonalDetails({
          ...personalDetails,
          [name]: value,
        });
      }
    } else if (section === 'addressDetail') {
      setAddressDetails({
        ...addressDetails,
        [name]: value,
      });
    }
  };

  const toggleEditMode = (section) => {
    if (section === 'personalDetail') {
      setPersonalDetailEditing(!isPersonalDetailEditing);
    } else if (section === 'addressDetail') {
      setAddressEditing(!isAddressEditing);
    }
  };

  const handleDateChange = (date) => {
    // Check if a valid date object is received
    if (date && typeof date === 'object') {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      setPersonalDetails((prevDetails) => ({
        ...prevDetails,
        dob: formattedDate,
      }));
    }
  };

  const [selectedGender, setSelectedGender] = useState(user.gender);

  // Function to handle gender selection
  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
    // Update personalDetails state with the selected gender
    setPersonalDetails(prevDetails => ({
      ...prevDetails,
      gender: e.target.value,
    }));
  };


  const inputFileRef = useRef(null);
  const handleImageUpload = async (e) => {
    console.log("Inside handleImageUpload");

    const imageFile = e.target.files[0];

    // Check if a file is selected
    if (!imageFile) {
      console.error('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('image', imageFile);


    try {
      // Send the image file to the backend
      const response = await axios.post(' https://api.youthevents.in/viewer/upload-profile-image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          user: user.email
        }
      });

      // Handle success
      console.log('Image uploaded successfully:', response.data);
      fetchProfileImage()
    } catch (error) {
      // Handle error
      console.error('Error uploading image:', error);
    }
  };

  const fetchProfileImage = async () => {
    try {
      // Fetch the profile image for the user using the provided email
      const response = await axios.get(` https://api.youthevents.in/viewer/get-profile-image/`, {
        headers: {
          user: user.email
        },
      });

      // Create a base64 encoded data URL from the binary image data
      setImageSrc(response.data.profile_photo);

    } catch (error) {
      console.error('Error fetching profile image:', error);
    }
  };


  useEffect(() => {


    fetchProfileImage();
  }, [])

  console.log("personal details", personalDetails)

  const savePersonalInformation = async () => {
    try {
      // Send a PUT request to update the viewer data
      const response = await axios.put(' https://api.youthevents.in/viewer/viewer-update/', personalDetails, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          user: user.email
        },
      });

      // Handle success
      console.log('Personal information updated successfully:', response.data);
      // Reset the edit mode
      const updatedViewer = { ...user, ...response.data.viewer }; // Merge the existing user data with updated viewer data
      localStorage.setItem('viewer', JSON.stringify(updatedViewer));
      setPersonalDetailEditing(false);

    } catch (error) {
      // Handle error
      console.error('Error updating personal information:', error);
    }
  };

  const saveAddressInformation = async () => {
    try {
      const addressData = {
        address1: addressDetails.address1,
        address2: addressDetails.address2,
        landmark: addressDetails.landmark,
        zipcode: addressDetails.zipcode
      };

      // Send a PUT request to update the user's address
      const response = await axios.put(' https://api.youthevents.in/viewer/update-user-address/', addressData, {
        headers: {
          'Content-Type': 'application/json',
          user: user.id
        },
      });
      // Handle success
      console.log('User address updated successfully:', response.data);
      const updatedViewer = response.data.address; // Merge the existing user data with updated viewer data
      localStorage.setItem('address', JSON.stringify(updatedViewer));
      setAddressEditing(false);

    } catch (error) {
      // Handle error
      console.error('Error updating user address:', error);
    }
  };


  useEffect(() => {
    // Fetch address details from API if not available in local storage
    const fetchAddressDetails = async () => {
      if (!localStorage.getItem("address")) {
        try {
          const response = await axios.get('https://api.youthevents.in/viewer/get-user-address/', {
            headers: {
              'user': user.id
            }
          });
          setAddressDetails(response.data);
          localStorage.setItem('address', JSON.stringify(response.data));
        } catch (error) {
          console.error('Error fetching address details:', error);
        }
      } else {
        setAddressDetails(JSON.parse(localStorage.getItem("address")));
      }
    };

    fetchAddressDetails();
  }, []);


  const [showotp, setShowOtp] = useState(false)
  const [verificationType, setVerificationType] = useState()
  const [contactInfo, setContactInfo] = useState(false)

  const verifyMobileemail = (type, contact) => {
    setVerificationType(type);
    setContactInfo(contact);
    setShowOtp(true);
  };
  const closePopup = () => {
    setShowOtp(false)
  }


  return (
    <section className="general-section">
      <ProfileBar />
      <h2 className="general-heading">Profile</h2>
      <img src={line} alt="Line" className="general-line" />
      <div className="main-div">
      {showotp && <VerifyOtp closePopup={closePopup} verificationType={verificationType} contactInfo={contactInfo} />}

        <div className="cover-div">

          <div className="profile-name">
            <div className="profile-image-container">
              <div className="upload-img">
                <img src={upload} alt="" />
                <input type="file" ref={inputFileRef} onChange={handleImageUpload} style={{ display: 'none' }} />
              </div>
              <div className="profile-image-upload" onClick={() => inputFileRef.current.click()}>Upload</div>
              <img src={imageSrc ? `data:image/jpeg;base64,${imageSrc}` : place} alt="" className="profile-image" />
            </div>
            <div className="profile-detail">
              <h2 className={`user-name `}>
                {personalDetails.firstName} {personalDetails.lastName}
              </h2>
              <span className="email-id">{personalDetails.email}</span>
              {user.secondary_email && (
                <span className="email-id">
                  {user.secondary_email}
                  {user.is_secondary_email_verified ? (
                    <span className="verifyit">( Verified )</span>
                  ) : (
                    <span onClick={() => verifyMobileemail('email', user.secondary_email)} className="verifyit">
                      ( Verify )
                    </span>
                  )}
                </span>
              )}
              <span className="number">{personalDetails.phone}</span>
              {user.secondary_mobile && (
                <span className="number">
                  {user.secondary_mobile}
                  {user.is_secondary_mobile_verified ? (
                    <span className="verifyit">( Verified )</span>
                  ) : (
                    <span onClick={() => verifyMobileemail('mobile', user.secondary_mobile)} className="verifyit">
                      ( Verify )
                    </span>
                  )}
                </span>
              )}
            </div>
            {/* <VerifyOtp closePopup={closePopup}/> */}
          </div>
          <div className="personal-detail">
            <div className="top-head">
              <h2 className="detail-heading">Personal Detail</h2>
              <div onClick={() => toggleEditMode('personalDetail')}>
                {isPersonalDetailEditing ? <div onClick={savePersonalInformation}>Save</div> : <img src={edit} />}
              </div>
            </div>
            <div className="names1">
              <div className="firstname">
                <input
                  type="text"
                  name="firstName"
                  value={personalDetails.firstName}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">First name</div>
              </div>
              <div className="lastname">
                <input
                  type="text"
                  name="lastName"
                  value={personalDetails.lastName}
                  onChange={(e) => handleInputChange(e, 'personalDetail')}
                  readOnly={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                />
                <div className="general-name">last name</div>
              </div>
            </div>
            <div className="dob-gender">

              <div className="dob">

                <div
                  className={isPersonalDetailEditing ? 'edit-date' : 'date-pic-dob'}

                >
                  <DatePicker
                    styles={{ theme }}
                    placeholder='--/--/--'
                    value={new Date(personalDetails.dob)} 
                    onSelectDate={handleDateChange}
                    maxDate={new Date()} // Set the maximum date to the current date to prevent selection of future dates
                    disabled={!isPersonalDetailEditing}
                  />
                </div>
                <div className="general-name">Date of Birth</div>

              </div>
              <div className="gender">
                <select
                  value={selectedGender}
                  onChange={handleGenderChange}
                  disabled={!isPersonalDetailEditing}
                  className={isPersonalDetailEditing ? 'edit-mode' : ''}
                  placeholder='Gender'>

                  <option value="Select">  Select </option>
                  <option value="Male">  Male </option>
                  <option value="Female">Female</option>

                  <option value="Others">Others</option>
                </select>
                <div className="general-name">Gender</div>

              </div>
            </div>
          </div>
          <div className="address-detail">
            <div className="top-head">
              <h2 className="detail-heading">Address Detail</h2>
              <div onClick={() => toggleEditMode('addressDetail')}>
                {isAddressEditing ? <div onClick={saveAddressInformation}>Save</div> : <img src={edit} />}
              </div>
            </div>
            <div className="address1">
              <input
                type="text"
                name="address1"
                value={addressDetails.address1}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
                placeholder='Address Line 1'
              />
              <div className="address-name">Address Line 1</div>


            </div>
            <div className="address2">
              <input
                type="text"
                name="address2"
                value={addressDetails.address2}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
                placeholder='Address Line 2(optional)'

              />
              <div className="address-name">Address Line 2(optional)</div>

            </div>
            <div className="landmark">
              <input
                type="text"
                name="landmark"
                value={addressDetails.landmark}
                onChange={(e) => handleInputChange(e, 'addressDetail')}
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
                placeholder='Landmark'

              />
              <div className="address-name">Landmark</div>

            </div>

            <div className="State">
              {/* <select placeholder='Town'>

                <option value="">   ---</option>
                <option value="fruit">Town/City</option>

                <option value="vegetable">Others</option>
              </select> */}
              <input
                type="number"
                name="zipcode"
                value={addressDetails.zipcode}
                onChange={(e) => handleInputChange(e, 'addressDetail')} // Pass 'addressDetail' as section
                readOnly={!isAddressEditing}
                className={isAddressEditing ? 'edit-mode' : ''}
                placeholder='Zip-Code'
                maxLength={6}
              />
              <div className="address-name">Zip-Code</div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
