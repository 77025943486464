import React, { useState, useEffect } from 'react';
import '../yourorder.css';
import x from "../../../media/x.svg";
import axios from 'axios';

const ContactOrganizerPopup = ({ data, onClose }) => {
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Send');
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = async () => {
    const user = JSON.parse(localStorage.getItem("viewer"));
    const viewerToken = localStorage.getItem("viewerToken");

    console.log("data", data);

    setIsSending(true);
    setButtonText('Sending...');

    try {
      // Send the message to the organizer
      const response = await axios.post('https://api.youthevents.in/publisher/send-message-to-organizer/', {
        userId: user.id,
        publisherId: data.event.publisher,
        message: message,
        event: data.event.id
      });

      // Check if the API request was successful
      if (response.status === 200) {
        // Update button text to "Sent"
        setButtonText('Sent');

        // Automatically close the popup after 1 second
        setTimeout(onClose, 1000);
      } else {
        // Handle error scenarios
        console.error('Failed to send message:', response.statusText);
        setButtonText('Send');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setButtonText('Send');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={onClose}>
          <img src={x} alt="" />
        </button> {/* Close button in the top-right corner */}
        <h2>Contact the Organizer</h2>
        <textarea
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={isSending}
        ></textarea>
        <button onClick={handleSendMessage} disabled={isSending}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ContactOrganizerPopup;
