import "./eventdetail.css"
import React, { useState } from 'react';
import x from "../../media/x.svg"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Confirmation from "./Confirmation";

const AskForNumEmail = ({ onClose , handlePaymentAndBooking}) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const user = JSON.parse(localStorage.getItem("viewer"));

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phone: Yup.string()
            .matches(/^\d+$/, 'Phone number must contain only digits')
            .min(10, 'Phone number must be 10 digits')
            .max(10, 'Phone number must be 10 digits')
            .required('Phone number is required')
    });

    // Define initial form values
    const initialValues = {
        email: user.email || '',
        phone: user.phone || ''
    };



    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            // Prepare the data for the POST request
            let data = {
                user: user.id,
            };
    
            // Conditionally add email or phone based on presence in values
            if (user.email) {
                data.phone = values.phone;
            }
            if (user.phone) {
                data.email = values.email;
            }

            // Make a POST request to the backend endpoint
            const response = await axios.post('https://api.youthevents.in/viewer/update-secondary-contact/', data);

            // Handle the response
            if (response.status === 200) {
                // Update the subscribed state
                
                // Update local storage with the updated user data
                const updatedUserData = {
                    ...user, // Copy existing user data
                    secondary_email: response.data.secondary_email,
                    is_secondary_email_verified: response.data.is_secondary_email_verified,
                    secondary_mobile: response.data.secondary_mobile,
                    is_secondary_mobile_verified: response.data.is_secondary_mobile_verified
                };

                // Save the updated user data back to local storage
                localStorage.setItem("viewer", JSON.stringify(updatedUserData));
                handlePaymentAndBooking();
                onClose();
            }
        } catch (error) {
            console.error('Error updating secondary contact:', error);
            // Handle any errors here
        } finally {
            setSubmitting(false);
        }
    };


    const renderContent = () => {
       
            // User has not subscribed, show the subscription form
            return (
                <div className="popup-content email-mobile-content">
                    


                    <div className="news-btn news-tn2" >
                        <button className="close-button-news" onClick={onClose}><img src={x} alt="" style={{ height: "17px", width: "17px" }} /></button> {/* Close button in the top-right corner */}
                    </div>

                    <div className="enter-your">Enter Your Contact Details</div>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form className="enter-main mains2">
                            <Field
                                name="email"
                                type="email"
                                placeholder="Enter Email Address*"
                                readOnly={user.email != null}
                            />
                            <ErrorMessage name="email" component="div" className="errorMessage" />

                            <Field
                                name="phone"
                                type="number"
                                placeholder="Enter Phone no.*"
                                readOnly={user.phone != null}
                                maxLength={10}
                            />
                            <ErrorMessage name="phone" component="div" className="errorMessage" />

                            <div className="subscribe proceed2">
                                <button type="submit" disabled={isSubmitting}>
                                    Proceed
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>


                </div>
            );
        }


    return (
        // <div className="newsletter-popup">
        <>

            {renderContent()}
        </>
        // </div>
    );

}

export default AskForNumEmail
