import React from 'react'
import "./faq.css"
import line from "../../media/line.svg";
import Accordian from '../Accordian/Accordian';

// Q1. What is YouthEvents?
// A1. YouthEvents is a platform that connects you with a variety of events tailored 
// to the interests and passions of young individuals.

// Q2. How do I create an account?
// A2. Creating an account is easy! Click on the "Sign Up" button, and follow the prompts 
// to provide your information.

// Q3. Is YouthEvents free to use?
// A3. Signing up and browsing events on YouthEvents is free. However, there may be 
// charges associated with booking tickets for specific events.

// Using the Platform:

// Q4. How do I find events near me?
// A4. Use the search bar or filters to browse events by location, date, 
// category, and more.

// Q5. How do I book tickets for an event?
// A5. Once you've found an event you're interested in, click on it to view event details 
// and ticket options. Select your desired tickets and follow the booking process.

// Q6. Can I cancel or change my ticket order?
// A6. Event cancellation and refund policies vary. Check the event details or contact 
// the event organizer for information on cancellations and changes.

const FAQs = () => {
    const accordionItems = [
        {
            question: 'What is YouthEvents?',
            answer: `YouthEvents is a platform that connects you with a variety of events tailored to the interests and passions of young individuals.`,
        },
        {
            question: 'How do I create an account?',
            answer: ` Creating an account is easy! Click on the "Sign Up" button, and follow the prompts to provide your information.
            `,
        },
        {
            question: `Is YouthEvents free to use?`,
            answer: `Signing up and browsing events on YouthEvents is free. However, there may be charges associated with booking tickets for specific events.`,
        },
        {
            question: 'How do I find events near me?',
            answer: ` Use the search bar or filters to browse events by location, date, category, and more.`,
        },
        {
            question: 'How do I book tickets for an event?',
            answer: ` Once you've found an event you're interested in, click on it to view event details and ticket options. Select your desired tickets and follow the booking process.`,
        },
        {
            question: 'Can I cancel or change my ticket order?',
            answer: `Event cancellation and refund policies vary. Check the event details or contact the event organizer for information on cancellations and changes.`,
        },
    ];



    return (

        <section className="general-section">
            <h2 className="general-heading">FAQs</h2>
            <img src={line} alt="Line" className="general-line" />

            <h1 className='faqs-heading'>Frequently Asked Questions</h1>
            <p className="faq">Welcome to the YouthEvents FAQ page! Here, we've compiled answers to common
                questions you may have about using our platform. If you don't find the information
                you're looking for, feel free to contact us at hi@youthevents.in for further assistance.</p>
            <Accordian data={accordionItems} />



        </section>
    )
}

export default FAQs
