import React, { useEffect, useState } from 'react'
import "./eventgallery.css"
import line from "../../media/line.svg";
import Image from './Image';
import { useParams } from 'react-router-dom';
import axios from 'axios';



const EventGallery = () => {
    const { cityName } = useParams();

    
    const [galleryData, setGalleryData] = useState([]);

    useEffect(() => {
        const fetchGalleryData = async () => {
            try {
                const response = await axios.get(` https://api.youthevents.in/publisher/api/viewer-event-gallery/${cityName}/`);
                setGalleryData(response.data);
            } catch (error) {
                console.error('Error fetching gallery data:', error);
            }
        };

        fetchGalleryData();
    }, [cityName]);

    console.log("gallery data",galleryData)
    return (
        <section className="general-section">
            <h2 className="general-heading">Event Gallery</h2>
            <img src={line} alt="Line" className="general-line" />
            <div className="box1">
        <Image imgdetails={galleryData}/>
      </div>

          

           

        </section>
    )
}

export default EventGallery