import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vector1 from "../../media/vector1.svg"
import "./SearchBox.css"


const CustomSearchBar = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleSearch = () => {
        onSearch(query);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch(query);
        }
    };

    return (
        <div className="custom-search-bar">
            <input
            className='search-input'
                type="text"
                placeholder="Event Name"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
            />
            {/* <img className="vector-icon1" alt="" src={vector1} onClick={handleSearch} /> */}

        </div>
    );
};

CustomSearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

export default CustomSearchBar;
