import React, { useEffect, useState } from 'react';
import './Notification.css';
import line from '../../media/line.svg';
import ProfileBar from '../ProfileNavbar/ProfileNavbar';
import axios from 'axios';


const Notification = () => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchViewerNotifications = async () => {
            const user = JSON.parse(localStorage.getItem("viewer"));
            try {
                const response = await axios.get(' https://api.youthevents.in/viewer/api/viewer-notifications/', {
                    params: {
                        user: user.id,
                    },
                });
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching Viewer Notifications:', error);
                // Handle error
            }
        };

        fetchViewerNotifications();
    }, []);


    console.log("notification is", notifications)

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };
  
    const reversedNotifications = [...notifications].reverse();

    return (
        <section className="general-section">
            <ProfileBar />
            <h2 className="general-heading">Notifications</h2>
            <img src={line} alt="Line" className="general-line" />
            <div className="main-notifications">
                {reversedNotifications.length > 0 ? (
                    <>
                        {reversedNotifications.map((notification) => (
                           <>
                           <div className="notification message" key={notification.id}>
                               <div className="pname">

                                   <h3 className="notification-heading mess-heading">{notification.heading}</h3>
                                   <p className="notification-text mess-text">{notification.description}</p>
                               </div>
                               <div className='p-date'>
                                   {formatDate(notification.created_at)}
                               </div>
                           </div>
                       </>
                        ))}
                    </>
                ) : (
                    <>
                        <h3 className="noe">
                            No Notification To Show
                        </h3>
                    </>
                )}

            </div>
        </section>
    );
};

export default Notification;
