import React from 'react';
import QRCode from 'qrcode.react';
import "./Orderedtickets.css"


const QRPop = ({ ticket, onClose , eventdata }) => {

    function convertTo12Hour(time) {
        const [hour, minute, second] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    }
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };

    return (
        <div className="qr-code-popup">
            <div className="overlay" />

            <div className="popup-content4">


                    <div key={ticket.ticket_id} className="tickets ">
                        <QRCode value={ticket.ticket_id} style={{ height: "256px", width: "256px" }} />
                        <div className="ticket-details">


                            <div className="tictype">{ticket.ticket_type_name}</div>
                            <div className="ticno">{ticket.quantity} Ticket</div>
                            <div className="ticid">{ticket.ticket_id}</div>

                        </div>
                        <div className="ticket-line"></div>
                        <div className="event-detail">
                            

                            <div className="tic-event">{eventdata.event_title}</div>
                            <div className="tic-lang">{eventdata.event_language}</div>
                            <div className="tic-date">{formatDate(eventdata.event_date)}</div>
                            <div className="tic-time">{convertTo12Hour(eventdata.event_time)}</div>

                        </div>
                        <button className="close-button3" onClick={onClose}>
                            Close
                        </button>
                    </div>

                </div>
            </div>
    );
};

export default QRPop;
