import React from 'react'
import booknow from "../../media/Adv.png"
import "./grabyour.css"
import { Link } from 'react-router-dom'

const GrapYours = ({images}) => {
    console.log("base banner",images)
    return (
        <section  className='backgroundimage'>
            <img src={images[0]?.banner_image} alt="" className='grab-img' />
            <div className="textarea">

                <h3 className='texts1'>Grab your tickets for </h3>
                <h3 className='texts2'>{images[0]?.event?.eventTitle}</h3>
                <div className="grab-btn">

                <Link to={`/event-detail/${images[0]?.event?.id}`} className='booknow'>Book now</Link>
                </div>
            </div>


        </section>
    )
}

export default GrapYours
