import "./homescreen.css";
import ellipse6 from "../../media/ellipse-6.svg"
import ellipse5 from "../../media/ellipse-5.svg"
import mainlogo from "../../media/main-logo1.svg"
import vector11 from "../../media/vector-11.svg"
import SearchableDropdown from "../Searchabledropdown/Dropdown";
import CustomButton from "../CustomButton/CustomButton";
import CustomSearchBar from "../CustomSearchBox/CustomSearchBox";

const Homescreen = () => {
    const options = ['Gurgaon', 'Delhi', 'Mumbai', 'Kolkata'];

  const handleSelect = option => {
    console.log('Selected:', option);
  };

  const handleButtonClick = () => {
  };

  const handleSearch = (query) => {
    // Define the search action here, e.g., fetch search results from an API
  };

  //todo city dropdown icon and search icon and baseline text


    return (
        <div className="bg">
            <div className="main-logo-parent">
                <div>
                    <img
                        className="main-logo-icon1"
                        id="main-logo"
                        alt=""
                        src={mainlogo}
                    />
                </div>

                <div className="headings">
                    <h1 className="youth-events">YOUTH EVENTS</h1>
                    <h3 className="unlock-the-citys">
                        Unlock the City's Pulse: Your Ticket to Youthful Experiences
                    </h3>
                </div>
            </div>
            <div className="frame">


                <div className="frame-inner">
                    <div className="frame-parent">
                        <div className="onboard-search-wrapper">
                            <div className="frame-parent">
                                <div className="searchbar1">

                                    <CustomSearchBar onSearch={handleSearch}/>
                                    
                                </div>
                                <div className="city2">
                                    <SearchableDropdown options={options} onSelect={handleSelect} />

                                    {/* <img className="city-child1" alt="" src={vector11} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="search-btn">
                            <CustomButton className="search1" text="Search" onClick={handleButtonClick}/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="text" style={{ display: "flex" }}>
                <div className="explore">Not Sure, click here to take a tour.</div>

            </div>


        </div>
    );
};

export default Homescreen;
