import React, { useState } from 'react';
import "../eventpublish.css"
import {
  initializeIcons,
  createTheme,
  DatePicker,
} from "@fluentui/react";
import Dropdown from './SingleDropDown';
import MDropdown from './MultipleSelectDwropdown';


initializeIcons();

const theme = createTheme({
  palette: {
    themePrimary: "#169c7c",
    themeLighterAlt: "#f2fbf9",
    themeLighter: "#ceefe7",
    themeLight: "#a7e1d4",
    themeTertiary: "#5fc3ac",
    themeSecondary: "#29a78a",
    themeDarkAlt: "#148c70",
    themeDark: "#11765e",
    themeDarker: "#0c5746",
    neutralLighterAlt: "#323232",
    neutralLighter: "#3a3a3a",
    neutralLight: "#484848",
    neutralQuaternaryAlt: "#505050",
    neutralQuaternary: "#575757",
    neutralTertiaryAlt: "#747474",
    neutralTertiary: "#ececec",
    neutralSecondary: "#efefef",
    neutralPrimaryAlt: "#f2f2f2",
    neutralPrimary: "#e3e3e3",
    neutralDark: "#f9f9f9",
    black: "#fcfcfc",
    white: "#292929"
  }
})
const languagesOfIndia = [
  {
    id: 0,
    label: "English",
  },
  {
    id: 1,
    label: "Hindi",
  },
  {
    id: 2,
    label: "Bengali",
  },
  {
    id: 3,
    label: "Telugu",
  },
  {
    id: 4,
    label: "Marathi",
  },
  {
    id: 5,
    label: "Tamil",
  },
  {
    id: 6,
    label: "Urdu",
  },
  {
    id: 7,
    label: "Gujarati",
  },
  {
    id: 8,
    label: "Kannada",
  },
  {
    id: 9,
    label: "Odia",
  },
  {
    id: 10,
    label: "Punjabi",
  },
  {
    id: 11,
    label: "Malayalam",
  },
  {
    id: 12,
    label: "Assamese",
  },
  {
    id: 13,
    label: "Sanskrit",
  },
];

const EventType = [
  {
    id: 0,
    label: "Parties",
  },
  {
    id: 1,
    label: "Music",
  },
  {
    id: 2,
    label: "Standup comedy",

  },
  {
    id: 3,
    label: "Personal growth",

  },
  {
    id: 4,
    label: "Showcraft",
  },
  {
    id: 5,
    label: "Festivals",
  },
  {
    id: 6,
    label: "Community",
  },
  {
    id: 7,
    label: "Adventure",
  }
];
const City = [
  {
    id: 0,
    label: "Hyderabad",
  },
  {
    id: 1,
    label: "Bengaluru",
  },
  {
    id: 2,
    label: "Delhi",

  },
  {
    id: 3,
    label: "Mumbai",

  },
  {
    id: 4,
    label: "Jaipur",
  },
  {
    id: 5,
    label: "Chennai",
  },
];

const EventTagsByType = {
  0: [
    // { id: 10, label: "Dance Party" },
    // { id: 11, label: "Cocktail Party" },
  ],
  1: [
    { id: 20, label: "Live Concert" },
    { id: 21, label: "Karaoke Nights" },
    { id: 22, label: "Fashion shows" },
    { id: 23, label: "Music Festivals" },
    { id: 24, label: "DJ Nights" },
  ],
  2: [
    // { id: 20, label: "Concert" },
    // { id: 21, label: "Festival" },
  ],
  3: [
    { id: 30, label: "Seminars" },
    { id: 31, label: "Workshops" },
    { id: 32, label: "Meditation" },
    { id: 33, label: "Networking Events" },
    { id: 34, label: "Job Fair" },
    { id: 35, label: "Yoga Retreats" },
    { id: 36, label: "Motivational Speaker Event" },
  ],
  4: [
    { id: 40, label: "Theatre" },
    { id: 41, label: "Magic shows" },
    { id: 42, label: "Fashion shows" },
    { id: 43, label: "Art exhibitions" },
    { id: 44, label: "Circus" },
    { id: 45, label: "Open mic" },
    { id: 46, label: "Poetry slams" },
  ],
  5: [
    { id: 50, label: "Cultural Festivals" },
    { id: 51, label: "Music Festivals" },
    { id: 52, label: "Food Festivals" },
    { id: 53, label: "College Festivals" },
    { id: 54, label: "Religious Festivals" },
    { id: 55, label: "Tech Festivals" },
  ],
  6: [
    // { id: 20, label: "Concert" },
    // { id: 21, label: "Festival" },
  ],
  7: [
    { id: 70, label: "Trekking" },
    { id: 71, label: "Water Parks" },
    { id: 71, label: "Amusement Parks" },
    { id: 71, label: "Camping" },
    { id: 71, label: "Adventure Parks" },
    { id: 71, label: "Gaming Zones" },
  ],
};

const EventsDetails = ({ onDataChange , editdata }) => {
  console.log("edit data",editdata)
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [eventTime, setEventTime] = useState(""); // State for time input
  const [imageFile, setImageFile] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState([]);
  const [selectedEventTag, setSelectedEventTag] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [eventTagOptions, setEventTagOptions] = useState([]);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      onDataChange({ imageFile: file });
    }
  };




  const [eventData, setEventData] = useState({
    eventTitle: "",
    eventDescription: "",
    eventLocation: "",
    eventDate: null,
    eventTime: "",
    eventCategory: "",
    eventTag: "",
    eventLanguage: "",
    eventDuration: "",
    city: ""
  });

  const handleTimeChange = (e) => {
    const { value } = e.target;

    setEventTime(value);
    // Ensure the time is in HH:MM format
    const [hours, minutes] = value.split(':');
    const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;

    onDataChange({ eventTime: formattedTime, eventDate: selectedDate.toISOString() });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Convert the date to YYYY-MM-DD format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    onDataChange({ eventDate: formattedDate });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
    // Call onDataChange to pass data back to the parent component
    onDataChange({ [name]: value, imageFile });
  };

  const handleEventTypeChange = (selectedType) => {
    setSelectedEventType(selectedType);
    const tagsForType = EventTagsByType[selectedType] || [];
    setSelectedEventTag([]);
    setEventTagOptions(tagsForType);


    onDataChange({
      eventCategory: selectedType,
      eventTag: selectedEventTag,
      eventLanguage: selectedLanguage,
      city: selectedCity
    });
  };



  return (
    <div className='event-d'>
      <h2>Event Details</h2>
      <div className="event-tittles">
        <input type="text" name="eventTitle" placeholder={editdata.eventTitle} value={eventData.eventTitle} onChange={handleInputChange} />
        <h3>Up to 46 characters</h3>
      </div>

      <div className="event-tittles">
        <input type="text" name="eventDescription" placeholder='Event Description' value={eventData.eventDescription} onChange={handleInputChange} />
        <h3>Up to 742 characters</h3>
      </div>

      <div className="event-tittles">
        <input type="text" name="eventLocation" placeholder='Event Location / Venue' value={eventData.eventLocation} onChange={handleInputChange} />
        <h3>Recommendation: Utilize a Precise Location from Google Maps</h3>
      </div>

      <div className="event-tittles">
        <input type="text" name="eventDuration" placeholder='Event Duraion' value={eventData.eventDuration} onChange={handleInputChange} />
        <h3>Event Duration in hours</h3>
      </div>

      <div className="drop-boxs">
        <div className="date-pic">
          <DatePicker styles={{ theme }} placeholder='Date of Event' value={eventData.eventDate} onSelectDate={handleDateChange} />
        </div>

        <div style={{ width: "100%", display: "flex", maxWidth: "280px" }}>
          <div className="datepicker">
            <input
              type="time"
              placeholder="Time"
              value={eventTime}
              onChange={handleTimeChange}
              style={{ paddingRight: "24px", paddingLeft: "24px" }}
            />

          </div>
        </div>
        {/* <input type="text" name="eventCategory" placeholder='Event Category' value={eventData.eventCategory} onChange={handleInputChange} /> */}
        <Dropdown
          value={selectedEventType}
          onChange={(v) => {
            handleEventTypeChange(v);
            onDataChange({
              eventCategory: v,
              eventTag: selectedEventTag,
              eventLanguage: selectedLanguage,
            });
          }}
          options={EventType}
          placeholder={'Event Category'}
        />

        {/* <input type="text" name="eventTag" placeholder='Event Tag' value={eventData.eventTag} onChange={handleInputChange} /> */}
        {eventTagOptions.length > 0 ? (
          <Dropdown
            value={selectedEventTag}
            onChange={(v) => {
              setSelectedEventTag(v);
              onDataChange({
                eventTag: v,
                eventCategory: selectedEventType,
                eventLanguage: selectedLanguage,
              });
            }}
            options={eventTagOptions}
            placeholder={'Event Tag'}
          />
        ) : (
          <input
            type="text"
            name="eventTag"
            value={selectedEventTag}
            onChange={(e) => setSelectedEventTag(e.target.value)}
            style={{visibility:"hidden"}}
            className='dropdown-input'
          />
        )}
        <Dropdown
          value={selectedCity}
          onChange={(v) => {
            setSelectedCity(v);
            onDataChange({
              city: v,
              eventCategory: selectedEventType,
              eventLanguage: selectedLanguage,
            });
          }}
          options={City}
          placeholder={'Select City'}
        />

        <MDropdown
          value={selectedLanguage}
          onChange={(v) => {
            setSelectedLanguage(v);
            onDataChange({
              eventLanguage: v,
              eventCategory: selectedEventType,
              eventTag: selectedEventTag,
            });
          }}
          options={languagesOfIndia}
          placeholder={"Languages"}
        />



      </div>
      <div className="event-tittles event-tittles1" style={{ marginBottom: "79px" }}>
        <div className="text1">
          {imageFile ? imageFile.name || imageFile[0].name : 'Main / Thumbnail Image'}

        </div>
        <div className="text1 text2">
          {imageFile ? imageFile.name || imageFile[0].name : 'Image'}
        </div>
        <div className="upload">
          <label htmlFor="imageInput">Upload</label>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </div>



      </div>


      {/* {imageFile && (
        <div>
          <h3>Selected Image Preview:</h3>
          <img src={URL.createObjectURL(imageFile)} alt="Selected" />
        </div>
      )} */}
    </div>
  );
}

export default EventsDetails;