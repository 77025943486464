import React, { useState } from 'react';
import '../accountsetting.css'; // Import your CSS styles

const EmailNotification = () => {
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [receiveOrder, setReceiveOrder] = useState(true);

  const toggleReceiveEmail = () => {
    setReceiveEmail(!receiveEmail);
  };

  const toggleReceiveOrder = () => {
    setReceiveOrder(!receiveOrder);
  };

  return (
    <div>
      <h1 className='email-noti'>Email Notification</h1>
      <div className="toggle-option">
        <div className="receive-noti">Receive Email notification</div>
        <div className={`slider1 ${receiveEmail ? 'actives' : ''}`} onClick={toggleReceiveEmail}>
          <div className={`slider-button ${receiveEmail ? 'actives' : ''}`}></div>
        </div>
      </div>
      <div className="toggle-option">
        <div className="order-noti">Receive Order notification</div>
        <div className={`slider1 ${receiveOrder ? 'actives' : ''}`} onClick={toggleReceiveOrder}>
          <div className={`slider-button ${receiveOrder ? 'actives' : ''}`}></div>
        </div>
      </div>
    </div>
  );
};

export default EmailNotification;
