import "./eventdetail.css"

function Para({ name, description ,subTitle}) {
    return (
      <div>
        <h1 className="insight-name">{name}</h1>
        <h3 className="insight-sub">{subTitle}</h3>
        <div>
          <p className="insight-desc">{description}</p>
        </div>
      </div>
    );
  }
  
  export default Para;
  