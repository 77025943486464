import { React } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Contactus.css';
import line from '../../media/line.svg';

const Contactus = () => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        message: Yup.string().required('Message is required'),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true);
            const response = await fetch('https://api.youthevents.in/viewer/contactus/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.ok) {
                console.log('Contact form submitted successfully');
                setSubmitting(false);
                resetForm();
            } else {
                console.error('Failed to submit contact form');
            }
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setSubmitting(false);
        }
    };

    return (
        <section className="general-section">
            <h2 className="general-heading">Contact Us</h2>
            <img src={line} alt="Line" className="general-line" />

            <h3 className="contact-heading">
                We're here to help! If you have questions, feedback, or need assistance, feel free
                to reach out to us. Your inquiries are important to us, and we'll do our best to
                provide a prompt and helpful response. Here's how you can get in touch:
            </h3>

            <Formik
                initialValues={{ name: '', lastName: '', email: '', message: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="contact-form">
                        <div className="formcontainer">
                            <div className="bothnames">

                                <div className="names">
                                    <Field
                                        name="name"
                                        type="text"
                                        placeholder="First name"
                                    />
                                    <ErrorMessage name="name" component="div" className="errorMessage2" />
                                </div>
                                <div className="names">

                                    <Field
                                        name="lastName"
                                        type="text"
                                        placeholder="Last Name"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="errorMessage2" />
                                </div>
                            </div>


                            <div className="emailaddress">
                                <Field
                                    name="email"
                                    type="text"
                                    placeholder="Enter Your Email"
                                />
                                <ErrorMessage name="email" component="div" className="errorMessage2" />
                            </div>

                            <div className="messagehere">
                                <Field
                                    name="message"
                                    as="textarea"
                                    placeholder="Type your message here"
                                />
                                <ErrorMessage name="message" component="div" className="errorMessage2" />
                            </div>

                            <div className="submit-btn">
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </section>
    );
};

export default Contactus;
