import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  minimumPrice: 0,
  price: 0,
  eventType: "",
  date: null,
  eventData: null, 
  language: [],
  discount: [],
  userAuthenticated: false, 
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setEventType: (state, action) => {
      state.eventType = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload instanceof Date ? action.payload : null;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setUserAuthenticated: (state, action) => {
      state.userAuthenticated = action.payload;
    },
  },
});

export const {
  setPrice,
  setEventType,
  setDiscount,
  setLanguage,
  setDate,
  setEventData,
  setUserAuthenticated, 
} = filterSlice.actions;

export default filterSlice.reducer;
