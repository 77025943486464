import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './CitySelectionPopup.css';
import detect from "../../media/detect.svg";
import delhi from "../../media/delhi.png";
import bengaluru from "../../media/bengaluru.png";
import jaipur from "../../media/jaipur.png";
import mumbai from "../../media/mumbai.png";
import chennai from "../../media/chennai.png";
import hydrabad from "../../media/hyderabad.png";

const CitySelectionPopup = ({ onSelectCity, onClose }) => {
// Function to handle the city selection
const handleCitySelect = (city) => {
  onSelectCity(city);
  localStorage.setItem("selectedCity", city);

  // Get the current pathname
  const currentPathname = window.location.pathname;

  // Split the current pathname to extract the current city
  const pathParts = currentPathname.split('/');

  // Replace the current city with the selected city
  pathParts[1] = city // Assuming the city name is the first part of the pathname

  // Construct the new URL with the selected city
  const newUrl = pathParts.join('/');

  // Navigate to the new URL
  window.location.href = newUrl;

  // Close the popup
  onClose();
};

  return (
    <div className="city-selection-popup-container">
      <div className="city-selection-popup">
        {/* <div className="detect">
          <img src={detect} alt="" />
          Detect my Location
        </div> */}
        <div className="all-city">
          <div className="cities" onClick={() => handleCitySelect('Mumbai')}>
            <img src={mumbai} alt="" /> Mumbai
          </div>
          <div className="cities" onClick={() => handleCitySelect('Delhi')}>
            <img src={delhi} alt="" /> Delhi
          </div>
          <div className="cities" onClick={() => handleCitySelect('Hyderabad')}>
            <img src={hydrabad} alt="" /> Hyderabad
          </div>
          <div className="cities" onClick={() => handleCitySelect('Bengaluru')}>
            <img src={bengaluru} alt="" /> Bengaluru
          </div>
          <div className="cities" onClick={() => handleCitySelect('Jaipur')}>
            <img src={jaipur} alt="" /> Jaipur
          </div>
          <div className="cities" onClick={() => handleCitySelect('Chennai')}>
            <img src={chennai} alt="" /> Chennai
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitySelectionPopup;
