import styles from "./AboutUsCard.css";
import twitterIcon from "../../media/twitter.svg";
import facebookIcon from "../../media/facebook.svg";
import instagramIcon from "../../media/instagram.svg";
import youtubeIcon from "../../media/youtube.svg";
import mainlogo from "../../media/MainLogo.svg";
import React, { useState } from "react";
import NewsletterPopup from "../Newsletter popus/Newsletterpop";
import ln from "../../media/lin.png"

const Footer = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  // Function to handle clicks on the "hover" div elements
  const handleHoverDivClick = (link) => {
    // You can add logic here to determine the URL based on the link parameter
    window.location.href = link; // Navigate to the URL
  };
  const storedCity = localStorage.getItem('selectedCity');

  const handleHoverDiv = () => {
    const url = 'https://organizers.youthevents.in/';

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <div className="footer">
      <footer className='footerChild' />
      <div className='footerlast' />
      <div className="upper">
        <div className="two">
          <div className='aboutUsParent'>
            <div className='aboutUs hover' onClick={() => handleHoverDivClick('/about-us')}>About Us</div>
            <div className='aboutUs hover' onClick={() => handleHoverDivClick('/contact-us')}>Contact Us</div>
            <div className='aboutUs hover' onClick={() => handleHoverDivClick('/terms-of-use')}>Terms of Use</div>
            <div className='aboutUs hover' onClick={() => handleHoverDivClick('/privacy-policy')}>Privacy Policy</div>
          </div>
          <div className='faqsParent'>
            <div className='faqs hover' onClick={() => handleHoverDivClick('/faqs')}>FAQs</div>
            <div className='advertiseWithUs hover' onClick={() => handleHoverDivClick('/advertise-with-us')}>Advertise With Us</div>
            <div className='eventsCalendar hover' onClick={() => handleHoverDivClick(`/${storedCity}/events-calendar`)}>Events Calendar</div>
            <div className='partnerships hover' onClick={() => handleHoverDivClick('/partnerships')}>Partnerships</div>
          </div>
        </div>
        <div className="three">
          <div className='publishYourEventParent'>
            <div className='publish hover' onClick={handleHoverDiv}>Publish Your Event</div>
            <div className='homepage hover' onClick={() => handleHoverDivClick('/')}>Home page</div>
            <div className='newsletter hover' onClick={openPopup}>Subscribe To Newsletter</div>
            {isPopupOpen && (
              <NewsletterPopup onClose={closePopup} /> // Render the NewsletterPopup when isPopupOpen is true
            )}
            <div className='eventgallery hover' onClick={() => handleHoverDivClick(`/${storedCity}/event-gallery`)}>Event Gallery</div>
          </div>
          <div className="mainlogo">
            <img src={mainlogo} alt="Twitter" className="logo" />
          </div>
        </div>
      </div>
      <div className='allRightsReservedMadeWithParent'>
        <div className="madeby">
          made with ❤️ by Webminix
        </div>
        <div className='allRightsReserved'>
          A product by M/s BRICH
        </div>

        <div className="icons">
          <div className="social-icons">
            <a href="https://twitter.com">
              <img src={twitterIcon} alt="Twitter" className="social-icon" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61552117683578&sk=about" target="_blank">
              <img src={facebookIcon} alt="Facebook" className="social-icon" />
            </a>
            <a href="https://www.instagram.com/youthevents.in?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
              <img src={instagramIcon} alt="Instagram" className="social-icon" />
            </a>

            <a href="https://www.linkedin.com/company/youth-events/about/?viewAsMember=true" target="_blank">
              <img src={ln} alt="YouTube" className="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
