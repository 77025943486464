import React, { useState, useEffect } from 'react';
import "./eventtype.css";
import line from "../../media/line.svg";
import date from "../../media/date.svg";
import filter from "../../media/filter.svg";
import location from "../../media/location.svg";
import up from "../../media/up.svg";
import dow from "../../media/dow.svg";
import ImageSlider from '../MainImageSider/ImageSlider';
import TimePicker from '../Event calender/TimePicker';
import { Slider } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEventData } from '../../redux/filtersSlice';


const MobileFilter = ({
  selectedCategory,
  setSelectedCategory,
  maxPrice,
  setMaxPrice,
  priceRange,
  setPriceRange,
  discountRange,
  setDiscountRange,
  selectedLanguages,
  setSelectedLanguages,
  futureEvents
}) => {

  // State variables to control the visibility of filters
  const [isDateFilterOpen, setDateFilterOpen] = useState(false);
  const [isLanguageFilterOpen, setLanguageFilterOpen] = useState(false);
  const [isDiscountFilterOpen, setDiscountFilterOpen] = useState(false);
  const [isPriceFilterOpen, setPriceFilterOpen] = useState(false);
  const [isViewFilterOpen, setViewFilterOpen] = useState(false);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);


  // Function to toggle the visibility of a sub-filter
  const toggleSubFilter = (subFilterStateSetter) => {
    subFilterStateSetter((prevState) => !prevState);
  };
  const languagesOfIndia = [
    'English',
    'Hindi',
    'Bengali',
    'Telugu',
    'Marathi',
    'Tamil',
    'Urdu',
    'Gujarati',
    'Kannada',
    'Odia',
    'Punjabi',
    'Malayalam',
    'Assamese',
    'Sanskrit',
  ];


  const handleLanguageChange = (language) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleDiscountChange = (value) => {
    setDiscountRange(value);
  };
  const categories = [
    { key: 'latest', label: 'Latest' },
    { key: 'most-viewed', label: 'Most-Viewed' },
    { key: 'most-sold', label: 'Most-Sold' },
  ];


  return (
    <div className='filter-mobile-cover'>
      <div
        className={`event-mobile-filter1 ${areFiltersVisible ? 'mobile-open' : ''}`}
        onClick={() => setAreFiltersVisible(!areFiltersVisible)}
      >
        Filter
        <img
          className={`filter-img ${areFiltersVisible ? 'filter-rotate' : ''}`}
          src={filter}
          alt=""
        />
      </div>
      {/* Render individual filters based on areFiltersVisible */}
      {areFiltersVisible && (
        <div className="filter-options">
          <div className="event-mobile-filter" >
            <div className='filter-name' onClick={() => toggleSubFilter(setDateFilterOpen)}>
              <div className="fname">Date</div>

              <img className='filter-img' src={isDateFilterOpen ? dow : up} alt="" />

            </div>
            {isDateFilterOpen && (
              <div className="filter-dropdown1" >
                <div className="calender">
                  <TimePicker flexDirection={"column"} futureEvents={futureEvents} />
                </div>
              </div>
            )}
          </div>
          <div className="event-mobile-filter" >
            <div className='filter-name' onClick={() => toggleSubFilter(setLanguageFilterOpen)}>
              <div className="fname">Language</div>



              <img className='filter-img' src={isLanguageFilterOpen ? dow : up} alt="" />
            </div>
            {isLanguageFilterOpen && (
              <div className="languages">
                <div className="laguage-checkbox">
                  {languagesOfIndia.map((language) => (
                    <div key={language} className="language-item">
                      <input
                        type="checkbox"
                        id={language}
                        name={language}
                        checked={selectedLanguages.includes(language)}
                        onChange={() => handleLanguageChange(language)}
                      />
                      <span className='select-language'>{language}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* <div className="event-mobile-filter" onClick={() => toggleSubFilter(setDiscountFilterOpen)}>
          <div className='filter-name'>


            Discount
            <img className='filter-img' src={filter} alt="" />
          </div>
          {isDiscountFilterOpen && (
 <div className="discount">
              
              <div className="price-range">


                {discountRange[0]}% - {discountRange[1]}%
              </div>
              <div className="discount-slider">
                <Slider
                  range
                  step={10}
                  defaultValue={[10, 50]}
                  min={0}
                  max={75}
                  onChange={handleDiscountChange}
                  railStyle={{ backgroundColor: "#959595" }}
                  trackStyle={{ backgroundColor: "#959595" }}
                />
                <div className="ranges">
                  <p>0%</p>
                  <p>75%</p>

                </div>
              </div>

            </div>
          )}
        </div> */}
          <div className="event-mobile-filter" >
            <div className='filter-name' onClick={() => toggleSubFilter(setPriceFilterOpen)}>
              <div className="fname">Price</div>



              <img className='filter-img' src={isPriceFilterOpen ? dow : up} alt="" />
            </div>
            {isPriceFilterOpen && (
              <div className="discount">
                <div className="price-range" style={{justifyContent:"center"}}>
                  ₹ {priceRange[0]} - ₹ {priceRange[1]}
                </div>
                <div className="discount-slider">
                  <Slider
                    range
                    step={10}
                    defaultValue={[10, maxPrice]}
                    min={0}
                    max={maxPrice}
                    onChange={handlePriceChange}
                    railStyle={{ backgroundColor: "#959595" }}
                    trackStyle={{ backgroundColor: "#959595" }}
                    handleStyle={{outerWidth:"24px", innerWidth:"24px",innerHeight:"24px",outerHeight:"24px"}}
                    
                  />
                  <div className="ranges">
                    <p>Free</p>
                    <p>{maxPrice}</p>

                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="event-mobile-filter" >
            <div className='filter-name' onClick={() => toggleSubFilter(setViewFilterOpen)}>
              <div className="fname">Most-Viewed</div>



              <img className='filter-img' src={isViewFilterOpen ? dow : up} alt="" />
            </div>

            {isViewFilterOpen && (
              <div className="view-sold">
                {categories.map((category) => (
                  <div
                    key={category.key}
                    className={` ${selectedCategory === category.key ? 'selected1' : category.key}`}
                    onClick={() => setSelectedCategory(category.key)}
                  >
                    {category.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileFilter
