import { useEffect, useState } from "react";
import styles from "../Event gallery/Image.module.css";
import Imagecomp from "../Event gallery/Imagecomp";
import leftarrow from "../../media/leftarrow.svg"
import rightarrow from "../../media/rightarrow.svg"



function Image({imgdetails}) {
  const [play, setplay] = useState(true);
  const [ind, setindex] = useState(0);
  const handleClick = (ind) => {
    // console.log(ind);
    if (ind + 1 > imgdetails.length - 1) {
      setindex(0);
    } else {
      setindex(ind);
    }
  };
    useEffect(() => {
      if (play) {
        let interval = setInterval(() => {
          // console.log("hiii" + ind);
          if (ind === imgdetails.length - 1) {
            setindex(0);
          } else {
            let n = ind + 1;
            setindex(n);
          }
        }, 3000);

        return () => {
          // console.log(interval)
          clearInterval(interval);
        };
      }
    }, [ind, play]);
  function handleleftArrayclick() {
    // console.log("hii" + ind);
    if (ind === 0) {
      setindex(imgdetails.length - 1);
    } else {
      let pg = ind - 1;
      setindex(pg);
    }
  }

  function handlerightArrayclick() {
    console.log("hii" + ind);
    if (ind === imgdetails.length - 1) {
      setindex(0);
    } else {
      let pg = ind + 1;
      setindex(pg);
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
};

  return (
    <div className={styles.cont}>
      <div className={styles.cont1}>
        <div>
          <div className={styles.paragraphs}>

        <div className={styles.headingName}>
          <h1>{imgdetails[ind]?.name}</h1>
        </div>
        <div className={styles.desc}>
          <p>{imgdetails[ind]?.description}</p>
        </div>
        <div className={styles.dated}>
          <p>{formatDate(imgdetails[ind]?.date)}</p>
        </div>
        </div>
          <img
            style={{ width: "100%", maxHeight: "432px" }}
            src={`https://res.cloudinary.com/dlztstoso/image/upload/v1/${imgdetails[ind]?.url}`}
            alt=""
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.cont2}>
          <div className={styles.eventprev} onClick={handleleftArrayclick}>
            <img src={leftarrow} alt="" />
          </div>
          {imgdetails.map((elm, index) => {
            return (
              <Imagecomp
              url={`https://res.cloudinary.com/dlztstoso/image/upload/v1/${elm.url}`}
                id={index}
                handleClick={handleClick}
                key={elm.id}
                className={index === ind ? `styles.active` : ""}

              // className={styles.active}
              />
            );
          })}
          <div className={styles.eventnext} onClick={handlerightArrayclick}>
            <img src={rightarrow} alt="" />
          </div>
         
        </div>

      </div>
    </div>
  );
}

export default Image;
