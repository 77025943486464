import React, { useEffect, useState } from 'react';
import "./yourorder.css";
import ProfileBar from '../ProfileNavbar/ProfileNavbar';
import line from '../../media/line.svg';
import AllOrders from './order components/AllOrders';
import PreBookingOrders from './order components/PreBookingOrders';
import UpcomingOrders from './order components/UpcomingOrders';
import PastOrders from './order components/PastOrders';
import FailedOrders from './order components/FailedOrders';
import axios from 'axios';
import OrderLoader from './order components/OrderLoader';


const YourOrders = () => {
    const [selectedOption, setSelectedOption] = useState('All');
    const [ticketBookings, setTicketBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // State variable for loading status

    useEffect(() => {
        const apiUrl = 'https://api.youthevents.in/viewer/api/user-orders/';

        const user = JSON.parse(localStorage.getItem("viewer"));
        const viewerToken = localStorage.getItem("viewerToken");

        // Set isLoading to true before making the request
        setIsLoading(true);

        axios.get(apiUrl, { params: { user: user.id } })
            .then(response => {
                setTicketBookings(response.data);
                setIsLoading(false); // Set isLoading to false after receiving data
            })
            .catch(error => {
                console.error('Error fetching ticket bookings:', error);
                setIsLoading(false); // Set isLoading to false if an error occurs
            });
    }, []);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    let orderCategoryComponent;
    switch (selectedOption) {
        case 'All':
            orderCategoryComponent = <AllOrders orders={ticketBookings} />;
            break;
        case 'PreBooking':
            orderCategoryComponent = <PreBookingOrders orders={ticketBookings} />;
            break;
        case 'Upcoming':
            orderCategoryComponent = <UpcomingOrders orders={ticketBookings} />;
            break;
        case 'Past':
            orderCategoryComponent = <PastOrders orders={ticketBookings} />;
            break;
        case 'Failed':
            orderCategoryComponent = <FailedOrders orders={ticketBookings} />;
            break;
        default:
            orderCategoryComponent = <AllOrders orders={ticketBookings} />;
            break;
    }

    return (
        <section className="general-section">
            <ProfileBar />
            <h2 className="general-heading">Your Orders</h2>
            <img src={line} alt="Line" className="general-line" />
            <div className="orders-div">
                <div className="order-nav">
                    <a
                        className={selectedOption === 'All' ? 'active4' : ''}
                        onClick={() => handleOptionChange('All')}
                    >
                        All
                    </a>
                    <a
                        className={selectedOption === 'PreBooking' ? 'active4' : ''}
                        onClick={() => handleOptionChange('PreBooking')}
                    >
                        Pre Booking
                    </a>
                    <a
                        className={selectedOption === 'Upcoming' ? 'active4' : ''}
                        onClick={() => handleOptionChange('Upcoming')}
                    >
                        Upcoming
                    </a>
                    <a
                        className={selectedOption === 'Past' ? 'active4' : ''}
                        onClick={() => handleOptionChange('Past')}
                    >
                        Past
                    </a>
                    <a
                        className={selectedOption === 'Failed' ? 'active4' : ''}
                        onClick={() => handleOptionChange('Failed')}
                    >
                        Failed
                    </a>
                </div>

                {/* Render the loading spinner if isLoading is true */}
                {isLoading ? (
                    <OrderLoader />
                ) : (
                    // Otherwise, render the order components
                    orderCategoryComponent
                )}
            </div>
        </section>
    );
};

export default YourOrders;
