import React, { useEffect, useState } from 'react';
import './profilenavbar.css';
import { NavLink } from 'react-router-dom';
import Profile from '../../media/profile.svg';
import notification from '../../media/notification.svg';
import order from '../../media/order.svg';
import setting from '../../media/setting.svg';
import help from '../../media/help.svg';
import Profileblack from '../../media/profileblack.svg';
import notificationblack from '../../media/notiblack.svg';
import orderblack from '../../media/orderblack.svg';
import settingblack from '../../media/settingblack.svg';
import helpblack from '../../media/helpblack.svg';

const ProfileBar = () => {
  const [activePage, setActivePage] = useState('/');
  const [hoveredDiv, setHoveredDiv] = useState(null);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActivePage(currentPath);
  }, []);

  const handleMouseEnter = (e, page) => {
    setHoveredDiv(page);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  const isPageActive = (page) => {
    return page === activePage;
  };

  return (
    <div className="profile-bar">
      <NavLink
        exact
        to="/profile"
        className={`profile-button1 ${isPageActive('/profile') ? 'active4' : ''} ${hoveredDiv === '/profile' && !isPageActive('/profile') ? 'hovered' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, '/profile')}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={isPageActive('/profile') || (hoveredDiv === '/profile' && !isPageActive('/profile')) ? Profileblack : Profile}
          alt=""
        />{' '}
        Profile
      </NavLink>
      <NavLink
        exact
        to="/notifications"
        className={`profile-button2 ${isPageActive('/notifications') ? 'active4' : ''} ${hoveredDiv === '/notifications' && !isPageActive('/notifications') ? 'hovered' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, '/notifications')}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={isPageActive('/notifications') || (hoveredDiv === '/notifications' && !isPageActive('/notifications')) ? notificationblack : notification}
          alt=""
        />{' '}
        Notifications
      </NavLink>
      <NavLink
        exact
        to="/your-orders"
        className={`profile-button3 ${isPageActive('/your-orders') ? 'active4' : ''} ${hoveredDiv === '/your-orders' && !isPageActive('/your-orders') ? 'hovered' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, '/your-orders')}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={isPageActive('/your-orders') || (hoveredDiv === '/your-orders' && !isPageActive('/your-orders')) ? orderblack : order}
          alt=""
        />{' '}
        Your Orders
      </NavLink>
      <NavLink
        exact
        to="/account-settings"
        className={`profile-button4 ${isPageActive('/account-settings') ? 'active4' : ''} ${hoveredDiv === '/account-settings' && !isPageActive('/account-settings') ? 'hovered' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, '/account-settings')}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={isPageActive('/account-settings') || (hoveredDiv === '/account-settings' && !isPageActive('/account-settings')) ? settingblack : setting}
          alt=""
        />{' '}
        Account Settings
      </NavLink>
      <NavLink
        exact
        to="/help-and-support"
        className={`profile-button5 ${isPageActive('/help-and-support') ? 'active4' : ''} ${hoveredDiv === '/help-and-support' && !isPageActive('/help-and-support') ? 'hovered' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, '/help-and-support')}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={isPageActive('/help-and-support') || (hoveredDiv === '/help-and-support' && !isPageActive('/help-and-support')) ? helpblack : help}
          alt=""
        />{' '}
        Help and Support
      </NavLink>
    </div>
  );
};

export default ProfileBar;
