import React from "react";
import "./TimePicker.css";
import Calendar from "./Calendar";
import TimeSelect from "./TimeSelect";
import { useDispatch } from "react-redux"; 
import { setDate } from "../../redux/filtersSlice";
import CalendarEvent from "./CalenderEvent";

const TimePickerEvent = ({ flexDirection , futureEvents , onDateSelect  }) => {
    const dispatch = useDispatch(); 

    console.log("future event in time picker",futureEvents)

    const [year, setYear] = React.useState(new Date().getFullYear());
    const [month, setMonth] = React.useState(new Date().getMonth());
    const [pickedDate, setPickedDate] = React.useState(null);
    const monthList = [
        "January",
        "Febuary",
        "March",
        "Аpril",
        "Мay",
        "June",
        "July",
        "Аugust",
        "September",
        "Оctober",
        "November",
        "December"
    ];
    const selectNextMonth = () => {
        if (month < 11) {
            setMonth((m) => m + 1);
        } else {
            setMonth(0);
            setYear((y) => y + 1);
        }
    };
    const selectPreviousMonth = () => {
        if (month > 0) {
            setMonth((m) => m - 1);
        } else {
            setMonth(11);
            setYear((y) => y - 1);
        }
    };

    
    const handleDaySelect = (day) => {
        const selectedDate = new Date(year, month, day);
        const selectedEventData = futureEvents.filter(event => {
            const eventDate = new Date(event.date);
            return eventDate.getFullYear() === selectedDate.getFullYear() &&
                eventDate.getMonth() === selectedDate.getMonth() &&
                eventDate.getDate() === selectedDate.getDate();
        });
    
        dispatch(setDate(selectedDate));
        setPickedDate(selectedDate);
        onDateSelect(selectedEventData);
    };
    const showTimeSelect = () => {
        const currentDate = new Date();
        const pickedDateString = pickedDate
            ? pickedDate.toLocaleDateString("default", {
                month: "short",
                day: "numeric",
            })
            : "";
        const todayDateString = currentDate.toLocaleDateString("default", {
            month: "short",
            day: "numeric",
        });

        return (
            <div className="selected-date">
                <div className="picked-date">
                    {pickedDate ? pickedDateString : todayDateString}
                </div>
            </div>
        );
    };


    return (
        <div className="time-picker">
            <div>
                <div className="top-menu">
                    Select Date
                </div>
                {pickedDate ? showTimeSelect() : showTimeSelect()}
                <div className="selector-list" style={{ flexDirection }}> {/* Apply flexDirection from prop */}
                    <div className="selector">
                        <button onClick={() => setYear((y) => y - 1)}>
                            &lt;
                        </button>
                        <div className="year">{year}</div>
                        <button onClick={() => setYear((y) => y + 1)}>
                            &gt;
                        </button>
                    </div>
                    <div className="selector">
                        <button onClick={selectPreviousMonth}>&lt;</button>
                        <div className="month">{monthList[month]}</div>
                        <button onClick={selectNextMonth}>&gt;</button>
                    </div>
                </div>
                <CalendarEvent
                    year={year}
                    month={month}
                    onDaySelect={handleDaySelect}
                    selectedDate={pickedDate} // Pass the pickedDate as selectedDate
                    futureEvents={futureEvents}
                />
                {/* <div className="buttons">
                    <div className="cancel">Cancel</div>
                    <div className="ok">OK</div>
                </div> */}
            </div>
        </div>
    );
};

export default TimePickerEvent;
