import React, { useState, useEffect } from 'react';
import "./eventtype.css";
import line from "../../media/line.svg";
import date from "../../media/date.svg";
import location from "../../media/location.svg";
import ImageSlider from '../MainImageSider/ImageSlider';
import TimePicker from '../Event calender/TimePicker';
import { Slider } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Tabfilter from './Tabfilter';
import MobileFilter from './MobileFilter';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const findMaxPrice = (events) => {
  if (!events || events.length === 0) {
    return 0;
  }

  let maxPrice = -Infinity;

  events.forEach((event) => {
    event.ticket_types.forEach((ticketType) => {
      if (ticketType.ticketPrice > maxPrice) {
        maxPrice = ticketType.ticketPrice;
      }
    });
  });

  return maxPrice;
};

const PersonalGrowth = () => {
  const { cityName } = useParams();
  const [maxPrice, setMaxPrice] = useState(10000);
  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [discountRange, setDiscountRange] = useState([10, 50]);
  const [selectedCategory, setSelectedCategory] = useState('latest');
  const [selectedSubcategory, setSelectedSubcategory] = useState(null); // State to track selected subcategory

  const [carouselData, setCarouseldata] = useState()
  const fetchBasicEventDetails = async (cityName) => {
    try {
      const response = await axios.get(` https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
      const basicDetails = response.data;
      console.log(basicDetails);
      setCarouseldata(basicDetails);
    } catch (error) {
      console.error('Error fetching basic event details:', error);
    }
  };


  const [topBanners, setTopBanners] = useState([]);

  const fetchData = async (cityName) => {
    try {
      const topBannersResponse = await axios.get(` https://api.youthevents.in/publisher/api/top-banners/?city=${cityName}`);
      setTopBanners(topBannersResponse.data);


    } catch (error) {
      console.error('Error fetching data', error);
    }
  };


  useEffect(() => {
    fetchBasicEventDetails(cityName);
    fetchData(cityName);

  }, [cityName]);


  const filterData = carouselData?.filter((data) => data.category == "Personal growth")


  const getImages = (num) => {
    const images = [];
    for (let i = 7; i <= num; i++) {
      images.push({
        img: `https://picsum.photos/seed/${i}/1280/500`
      });
    }
    return images;
  };
  const images = getImages(8);

  const getMinTicketPrice = (data) => {
    let minPrice = Infinity;
    data?.ticket_types?.forEach((ticketType) => {
      if (ticketType.ticketPrice < minPrice) {
        minPrice = ticketType.ticketPrice;
      }
    });
    return minPrice;
  };


  const renderAvailabilityStatus = (item) => {

    let totalTicketsLeft = 0;
    item.ticket_types.forEach((ticketType) => {
      totalTicketsLeft += ticketType.availableTickets;
    });


    if (totalTicketsLeft <= 30) {
      return (
        <div className="availability-status-red">
          <div className="status-tag">{totalTicketsLeft} Ticket Left</div>
        </div>
      );
    } else if (totalTicketsLeft < 100) {
      return (
        <div className="availability-status-yellow">
          <div className="status-tag">Filling Fast</div>
        </div>
      );
    }
    return (
      <div className="available">
        available
      </div>

    )
  }
    const [languagesOfIndia, setLanguagesOfIndia] = useState([
    'English',
    'Hindi',
    'Bengali',
    'Telugu',
    'Marathi',
    'Tamil',
    'Urdu',
    'Gujarati',
    'Kannada',
    'Odia',
    'Punjabi',
    'Malayalam',
    'Assamese',
    'Sanskrit',
  ]);

  const extractUniqueLanguages = (events) => {
    const languageSet = new Set();
    events?.forEach((event) => {
      const languages = event.language.split(',')?.map((lang) => lang.trim());
      languages.forEach((lang) => languageSet.add(lang));
    });
    return Array.from(languageSet);
  };

 
  useEffect(() => {
    if (filterData?.length > 0) {
      const uniqueLanguages = extractUniqueLanguages(filterData);
      setLanguagesOfIndia(uniqueLanguages)
      // Filter the `languagesOfIndia` list to only include languages present in events
      
    }
  }, [filterData]);

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleLanguageChange = (language) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleDiscountChange = (value) => {
    setDiscountRange(value);
  };


  const selectedDate = useSelector((state) => state.filters.date);

  // Convert the date to YYYY-MM-DD format
  const year = selectedDate?.getFullYear();
  const month = String(selectedDate?.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(selectedDate?.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;


  const filteredEvents = filterData?.filter((event) => {
    const eventDate = event.date;
    const eventPrice = getMinTicketPrice(event)
    // const eventDiscount = event.discount;

    const dateFilterPassed = selectedDate === null || eventDate.toString() == formattedDate.toString();
    const languageFilterPassed = selectedLanguages.length === 0 || (event.language).includes(selectedLanguages);
    const priceFilterPassed = eventPrice >= priceRange[0] && eventPrice <= priceRange[1];
    // const discountFilterPassed = eventDiscount >= discountRange[0] && eventDiscount <= discountRange[1];
    const categoryFilterPassed = selectedCategory === 'latest' || event.category === selectedCategory;
    const subcategoryFilterPassed = !selectedSubcategory || event.subCategory === selectedSubcategory;

    return dateFilterPassed && languageFilterPassed && priceFilterPassed &&
      // discountFilterPassed &&
      categoryFilterPassed && subcategoryFilterPassed;
  });

  const sortEvents = (events, category) => {
    if (!events || events.length === 0) {
        return [];
    }

    // Sort based on selected category
    switch (category) {
        case 'most-viewed':
            // Sort by views in descending order
            return [...filterData].sort((a, b) => b.views - a.views);
        case 'most-sold':
            // Sort by Ticket_Sold in descending order
            return [...filterData].sort((a, b) => b.Ticket_Sold - a.Ticket_Sold);
        case 'latest':
        default:
            // Sort by date in ascending order (latest events come first)
            return [...filterData].sort((a, b) => new Date(a.date) - new Date(b.date));
    }
};

// Inside the render function
const sortedEvents = sortEvents(filteredEvents, selectedCategory);

  const categories = [
    { key: 'latest', label: 'Latest' },
    { key: 'most-viewed', label: 'Most-Viewed' },
    { key: 'most-sold', label: 'Most-Sold' },
  ];

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const subcategories = [
    'Seminars',
    'Workshops',
    'Meditation ',
    'Networking Events',
    'Job Fair',
    'Yoga Retreats',
    'Motivational Speaker Event',
  ];

  useEffect(() => {
    const calculatedMaxPrice = findMaxPrice(carouselData);
    setMaxPrice(calculatedMaxPrice);
  }, [carouselData]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  };

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    } else {
        return str;
    }
}

  return (
    <div className='events-div'>
      <div className="main-content">
        {/* <ImageSlider images={topBanners ? topBanners : images} /> */}
        <div className="line-head">
          <h2 className="event-heading">Personal Growth events in {cityName}</h2>
          <img src={line} alt="Line" className="general-line" />
        </div>
        <div className="subcategory">
          {subcategories.map((subcategory) => (
            <div
              key={subcategory}
              className={`sub-category ${selectedSubcategory === subcategory ? 'selected-subcategory' : ''}`}
              onClick={() => handleSubcategoryClick(subcategory)}
            >
              {subcategory}
            </div>
          ))}
        </div>
        <Tabfilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
          discountRange={discountRange}
          setDiscountRange={setDiscountRange}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
        />

        {/* mobile filter */}
        <MobileFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        discountRange={discountRange}
        setDiscountRange={setDiscountRange}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        />
        <div className="filter-cards">
          <div className="filter">
            <div className="calender">
              <TimePicker flexDirection={"column"}  futureEvents={filterData}/>
            </div>
            <div className="view-sold">
              {categories.map((category) => (
                <div
                  key={category.key}
                  className={` ${selectedCategory === category.key ? 'selected1' : category.key}`}
                  onClick={() => setSelectedCategory(category.key)}
                >
                  {category.label}
                </div>
              ))}
            </div>
            <div className="languages">
              <div className="langu">Language</div>
              <div className="laguage-checkbox">
                {languagesOfIndia.map((language) => (
                  <div key={language} className="language-item">
                    <input
                      type="checkbox"
                      id={language}
                      name={language}
                      checked={selectedLanguages.includes(language)}
                      onChange={() => handleLanguageChange(language)}
                    />
                    <span className='select-language'>{language}</span>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="discount">
              <div className="discount-head">
                Discount
              </div>
              <div className="price-range">


                {discountRange[0]}% - {discountRange[1]}%
              </div>
              <div className="discount-slider">
                <Slider
                  range
                  step={10}
                  defaultValue={[10, 50]}
                  min={0}
                  max={75}
                  onChange={handleDiscountChange}
                  railStyle={{ backgroundColor: "#959595" }}
                  trackStyle={{ backgroundColor: "#959595" }}
                />
                <div className="ranges">
                  <p>0%</p>
                  <p>75%</p>

                </div>
              </div>

            </div> */}
            <div className="discount">
              <div className="discount-head">
                Price
              </div>
              <div className="price-range">
                ₹ {priceRange[0]} - ₹ {priceRange[1]}
              </div>
              <div className="discount-slider">
                <Slider
                  range
                  step={10}
                  defaultValue={[10, maxPrice]}
                  min={0}
                  max={maxPrice}
                  onChange={handlePriceChange}
                  railStyle={{ backgroundColor: "#959595" }}
                  trackStyle={{ backgroundColor: "#959595" }}
                />
                <div className="ranges">
                  <p>Free</p>
                  <p>{maxPrice}</p>

                </div>
              </div>
            </div>
            
          </div>
          <div className="cards row">
          {sortedEvents?.length == 0 ? <div className='No-event'>No Event Available For The selected Filter</div> : (<>
              {sortedEvents?.map((item, index) => (
                <Link
                  key={item.id}
                  to={{
                    pathname: `/event-detail/${item.id}`
                  }}
                  className={`carousel-card2  `}
                >
                  {renderAvailabilityStatus(item)}
                  <div className="image1">
                    <img src={item.thumbnail_image} alt={item.eventName} />
                  </div>
                  <div className="details1">
                    <div className='tittle1'>{truncateString(item.eventName, 24)}</div>
                    <div className="icon-text1">
                      <img src={date} alt="Date" className="icon1" />
                      <span className="date1">{formatDate(item.date)}</span>
                    </div>
                    <div className="icon-text1">
                      <img src={location} alt="Location" className="icon1" />
                      <span className="location1">{truncateString(item.location, 35)}</span>
                    </div>
                    <div className="price1">₹ {getMinTicketPrice(item)} Onwards</div>
                  </div>
                </Link>
              ))}
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
};


export default PersonalGrowth
