import React, { useState } from 'react';
import CustomCarousel from '../components/CustomCarousel/CustomCarousel';
import './Customsection.css'; 
import line from "../media/line.svg";
import more from "../media/more.svg";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios  from 'axios';


function CustomSection({ heading, carouselData, seeMoreLink }) {



  
  console.log("link",seeMoreLink)
  console.log("data",carouselData)
  return (
    <section className="custom-section">
      <h2 className="section-heading">{heading}</h2>
      <img src={line} alt="Line" className="section-line" />


      <CustomCarousel data={carouselData} />
      {/* <CustomCarousel /> */}
      <div className="see-more-link">
      <Link to={seeMoreLink} >
  See More
</Link>
  <img src={more} alt="See More" className="see-more-icon" />

      </div>
    </section>
  );
}

export default CustomSection;
