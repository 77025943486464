import React from 'react';
import './PrivacyPolicy.css';
import line from '../../media/line.svg';
import Accordian from '../Accordian/Accordian';

const PrivacyPolicy = () => {
  const privacyPolicyData = [
    {
      question: 'Information We Collect',
      answer: `We may collect personal information such as your name, email address, and location when you create an account, browse events, or interact with our platform.`,
    },
    {
      question: 'How We Use Your Information',
      answer: `We use your personal information to provide and improve our services, personalize your experience, and communicate with you about events and updates.`,
    },
    {
      question: 'Sharing Your Information',
      answer: `We may share your information with event organizers, payment processors, and service providers to facilitate event bookings and payments.`,
    },
    {
      question: 'Cookies and Tracking',
      answer: `We use cookies and similar tracking technologies to enhance your browsing experience and gather information about how you use our platform.`,
    },
    {
      question: 'Data Security',
      answer: `We implement security measures to protect your personal information from unauthorized access and disclosure.`,
    },
    {
      question: "Children's Privacy",
      answer: `Youth Events is not intended for users under the age of 13. If we discover that personal information has been collected from a child under 13, we will take appropriate steps to delete the information.`,
    },
    {
      question: 'Changes to Privacy Policy',
      answer: `We may update this Privacy Policy to reflect changes in our practices. Please review the policy periodically for any updates.`,
    },
    {
      question: 'Contact Us',
      answer: `If you have any questions or concerns about this Privacy Policy, please contact us at [contact@youthevents.in].`,
    },
  ];

  const termsConditionsData = [
    {
      question: 'Account Registration',
      answer: `You must be at least 18 years old to create an account on Youth Events. By creating an account, you confirm that you are of legal age.`,
    },
    {
      question: 'Event Creation and Promotion',
      answer: `When creating events on Youth Events, you agree to provide accurate and up-to-date information, including event details, dates, and locations.`,
    },
    {
      question: 'Ticket Purchases',
      answer: `By purchasing tickets through Youth Events, you agree to provide accurate payment and billing information.`,
    },
    {
      question: 'Code of Conduct',
      answer: `You agree to use Youth Events for lawful purposes only and to comply with all applicable laws and regulations.`,
    },
    {
      question: 'Intellectual Property',
      answer: `The content on Youth Events, including text, graphics, logos, and images, is protected by intellectual property laws.`,
    },
    {
      question: 'Privacy',
      answer: `Your use of Youth Events is subject to our Privacy Policy.`,
    },
    {
      question: 'Termination of Services',
      answer: `We reserve the right to suspend or terminate your account and access to our platform if you violate these Terms and Conditions or engage in any activities that may harm the integrity of our services.`,
    },
    {
      question: 'Governing Law',
      answer: `These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].`,
    },
    {
      question: 'Changes to Terms and Conditions',
      answer: `We may update these Terms and Conditions from time to time.`,
    },
    {
      question: 'Contact Us',
      answer: `If you have any questions or concerns about these Terms and Conditions, please contact us at [contact@youthevents.in].`,
    },
  ];

  const refundCancellationData = [
    {
      question: 'Refund Policy',
      answer: `Tickets purchased through Youth Events are non-refundable, except in the case of event cancellation.`,
    },
    {
      question: 'Cancellation Policy',
      answer: `Once tickets are booked, they cannot be canceled or transferred to another individual.`,
    },
    {
      question: 'Event Cancellation',
      answer: `If an event is canceled by the organizer, Youth Events will facilitate the refund process as outlined in the refund policy.`,
    },
    {
      question: 'Refund Request Process',
      answer: `To request a refund for a canceled event, please contact us at [refund@youthevents.in] within [number of days] from the date of the cancellation notice.`,
    },
    {
      question: 'Gateway or Payment Charges',
      answer: `Gateway or payment charges are incurred during the transaction process and are non-refundable, except in cases where the event is canceled.`,
    },
    {
      question: 'Force Majeure',
      answer: `Youth Events and event organizers shall not be held liable for refunds or damages if an event is canceled due to circumstances beyond our control.`,
    },
    {
      question: 'Contact Us',
      answer: `For refund-related inquiries or concerns, please contact us at [refund@youthevents.in].`,
    },
  ];

  return (
    <section className="general-section">
      <h2 className="general-heading">Privacy Policy</h2>
      <img src={line} alt="Line" className="general-line" />

      <h1 className='privacy-heading'>Guidelines for YouthEvents</h1>
      <p className="privacy1">Welcome to YouthEvents! This privacy policy outlines how we collect, use, and protect your personal information when you use our website and services. By accessing and using YouthEvents, you consent to the practices described in this policy. Please take a moment to read it carefully.</p>
      <Accordian data={privacyPolicyData} />

      <h1 className='privacy-heading' style={{marginTop:"0",marginBottom:"0"}}>Terms and Conditions for Youth Events</h1>
      <Accordian data={termsConditionsData} />

      <h1 className='privacy-heading'style={{marginTop:"0",marginBottom:"0"}}>Refund and Cancellation Policy for Youth Events</h1>
      <Accordian data={refundCancellationData} />
    </section>
  );
};

export default PrivacyPolicy;
