import { React, useState, useRef } from 'react'
import organiser from '../../../media/organiser.svg';
import ctob from '../../../media/ctob.svg';
import wl from '../../../media/wl.svg';
import cont from '../../../media/cont.svg';
import qrop from '../../../media/qrop.svg';
import direction from '../../../media/direction.svg';
import invoice from '../../../media/invoice.svg';
import ContactOrganizerPopup from './ContactOrgainiserpopup';
import location from "../../../media/blocation.svg"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import QRCodePopup from './QRcodepopup';
import PDFTicket from '../../PDF Ticket/PDFTicket';
import headl from "../../../media/headl.svg";



const AllOrders = ({ orders }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const user = JSON.parse(localStorage.getItem("viewer"));


    const containerRef = useRef(null);

    const openMessagePopup = (message) => () => {
        setSelectedMessage(message);
        openPopup();
    };

    const [showQRPopup, setShowQRPopup] = useState(false);
    const openQRPopup = (message) => () => {
        setSelectedMessage(message);
        setShowQRPopup(true)
    };
    const closeQRPopup = () => {
        setShowQRPopup(false);
        setSelectedMessage(null);

    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        setSelectedMessage(null);

    };


    const openInGoogleMaps = (event) => {
        const addressQuery = encodeURIComponent(event.eventLocation);
        window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`, '_blank');

    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };





    function convertTo12Hour(time) {
        const [hour, minute, second] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    const [selectedOrder, setSelectedOrder] = useState(null);
    const pdfTicketRef = useRef(null);

    const downloadPDF = async (order) => {
        // Set the selected order state
        setSelectedOrder(order);

        // Allow time for the PDFTicket component to render with the selected order data
        await new Promise((resolve) => setTimeout(resolve, 2000));

        try {
            // Capture the PDFTicket component as a canvas
            const canvas = await html2canvas(pdfTicketRef.current, {
                useCORS: true,
                scrollY: -window.scrollY,
                willReadFrequently: true
            });

            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/png');

            // Calculate the width and height of the canvas
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Create a new jsPDF instance with custom page size matching the canvas dimensions
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt', // Set units to points
                format: [canvasWidth, canvasHeight] // Use canvas width and height as PDF format
            });

            // Add the image to the PDF, filling the entire custom page
            pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);


            // Save the PDF
            pdf.save('ticket.pdf');

            // Clear selected order state
            setSelectedOrder(null);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };



    console.log("object", selectedOrder)



    return (
        <>
            {orders.length > 0 ? (
                <>
                    {orders.map((order) => (
                        <div className="order-ticket" key={order.id} ref={containerRef}>
                            <div className="booking-detail">
                                <div className="event-image">
                                    <img src={order.event.thumbnail_image} alt="" className='order-img' />
                                </div>
                                <div className="event-buttons">
                                    <div className="book-detail">
                                        <span className="booking-id">Booking Details</span>
                                        <span className="order-id"><b>Order id :</b>{order.id}</span>
                                        <span className="booking-date">{formatDate(order.booking_time)}</span>
                                    </div>
                                    <div className="invoice-location">
                                        <div className="invoice" onClick={() => downloadPDF(order)}>
                                            <img src={invoice} alt="" />
                                        </div>
                                        <div className="locationn-btn" onClick={() => openInGoogleMaps(order.event)}>
                                            <img src={direction} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="booking-info">
                                <div className="tcover">
                                    <div className="ttop">
                                        <div className="topleft">
                                            <div className="book-name">{order.event.eventTitle}</div>
                                            <div className="location-order">
                                                {/* <img className='white' src={location} alt="" /> */}
                                                <img className='' src={wl} alt="" />
                                                <div className="location-event">{order.event.eventLocation}</div>
                                            </div>

                                        </div>
                                        <div className="topright">
                                            <div className="book-language">{order.event.eventLanguage}</div>
                                            <div className="event-date">{formatDate(order.event.eventDate)}</div>
                                            <div className="event-times">{convertTo12Hour(order.event.eventTime)}</div>
                                        </div>

                                    </div>
                                    <div className="tbottom">
                                        <div className="bottomleft">
                                            <div className="ticket-counts1">


                                                {order.booking_details.map((ticket) => (
                                                    <div className="ticket-type1">
                                                        <div className="ticket-name1">
                                                            {ticket.ticket_type.name}
                                                        </div>

                                                        <div className="ticket-number1">
                                                            {ticket.quantity} Ticket
                                                        </div>

                                                        <div className="ticket-price1">₹ {ticket.ticket_type.ticketPrice}</div>

                                                        {/* <QRCode value={ticket.id} /> */}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="total-price1">
                                                <div className="prix">
                                                    ₹ {order.amount_paid}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="bottomright">
                                            <div className="download" onClick={openMessagePopup(order)}>
                                                <img src={cont} alt="" />
                                            </div>
                                            <div className="qrdown" onClick={openQRPopup(order)}>
                                                <img src={qrop} alt="" />

                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>

                    ))}
                </>
            ) : (
                <div className='noe' style={{ height: "250px" }}>
                    No orders to Show
                </div>
            )}


            <div style={{ zIndex:"-999", position:"absolute" , opacity:"0" }}>

                {selectedOrder && (

                    <div className="pdf-ticket" ref={pdfTicketRef}>
                        <div className="pdf-head">
                            <div className="head-left">
                                <div className="logo-head">
                                    <img src='https://res.cloudinary.com/dlztstoso/image/upload/v1714112474/ye_zbk0ri.svg' alt="" />
                                    <div className="head-titles">
                                        <h2>Youth Events</h2>
                                        <h3>This is a YE Tag Line.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="head-right">
                                <h3>Hello, {user.name}</h3>
                                <p>Your Ticket to the Event</p>
                            </div>

                        </div>
                        <div className="silver-detail">
                            <p>Scan the QR Code during Event Check-in .</p>
                        </div>
                        <div className="pdf-detail">
                            <div className="pdf-left">
                                <img style={{ objectFit: "cover" }} src={selectedOrder.event.thumbnail_image} alt="" />
                                <div className="pdf-detailing">
                                    <h1>{selectedOrder.event.eventTitle} </h1>
                                    <h2>{selectedOrder.event.eventLocation}</h2>
                                    <h3>{selectedOrder.event.eventLanguage}</h3>
                                    <h4>{formatDate(selectedOrder.event.eventDate)}</h4>
                                    <p>{convertTo12Hour(selectedOrder.event.eventTime)}</p>

                                </div>
                            </div>

                            <div className="mid-line"></div>

                            <div className="pdf-right">
                                <h2>Booking Details</h2>
                                <h3>Order id : {selectedOrder.id}</h3>
                                <p>{formatDate(selectedOrder.booking_time)}</p>


                                <div className="ticket-counts1">

                                    {selectedOrder.booking_details.map((ticket) => (
                                        <div className="ticket-type1">
                                            <div className="ticket-name1 pdfticketname1">
                                                {ticket.ticket_type.name}
                                            </div>

                                            <div className="ticket-number1 pdfticketnumber1">
                                                {ticket.quantity} Ticket
                                            </div>

                                            <div className="ticket-price1 pdfticketprice1">₹ {ticket.ticket_type.ticketPrice}</div>

                                            {/* <QRCode value={ticket.id} /> */}
                                        </div>
                                    ))}


                                </div>
                                <div className="total-price1 pdftickettotal1">
                                    Amount Paid
                                    <div className="prix pdfticketprix1">
                                        ₹ {selectedOrder.amount_paid}
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="qr-area">

                            {selectedOrder.booking_details.map((ticket) => (
                                <div className="pdf-qrdetail">
                                    <QRCode value={ticket.id} style={{ height: "200px", width: "200px" }} />
                                    <div className="pdfqrinfo">
                                        <h2>{ticket.ticket_type.name} </h2>
                                        <h3>{ticket.quantity} Ticket</h3>
                                        <p>{ticket.id}</p>
                                    </div>

                                </div>

                            ))}

                            {/* map this one */}


                        </div>

                        <div className="silver-detail" style={{ height: "125px" }}>

                        </div>


                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="email-container" >
                            <tr>
                                <td className="email-header">
                                    <img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/Main_pybu5a.svg" alt="Youth Events Logo" />
                                </td>

                                <td className="email-content">
                                    <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit aliqua.</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="social-icons">
                                        <tr style={{ display: "contents", width: "100%" }}>
                                            <td align="center" >
                                                <a href="your-instagram-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/instagram_jvnq1c.svg" alt="Instagram" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-email-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/x_hfoe3p.svg" alt="Email" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-facebook-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112472/fb_d3xqvr.svg" alt="Facebook" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-youtube-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112474/youtube_lyxo72.svg" alt="YouTube" /></a>
                                            </td>
                                        </tr>


                                    </table>

                                    <p className='tp' >
                                        <a href="mailto:your-email@example.com">mailtoyouthevent.info</a>
                                    </p>

                                </td>
                            </tr>


                        </table>
                        <table className='righttable'>
                            <tbody style={{ marginLeft: "52px", width: "100%" }}>
                                <tr >
                                    <td>All rights reserved</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                )}
            </div>

            {showQRPopup && <QRCodePopup ticket={selectedMessage} onClose={closeQRPopup} />}

            {showPopup && <ContactOrganizerPopup data={selectedMessage} onClose={closePopup} />}

        </>
    )
}

export default AllOrders
