import React from 'react'
import ContentLoader from "react-content-loader"


const OrderLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={957}
            height={400}
            viewBox="0 0 957 400"
            backgroundColor="#262626"
            foregroundColor="#242424"
            style={{ width: '100%', height: "100%", maxWidth: "980px" }}
            {...props}
        >
            <rect x="5" y="22" rx="0" ry="0" width="170" height="164" />
            <rect x="4" y="201" rx="0" ry="0" width="166" height="95" />
            <rect x="89" y="312" rx="0" ry="0" width="80" height="48" />
            <rect x="221" y="23" rx="0" ry="0" width="670" height="330" />
            <rect x="6" y="312" rx="0" ry="0" width="80" height="48" />
        </ContentLoader>
    )
}

export default OrderLoader
