import { React, useState, useRef } from 'react'
import organiser from '../../../media/organiser.svg';
import ctob from '../../../media/ctob.svg';
import wl from '../../../media/wl.svg';
import cont from '../../../media/cont.svg';
import qrop from '../../../media/qrop.svg';
import direction from '../../../media/direction.svg';
import invoice from '../../../media/invoice.svg';
import ContactOrganizerPopup from './ContactOrgainiserpopup';
import location from "../../../media/blocation.svg"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import QRCodePopup from './QRcodepopup';

const UpcomingOrders = ({ orders }) => {
    const containerRef = useRef(null);

    const [showPopup, setShowPopup] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [hoveredDiv, setHoveredDiv] = useState(null);

    const handleMouseEnter = (e, page) => {
        setHoveredDiv(page);
    };

    const handleMouseLeave = () => {
        setHoveredDiv(null);
    };




    const openMessagePopup = (message) => () => {
        setSelectedMessage(message);
        openPopup();
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        setSelectedMessage(null);

    };

    const openInGoogleMaps = (event) => {
        const addressQuery = encodeURIComponent(event.eventLocation);
        window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`, '_blank');

    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };

    const downloadPDF = async () => {
        const pdf = new jsPDF();
        const canvas = await html2canvas(containerRef.current, { scrollY: -window.scrollY });
        const imgData = canvas.toDataURL('image/png');

        // Adjust canvas size dynamically based on content height
        let imgWidth = pdf.internal.pageSize.getWidth();
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Check if the content height exceeds the page height
        if (imgHeight > pdf.internal.pageSize.getHeight()) {
            // Calculate scale factor to fit content within page height
            const scaleFactor = pdf.internal.pageSize.getHeight() / imgHeight;
            imgWidth *= scaleFactor;
            imgHeight *= scaleFactor;
        }

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('ticket.pdf');
    };




    const futureEvents = orders.filter(order => new Date(order.event.eventDate) > new Date());

    const [showQRPopup, setShowQRPopup] = useState(false);
    const openQRPopup = (message) => () => {
        setSelectedMessage(message);
        setShowQRPopup(true)
    };
    const closeQRPopup = () => {
        setShowQRPopup(false);
        setSelectedMessage(null);

    };
    function convertTo12Hour(time) {
        const [hour, minute, second] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    }
    return (
        <>
         {futureEvents.length > 0 ? (
            <>
            {futureEvents.map((order) => (
                <div className="order-ticket" key={order.id} ref={containerRef}>
                    <div className="booking-detail">
                        <div className="event-image">
                            <img src={order.event.thumbnail_image} alt="" className='order-img' />
                        </div>
                        <div className="event-buttons">
                            <div className="book-detail">
                                <span className="booking-id">Booking Details</span>
                                <span className="order-id"><b>Order id :</b>{order.id}</span>
                                <span className="booking-date">{formatDate(order.booking_time)}</span>
                            </div>
                            <div className="invoice-location">
                                <div className="invoice" onClick={() => downloadPDF(order)}>
                                    <img src={invoice} alt="" />
                                </div>
                                <div className="locationn-btn" onClick={() => openInGoogleMaps(order.event)}>
                                    <img src={direction} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="booking-info">
                        <div className="tcover">
                            <div className="ttop">
                                <div className="topleft">
                                    <div className="book-name">{order.event.eventTitle}</div>
                                    <div className="location-order">
                                        {/* <img className='white' src={location} alt="" /> */}
                                        <img className='' src={wl} alt="" />
                                        <div className="location-event">{order.event.eventLocation}</div>
                                    </div>

                                </div>
                                <div className="topright">
                                    <div className="book-language">{order.event.eventLanguage}</div>
                                    <div className="event-date">{formatDate(order.event.eventDate)}</div>
                                    <div className="event-times">{convertTo12Hour(order.event.eventTime)}</div>
                                </div>

                            </div>
                            <div className="tbottom">
                                <div className="bottomleft">
                                    <div className="ticket-counts1">


                                        {order.booking_details.map((ticket) => (
                                            <div className="ticket-type1">
                                                <div className="ticket-name1">
                                                    {ticket.ticket_type.name}
                                                </div>

                                                <div className="ticket-number1">
                                                    {ticket.quantity} Ticket
                                                </div>

                                                <div className="ticket-price1">₹ {ticket.ticket_type.ticketPrice}</div>

                                                {/* <QRCode value={ticket.id} /> */}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="total-price1">
                                        <div className="prix">
                                            ₹ {order.amount_paid}
                                        </div>
                                    </div>

                                </div>
                                <div className="bottomright">
                                    <div className="download" onClick={openMessagePopup(order)}>
                                        <img src={cont} alt="" />
                                    </div>
                                    <div className="qrdown" onClick={openQRPopup(order)}>
                                        <img src={qrop} alt="" />

                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>

            ))}
            </>
        ) : (
            <div className='noe' style={{height:"250px"}}>
                No Future events orders to Show
            </div>
        )}
            

            {showQRPopup && <QRCodePopup ticket={selectedMessage} onClose={closeQRPopup} />}

            {showPopup && <ContactOrganizerPopup data={selectedMessage} onClose={closePopup} />}

        </>

    )
}

export default UpcomingOrders
