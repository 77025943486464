import React, { useEffect, useState } from 'react'
import "./eventcalender.css"
import line from "../../media/line.svg";
import CustomSection from '../../CustomSections/CustomSection';
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import EventCarousel from './EventCarousel';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TimePickerEvent from './TimePickerEvent';



const EventCalender = () => {
    const { cityName } = useParams();
    const [events, setEvents] = useState([]);
    const fetchBasicEventDetails = async (cityName) => {
      try {
        const response = await axios.get(` https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
        const basicDetails = response.data;
        console.log(basicDetails);
        setEvents(basicDetails);
      } catch (error) {
        console.error('Error fetching basic event details:', error);
      }
    };
  
    
  
    useEffect(() => {
      fetchBasicEventDetails(cityName);
  
    }, [cityName]);
    const [carouselData, setCarouselData] = useState(events)

    const handleDateSelect = (selectedEventData) => {
        // Your function logic here
        setCarouselData(selectedEventData)
        console.log("Selected event data:", selectedEventData);
    };

    const selectedCity = localStorage.getItem('selectedCity');


    return (
        <section className="general-section">
            <h2 className="general-heading">Events Calender</h2>
            <img src={line} alt="Line" className="general-line" />
            <div className="upper-calender">
                <div className="wrapper">
                    <TimePickerEvent futureEvents={events} onDateSelect={handleDateSelect} />
                </div>
                <div className="event-carousel">
                  {carouselData.length > 0 ? <EventCarousel data={carouselData}/> : (
                    <h3 className="notoshow">
                      No event Available
                    </h3>
                  )}
                    
                </div>
            </div>
            <CustomSection heading={"You May Also Like"} seeMoreLink={`/${selectedCity}/You May Also Like`} carouselData={events}/>
        </section>
    )
}

export default EventCalender