import React, { useState, useEffect } from 'react';
import './Navbar.css';
import navlogo from '../../media/navLogo.svg';
import lines3 from '../../media/3lines.svg';
import lines9 from '../../media/9lines.svg';
import Profile from '../../media/profile.svg';
import place from '../../media/place.jpg';
import event from '../../media/event.svg';
import notification from '../../media/notification.svg';
import order from '../../media/order.svg';
import publish from '../../media/publish.svg';
import setting from '../../media/setting.svg';
import help from '../../media/help.svg';
import home from '../../media/house.svg';
import vector11 from '../../media/vector-11.svg';
import SignupPopUp from '../loginAndSignupScreems/SignupScreen';
import { useSelector } from 'react-redux';
import CitySelectionPopup from '../LandingPage/CitySelectionPopup';
import axios from 'axios';


const Navbar = () => {
  const userAuthenticated = useSelector((state) => state.filters.userAuthenticated);

  const options = ['Gurgaon', 'Delhi', 'Mumbai', 'Kolkata'];

  const [isRightNavbarOpen, setIsRightNavbarOpen] = useState(false);


  const handleButtonClick = () => {
  };

  const handleSearch = (query) => {
    // Define the search action here, e.g., fetch search results from an API
  };

  const toggleRightNavbar = () => {
    setIsRightNavbarOpen(!isRightNavbarOpen);
  };


  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchItem = query.trim(); // Get the trimmed search query
    if (searchItem) {
      // Use window.location.href to navigate to the search results page
      window.location.href = `/search/${searchItem}`;
    }
  };

  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [signupMethod, setSignupMethod] = useState(null);
  const [isSignInHovered, setIsSignInHovered] = useState(false); // Add state for hover
  const [activePage, setActivePage] = useState('/');
  const [isCitySelectionPopupOpen, setIsCitySelectionPopupOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');


  const openCitySelectionPopup = () => {
    setIsCitySelectionPopupOpen(true);
  };

  const closeCitySelectionPopup = () => {
    setIsCitySelectionPopupOpen(false);
  };




  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;

    // Update the active page based on the current URL
    setActivePage(currentPath);

    const storedCity = localStorage.getItem('selectedCity');
    if (storedCity) {
      setSelectedCity(storedCity);
    } else {
      openCitySelectionPopup();
    }

  }, []);


  const handleNavigationClick = (category) => {

    setActivePage(category);
    const selectedCity = localStorage.getItem("selectedCity");
    if (selectedCity) {
      const url = `/${selectedCity}${category}`;
      window.location.href = url;
    }
  };


  // Determine the class name for the navigation items based on the activePage
  const getNavItemClassName = (page) => {
    return page === activePage ? 'active3' : '';
  };

  const handleSignInHover = () => {
    setIsSignInHovered(!isSignInHovered); // Toggle hover state
  };

  const openSignUp = () => {
    setIsSignUpOpen(true);
  };

  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  useEffect(() => {
    // Filter options based on input value
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [inputValue]);

  const handleSelect = (option) => {
    setInputValue(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    openCitySelectionPopup();
    setIsOpen(!isOpen);

  };
  const handleHoverDivClick = (link) => {
    // You can add logic here to determine the URL based on the link parameter
    window.location.href = link; // Navigate to the URL
  };


  const user = JSON.parse(localStorage.getItem("viewer"));

  const [imageSrc, setImageSrc] = useState(null);


  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        // Fetch the profile image for the user using the provided email
        const response = await axios.get(` https://api.youthevents.in/viewer/get-profile-image/`, {
          headers: {
            user: user.email
          },
        });

        // Create a base64 encoded data URL from the binary image data
        setImageSrc(response.data.profile_photo);

      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
    };


    fetchProfileImage();
  }, [])

  const handleHoverDiv = () => {
    const url = 'https://organizers.youthevents.in/';

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  const storedCity = localStorage.getItem('selectedCity');

  const [carouselData, setCarouseldata] = useState()
  const fetchBasicEventDetails = async (cityName) => {
    try {
      const response = await axios.get(` https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
      const basicDetails = response.data;
      setCarouseldata(basicDetails);
    } catch (error) {
      console.error('Error fetching basic event details:', error);
    }
  };

  useEffect(() => {
    fetchBasicEventDetails(storedCity);
  }, [storedCity]);

  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
      return str?.substring(0, maxLength - 3) + '...';
    } else {
      return str;
    }
  }

  const findDistinctCategories = (data) => {
    const categoriesSet = new Set(data?.map(event => event.category));
    return Array.from(categoriesSet);
  };

  // Get distinct categories and count them
  const distinctCategories = findDistinctCategories(carouselData);
  const categoriesCount = distinctCategories?.length;

  console.log("category count ", categoriesCount)

  return (
    <div className="Navbar-parent">
      <div className="top">
        <div className="logo-div" onClick={() => handleHoverDivClick('/')}>
          <img className="nav-logo" src={navlogo} alt="Logo" />
        </div>
        <div className="heading" onClick={() => handleHoverDivClick('/')}>
          <h2 className="brand-name">YOUTH EVENTS</h2>
        </div>
        <div className="searches">

          <div className='search-bar'>
            <form className="search" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Search events..."
                value={query}
                onChange={handleInputChange}
              />
            </form>
          </div>


          <div className="nav-button" style={{ marginTop: "3px" }}>
            <div >
              <button className="search search-button" onClick={handleSubmit}>Search</button>
            </div>
          </div>

        </div>
        <div className="cityname">
          <input
            className='Input'
            type="text"
            placeholder={selectedCity}
            value={selectedCity}
            // onChange={(e) => setInputValue(e.target.value)}
            onClick={toggleDropdown}
            readOnly
          />
          <img
            alt=""
            src={vector11}
            className={`city-child2 dropdown-icon ${isOpen ? 'open' : 'closed'}`}
            onClick={toggleDropdown}
          />
          {/* {isOpen && (
              <ul className='nav-ul'>
                {filteredOptions.map((option, index) => (
                  <li key={index} onClick={() => handleSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )} */}
        </div>
        <div className="menu">
          <button onClick={toggleRightNavbar}>
            <img src={lines3} alt="Menu" />
          </button>
        </div>
        {userAuthenticated ? (
          <div className='profile-img profile-img2' onClick={() => handleHoverDivClick('/profile')}>
            <div className="prifile-holder">

              <img className='profiles' src={imageSrc ? `data:image/jpeg;base64,${imageSrc}` : place} alt="" />
            </div>
            <h3>{truncateString(user.name.split(' ')[0], 9)}</h3>
          </div>
        ) : (
          <button
            className={`signin-btn signin-btn-remove ${isSignInHovered ? 'hovered' : ''}`}
            onClick={openSignUp}
            onMouseEnter={handleSignInHover}
            onMouseLeave={handleSignInHover}
          >
            Sign in
          </button>
        )}

        <SignupPopUp isOpen={isSignUpOpen} onClose={closeSignUp} />
        {isCitySelectionPopupOpen && (
          <CitySelectionPopup onSelectCity={setSelectedCity} onClose={closeCitySelectionPopup} />
        )}

      </div>

      {/* Right-side navbar */}
      <div className={`right-navbar ${isRightNavbarOpen ? 'open' : ''}`}>
        <div className='right'>
          <img src={lines9} alt="Menu" onClick={toggleRightNavbar} />
          <div className="signinbtn">
            {userAuthenticated ? (
              <div className='profile-img ' onClick={() => handleHoverDivClick('/profile')}>
                <div className="prifile-holder">

                  <img className='profiles' src={imageSrc ? `data:image/jpeg;base64,${imageSrc}` : place} alt="" />
                </div>
                <h3>{truncateString(user.name.split(' ')[0], 9)}</h3>
              </div>
            ) : (
              <button
                className={`signin-btn  ${isSignInHovered ? 'hovered' : ''}`}
                onClick={openSignUp}
                onMouseEnter={handleSignInHover}
                onMouseLeave={handleSignInHover}
              >
                Sign in

              </button>
            )}
          </div>
        </div>
        <div className="nav-item">
          {userAuthenticated ? (
            <></>
          ) : (
            <div className='sign-nav' onClick={() => handleHoverDivClick('/')}> <img className='profile' src={home} /> Home</div>
          )}


          {userAuthenticated ? (
            <div className='sign-nav' onClick={() => handleHoverDivClick('/profile')}> <img className='profile' src={Profile} /> Profile</div>

          ) : (
            <div> <div className="notsign-nav" onClick={openSignUp}></div> <img className='profile' src={Profile} /> Profile</div>
          )}

          {userAuthenticated ? (
            <div className='sign-nav' onClick={() => handleHoverDivClick('/notifications')}><img src={notification} />Notifications</div>

          ) : (
            <div  > <div className='notsign-nav' onClick={openSignUp}></div><img src={notification} />Notifications</div>
          )}

          {userAuthenticated ? (
            <div className='sign-nav' onClick={() => handleHoverDivClick('/your-orders')}><img src={order} />Your Orders</div>

          ) : (
            <div  > <div className='notsign-nav' onClick={openSignUp}></div>< img src={order} />Your Orders</div>
          )}

          <div className='sign-nav' onClick={() => handleHoverDivClick(`/${storedCity}/event-gallery`)}><img src={event} />Event Gallery</div>

          <div className='sign-nav' onClick={handleHoverDiv}><img src={publish} />Publish Your Event</div>


          {userAuthenticated ? (
            <div className='sign-nav' onClick={() => handleHoverDivClick('/account-settings')}><img src={setting} />Account Setting</div>

          ) : (
            <div  > <div className='notsign-nav' onClick={openSignUp}></div><img src={setting} />Account Setting</div>
          )}

          <div className='sign-nav' onClick={() => handleHoverDivClick('/help-and-support')}><img src={help} />Help and Support</div>
        </div>
      </div>

      <div className="mid">
        <div className="feilds">

          <div className='search-bar'>
            <form className="search" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Search events..."
                value={query}
                onChange={handleInputChange}
              />
            </form>
          </div>
          <div className="nav-button" >
            <button className="search search-button" onClick={handleSubmit}>Search</button>
          </div>

        </div>

        <div className="cityname1">
          <input
            className='Input'
            type="text"
            placeholder={selectedCity}
            value={inputValue}
            onClick={toggleDropdown}
            readOnly
          />
          <img
            alt=""
            src={vector11}
            className={`city-child2 dropdown-icon ${isOpen ? 'open' : 'closed'}`}
            onClick={toggleDropdown}
          />

        </div>
      </div>
      {categoriesCount > 5 && (
        <div className="bottom">
          <div className="items">
            <div className="items1">
              {carouselData && carouselData.some(event => event.category === 'Parties') && (
                <h3
                  onClick={() => handleNavigationClick('/clubs-and-party')}
                  className={getNavItemClassName('/clubs-and-party')}
                >
                  Parties
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Music') && (
                <h3
                  onClick={() => handleNavigationClick('/music')}
                  className={getNavItemClassName('/music')}
                >
                  Music
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Standup comedy') && (
                <h3
                  onClick={() => handleNavigationClick('/standup-comedy')}
                  className={activePage === '/standup-comedy' ? 'active3' : ''}
                >
                  Standup comedy
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Personal growth') && (
                <h3
                  onClick={() => handleNavigationClick('/personal-growth')}
                  className={activePage === '/personal-growth' ? 'active3' : ''}
                >
                  Personal growth
                </h3>
              )}
            </div>
            <div className="items2">
              {carouselData && carouselData.some(event => event.category === 'Showcraft') && (
                <h3
                  onClick={() => handleNavigationClick('/show-craft')}
                  className={activePage === '/show-craft' ? 'active3' : ''}
                >
                  Showcraft
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Festivals') && (
                <h3
                  onClick={() => handleNavigationClick('/festivals')}
                  className={activePage === '/festivals' ? 'active3' : ''}
                >
                  Festivals
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Community') && (
                <h3
                  onClick={() => handleNavigationClick('/community')}
                  className={activePage === '/community' ? 'active3' : ''}
                >
                  Community
                </h3>
              )}
              {carouselData && carouselData.some(event => event.category === 'Adventure') && (
                <h3
                  onClick={() => handleNavigationClick('/adventure')}
                  className={activePage === '/adventure' ? 'active3' : ''}
                >
                  Adventure
                </h3>
              )}
            </div>

          </div>
        </div>
      )}
      <div className="bottom bottom2">
        <div className="items-line">
          {carouselData && carouselData.some(event => event.category === 'Parties') && (
            <h3
              onClick={() => handleNavigationClick('/clubs-and-party')}
              className={getNavItemClassName('/clubs-and-party')}
            >
              Parties
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Music') && (
            <h3
              onClick={() => handleNavigationClick('/music')}
              className={getNavItemClassName('/music')}
            >
              Music
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Standup comedy') && (
            <h3
              onClick={() => handleNavigationClick('/standup-comedy')}
              className={activePage === '/standup-comedy' ? 'active3' : ''}
            >
              Standup comedy
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Personal growth') && (
            <h3
              onClick={() => handleNavigationClick('/personal-growth')}
              className={activePage === '/personal-growth' ? 'active3' : ''}
            >
              Personal growth
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Showcraft') && (
            <h3
              onClick={() => handleNavigationClick('/show-craft')}
              className={activePage === '/show-craft' ? 'active3' : ''}
            >
              Showcraft
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Festivals') && (
            <h3
              onClick={() => handleNavigationClick('/festivals')}
              className={activePage === '/festivals' ? 'active3' : ''}
            >
              Festivals
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Community') && (
            <h3
              onClick={() => handleNavigationClick('/community')}
              className={activePage === '/community' ? 'active3' : ''}
            >
              Community
            </h3>
          )}
          {carouselData && carouselData.some(event => event.category === 'Adventure') && (
            <h3
              onClick={() => handleNavigationClick('/adventure')}
              className={activePage === '/adventure' ? 'active3' : ''}
            >
              Adventure
            </h3>
          )}
        </div>

      </div>

    </div >
  );
};

export default Navbar;
