import React from 'react';
import "./newsletter.css"
import x from "../../media/x.svg"
import { Link } from 'react-router-dom';


const WelcomePopup = ({ onClose }) => {
  return (
    <div className="popup1">
    <button className="close-button" onClick={onClose}><img src={x} alt="" /></button> {/* Close button in the top-right corner */}
    <div className="content1">
        <h3 className="great" >Welcome Aboard! 🎉  </h3>
        <p className="greatp" style={{marginTop:"0"}}>Thank you for subscribing to our newsletter. You're now part of our exclusive community, and we can't wait to share exciting updates, news, and special offers with you. Stay tuned for the latest from YouthEvents.</p>

    </div>
    <Link to={'/'} className="check">
        Explore
    </Link>

</div>

);
};

export default WelcomePopup;
