import React, { useState, useEffect } from 'react';
import './SearchableDropdown.css';
import vector11 from '../../media/vector-11.svg';

const SearchableDropdown = ({ options, onSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Filter options based on input value
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [inputValue, options]);

  const handleSelect = (option) => {
    onSelect(option);
    setInputValue(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="searchable-dropdown">
      <input
      className='Input'
        type="text"
        placeholder="City"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onClick={toggleDropdown}
      />
      <img
        alt=""
        src={vector11}
        className={`city-child1 dropdown-icon ${isOpen ? 'open' : 'closed'}`}
        onClick={toggleDropdown}
      />
      {isOpen && (
        <div className="drop">

        <ul className='dropdown-ul'>
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {option}
            </li>
          ))}
        </ul>
          </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
