import { React, useState } from 'react'
import OtpInput from "react-otp-input";
import "../Sign.css"

const Resetemailotpscreen = () => {
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);

  return (
    <div className="reset-email-main">
      <div className='reset-email'>

        <span className='reset-paragraph'>Reset Password OTP Sent to Your Email Please check your inbox for the one-time password to reset your password.</span>
      </div>
      <div className="outer">

        <OtpInput
          value={code}
          onChange={handleChange}
          numInputs={4}
          renderSeparator={<span style={{ width: "12px" }}> </span>}
          isInputNum={true}
          shouldAutoFocus={true}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            border: "1px solid transparent",
            borderRadius: "8px",
            width: "50px",
            height: "50px",
            fontSize: "18px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none"
          }}
        />
      </div>
      <button className='proceed'>Proceed</button>
      <div className="privacy">
        privacy policy
      </div>

    </div>
  )
}

export default Resetemailotpscreen
