import React from 'react'
import "./termofuse.css"
import line from "../../media/line.svg";
import Accordian from '../Accordian/Accordian';


const Termofuse = () => {

    const accordionItems = [
        {
            question: 'Acceptance of Terms',
            answer: `By accessing or using YouthEvents, you acknowledge that you have read, understood, 
            and agree to be bound by these terms of use. If you do not agree with any part of 
            these terms, please refrain from using our platform.`,
        },
        {
            question: 'User Eligibility            ',
            answer: `You must be at least 13 years old to use YouthEvents. If you are under the age 
            of 18, you must use YouthEvents under the supervision of a parent or legal guardian.`,
        },
        {
            question: ` Use of Services`,
            answer: `
            YouthEvents provides a platform for users to discover, book, and attend events. 
            Users are solely responsible for their interactions and transactions on the platform.`,
        },
        {
            question: 'Account Creation',
            answer: `
            When creating an account on YouthEvents, you agree to provide accurate and 
            complete information. You are responsible for maintaining the security of 
            your account and password.`,
        },
        {
            question: 'Content Guidelines',
            answer: `
            YouthEvents may include user-generated content such as event reviews, comments, 
            and images. By submitting content, you grant YouthEvents the right to use, modify, 
            and display the content on the platform.`,
        },
        {
            question: ' Intellectual Property',
            answer: `
All content and materials on YouthEvents, including logos, text, images, and 
software, are protected by intellectual property laws. You may not use, reproduce, 
or distribute any content without permission.`,
        },
    ];

    return (
        <section className="general-section">
            <h2 className="general-heading">Term Of use</h2>
            <img src={line} alt="Line" className="general-line" />

            <h1 className='term-heading'>Guidelines for YouthEvents</h1>
            <p className="terms">Welcome to YouthEvents! These terms of use outline the rules and regulations
                for your use of the YouthEvents website and services. By accessing and using
                our platform, you agree to comply with these terms. Please read them carefully.</p>
            <Accordian data={accordionItems} />



        </section>
    )
}

export default Termofuse
