import { React, useState } from 'react'
import "./advertise.css"
import line from "../../media/line.svg";
import x from "../../media/x.svg"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';



const AdvertiseWithus = () => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        phone: Yup.string()
            .required('Phone number is required')
            .matches(/^\d+$/, 'Phone number must contain only digits')
            .length(10, 'Phone number must be exactly 10 digits'),
    });


    const [isPopupOpen, setPopupOpen] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [submitting, setSubmitting] = useState("Request a Callback");

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting("Submitting");
        try {
            const response = await fetch('https://api.youthevents.in/viewer/get-in-touch/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            if (response.ok) {
                console.log('Form submitted successfully');
                setSubmitting("Submitted");
                resetForm();
            } else {
                console.error('Failed to submit form');
                setSubmitting("Failed to submit form. Try again later.");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitting("Error submitting form. Try again later.");
        } finally {
            setTimeout(() => {
                setPopupOpen(false);
            }, 1000);
        }
    };

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };
    console.log("isPopupOpen", isPopupOpen)

    return (
        <section className="general-section">
            <h2 className="general-heading">Advertise with Us</h2>
            <img src={line} alt="Line" className="general-line" />

            <p className="advertise">Are you looking to reach a dynamic and engaged audience of event enthusiasts and
                young individuals? YouthEvents offers advertising opportunities that can help you
                connect with your target demographic in a meaningful way.
            </p>

            <div className="whyadvertise">
                <h2 className="why-heading">
                    Why Advertise with YouthEvents
                </h2>
                <p > <b>Youth-Centric:</b> Our platform focuses on catering to the interests and passions of the
                    youth, providing a prime avenue for brands targeting this demographic.
                </p>
                <p> <b>Diverse Events:</b> Reach a community of active users who are enthusiastic about
                    exploring new experiences and events.
                </p>
                <p> <b>Customized Campaigns:</b> We work with you to create tailor-made advertising campaigns
                    that align with your brand message and objectives.
                </p>

            </div>
            <div className="whyadvertise2">
                <h2 className="why-heading2">
                    Advertising Opportunities
                </h2>
                <p > <b>Banner Ads:</b>  Place eye-catching banner ads on our platform's pages to
                    maximize your visibility.

                </p>
                <p> <b>Sponsored Content:</b>
                    Promote your brand through sponsored event listings, articles,
                    or featured content.

                </p>
                <p> <b>Email Campaigns:</b>  Reach our subscribers directly with engaging email campaigns that
                    showcase your offerings.


                </p>
                <p> <b>Event Collaborations:</b>  Partner with us to co-create events that align with
                    your brand identity.


                </p>

            </div>
            <div className="getintouch">
                <button className="touch" onClick={openPopup}>
                    Get in Touch
                </button>
            </div>
            <div className={`popup-overlay1 ${isPopupOpen ? 'open' : ''}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="popup1" style={{ backgroundColor: 'white' }}>
                    <button className="close-button" onClick={closePopup}><img src={x} alt="" /></button> {/* Close button in the top-right corner */}

                    {/* Popup content goes here */}
                    <p>GET IN TOUCH</p>
                    <h2>We will get back to you within 24 hours</h2>
                    <Formik
                            initialValues={{ name: '', phone: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                        {({ isSubmitting }) => (
                            <Form className="general" style={{ marginLeft: '65px', marginRight: '65px', display:"flex",flexDirection:"column",alignItems:"center" }}>
                                <div className="input-group">
                                    <Field
                                        name="name"
                                        type="text"
                                        placeholder="Enter Your Name"
                                    />
                                    <ErrorMessage name="name" component="div" className="errorMessage2" />
                                </div>
                                <div className="input-group">
                                    <Field
                                        name="phone"
                                        type="text"
                                        placeholder="Enter your Phone Number"
                                    />
                                    <ErrorMessage name="phone" component="div" className="errorMessage2" />
                                </div>

                                <button className="req-a-call" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Request a Callback'}
                                    </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <p className="bottompara">

                For inquiries about advertising opportunities, partnerships, and customized campaigns,
                please contact our business development team at info@youthevents.in. We're excited
                to collaborate with you and help your brand shine on our platform.
            </p>




        </section>
    )
}

export default AdvertiseWithus
