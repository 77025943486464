import React, { useState, useRef } from 'react';
import axios from 'axios';
import Getbackpopup from './Getbackpopup';
import '../helpandsupport.css';

const RaiseTicket = ({handleTabClick}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [relatedTicket, setRelatedTicket] = useState('');
  const [priority, setPriority] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [submit, setSubmit] = useState("Submit");
  const [error, setError] = useState();

  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("viewer"));
    setSubmit("Submitting")

    if (!name || !email || !department || !relatedTicket || !priority || !subject || !message) {
      setSubmit("Failed");
      console.error('Please fill in all required fields');
      return;
  }


    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('department', department);
      formData.append('relatedTicket', relatedTicket);
      formData.append('priority', priority);
      formData.append('subject', subject);
      formData.append('message', message);
      formData.append('user_email', user.email);
      formData.append('attachment', attachment);

      console.log("form data",formData)

      const response = await axios.post('https://api.youthevents.in/viewer/create-ticket/', formData);
      console.log('Ticket creation response:', response.data);
      setSubmit("Submited")


      setName('');
      setEmail('');
      setDepartment('');
      setRelatedTicket('');
      setPriority('');
      setSubject('');
      setMessage('');
      setAttachment(null);
      fileInputRef.current.value = '';
      setShowPopup(true);

    } catch (error) {
      console.error('Error creating ticket', error);
      setError(error.response.data.error)
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleCheckClick = () => {
    setShowPopup(false); // Close the popup
    // Trigger click on the "Previous Ticket" tab
    handleTabClick('previous');
};

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h2 className='Ticket-heading'>Ticket Information</h2>
      <div className='raise-div'>
        <div className="for-inner-item">
          <div className="name-email">
            <input
              type="text"
              className='for-name'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              className='for-email'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="dropdowns">
            <select
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            >
              <option value="">Select Department</option>
              <option value="Fruit">Payment</option>
              <option value="Vegetable">Feedback </option>
              <option value="Meat">Option 3</option>
            </select>
            <select
              value={relatedTicket}
              onChange={(e) => setRelatedTicket(e.target.value)}
            >
              <option value="">Select Related Ticket</option>
              <option value="Fruit">Option 1</option>
              <option value="Vegetable">Option 2</option>
              <option value="Meat">Option 3</option>
            </select>
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option value="">Select Priority</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
          <div className="subject">
            <input
              type="text"
              placeholder='Subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="issue-here">
            <textarea
              placeholder='Message-here'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="select-file">
            <div className="select-file-to" >
              {attachment === null ? "Select a File to Attach (Optional)" : attachment.name}
            </div>
            <div className="upload" onClick={() => fileInputRef.current.click()}>Upload</div>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
         {error && <div className="error-message">{error}</div>} 
        </div>
        <div className="submit-btn">
          <button onClick={handleSubmit}>{submit}</button>
        </div>
        {showPopup && <Getbackpopup onClose={handleClosePopup} onCheckClick={handleCheckClick} />}
      </div>
    </div>
  );
};

export default RaiseTicket;
