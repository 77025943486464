import React, { useState } from 'react';
import "./Sign.css"
import sendicon from "../../media/sendicon.svg"
import google from "../../media/google.svg"
import email1 from "../../media/email1.svg"
import x from "../../media/x.svg"
import GoogleSignupScreen from './signScreenComponents/GoogleSignupScreen';
import EmailSignupScreen from './signScreenComponents/EmailSignupScreen';
import LoginOTPScreen from './signScreenComponents/LoginOTPScreen';
import Resetemailotpscreen from './signScreenComponents/Resetemailotpscreen';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../redux/filtersSlice';
import axios from "axios"
import { GoogleLogin } from 'react-google-login';


const SignupPopUp = ({ isOpen, onClose }) => {

    const dispatch = useDispatch();

    const [signupMethod, setSignupMethod] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [googleResponse, setGoogleResponse] = useState('');
    const [userNotFound, setUserNotFound] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [Signin, setSignin] = useState("Signin");


    const handleSignupMobile = (method) => {

        const mobile = inputValue

        axios.post(' https://api.youthevents.in/viewer/register-mobile/', mobile, {
            headers: {
                'Content-Type': 'application/json',
                // Include any necessary headers such as authorization if required
            }
        })
            .then(response => {
                // Handle the successful login response here
                alert(`Signing up with ${method}`);
                setSignupMethod(method);
            })
            .catch(error => {
                // Handle errors during the login process
                console.error('There was an error logging in:', error);
                // You can display an error message or perform other error handling actions
            });

    };

    const handleSignupMethod = (method) => {
                alert(`Signing up with ${method}`);
                setSignupMethod(method);

    };

    const closePopup = () => {
        setSignupMethod(null);
        setError(false)
        setErrorMessage('');

        onClose();
    };

    const setAuthToken = (token) => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    };


    const handlesignin = () => {
        // Prepare the login data
        const loginData = {
            email: email,
            password: password
        };
        setError(false)
      setErrorMessage('');
      setSignin("Signin in...")

        // Make a POST request to your backend API for user login
        axios.post(' https://api.youthevents.in/viewer/sign-in/', loginData, {
            headers: {
                'Content-Type': 'application/json',
                // Include any necessary headers such as authorization if required
            }
        })
            .then(response => {
                // Handle the successful login response here
                console.log('Login successful:', response.data);
                const { token, user } = response.data;

                // Save the token in local storage or cookies
                localStorage.setItem('viewerToken', token);
                localStorage.setItem('viewer', JSON.stringify(user));
                setAuthToken(token);

                dispatch(setUserAuthenticated(user));

                // Close the popup
                closePopup();
            })
            .catch(error => {
                // Handle errors during the login process
                console.error('There was an error logging in:', error);
                setError(true)
      setErrorMessage('Password or Email does not match');
      setSignin("Signin")

            });
    };

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const csrfToken = getCookie('csrftoken');

axios.defaults.headers.common['X-CSRFToken'] = csrfToken;



const responseGoogle = (response) => {
    console.log(response);
    setGoogleResponse(response); 
    // Extract necessary user details from the response
    const { tokenId, profileObj } = response;

    // Make a POST request to your backend API with the user details
    axios.post(' https://api.youthevents.in/viewer/auth/google/signup/', JSON.stringify({
        token_id: tokenId,
        email: profileObj?.email,
        name: profileObj?.name,
        image: profileObj?.imageUrl
        // Add any other required user details here
    }), {
        headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        console.log('Google signup successful:', response.data);
        // Handle the successful signup response here
        const { token, user } = response.data;
        localStorage.setItem('viewerToken', token);
        localStorage.setItem('viewer', JSON.stringify(user));
        setAuthToken(token);
        dispatch(setUserAuthenticated(user));
        // handleSignupMethod('google');
        closePopup();
    })
    .catch(error => {
        if (error.response && error.response.data.message === 'User Not Found') {
            setUserNotFound(true); // Set user not found state
            handleSignupMethod('google');

        } else {
            console.error('Error signing up with Google:', error);
            // Handle errors during the Google signup process
        }
    });
};



    return (

        <div className='signup-popup' style={{ display: isOpen ? 'flex' : 'none', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <button className="close-button" onClick={closePopup}><img src={x} alt="" /></button> {/* Close button in the top-right corner */}

            {signupMethod ? (
                <>
                    {/* Display signup form based on selected method */}
                    {signupMethod === 'google' && (
                        <>
                            {/* Render Google signup form */}
                            <GoogleSignupScreen googleResponse={googleResponse}  onRegisterSuccess={closePopup} />
                            {/* Include your Google signup form components here */}
                        </>
                    )}

                    {signupMethod === 'email' && (
                        <>
                            {/* Render Email signup form */}
                            <h2 className='started'>Get started Login</h2>
                            <div className={`general ${error ? 'errored' : ''}`}>

                                <input
                                    className=''
                                    type="Email"
                                    placeholder="Enter Your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className={`general ${error ? 'errored' : ''}`}>

                                <input
                                    className=''
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="resetpass" onClick={() => handleSignupMethod('resetPasswordEmail')}>
                                {/* todo forget pass screen */}
                                forgot password ?
                            </div>
                            <div className="errorMessage">{errorMessage}</div>
                            <div className="signup-btn-div">
                                <button className='signup-btn' onClick={handlesignin}>{Signin}</button>
                            </div>

                            <div className="new">
                                <span className='newto' onClick={() => handleSignupMethod('newuser')}>New To youth Event Signup</span>
                            </div>
                            <div className="emailprivacy">
                                privacy policy
                            </div>

                            {/* Include your Email signup form components here */}
                        </>
                    )}

                    {signupMethod === 'mobile' && (
                        <>
                            {/* Render Mobile signup form */}
                            {/* <h2>Mobile Signup</h2> */}
                            <LoginOTPScreen Mobile={inputValue} onRegisterSuccess={closePopup}/>
                            {/* Include your Mobile signup form components here */}
                        </>
                    )}
                    {signupMethod === 'newuser' && (
                        <>
                            {/* new user form */}
                            <EmailSignupScreen onRegisterSuccess={closePopup}/>
                            {/* Include your Mobile signup form components here */}
                        </>
                    )}
                    {signupMethod === 'resetPasswordEmail' && (
                        <>
                            {/* new user form */}
                            <Resetemailotpscreen />
                            {/* Include your Mobile signup form components here */}
                        </>
                    )}

                </>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" ,width:"100%"}}>
                    <h2 className='started'>Get Started</h2>
                    <div className="signin-option">
                        {/* <button className='google1' onClick={() => handleSignupMethod('google')}><img className='signimage' src={google}/> <span>Continue with Google</span></button> */}

                        {/* <div className='google1' onClick={() => handleSignupMethod('google')}><img className='signimage' src={google} /> Continue with Google</div> */}
                        <GoogleLogin
                            clientId="218720132551-kmgbgee2drhg58apmvgifealr1luvnlc.apps.googleusercontent.com"
                            buttonText="Continue with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            className='google1'
                            // isSignedIn={true}
                        />
                        <div className='email1' onClick={() => handleSignupMethod('email')}> <img className='signimage' src={email1} /> Continue with Email</div>
                    </div>
                    <div className="or">
                        <span className='para'>or</span>

                    </div>
                    <div className="mobile">
                        <div className="inner">

                            <span className='ind'>+91 |</span>
                            <input
                                className='Input'
                                type="tel"
                                placeholder="Continue with Mobile Number"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </div>

                        <button className='send' disabled={inputValue.length!==10}  onClick={() => handleSignupMobile('mobile')}>
                            <img src={sendicon} alt="" />
                        </button>
                    </div>
                    <div className="privacy">
                        privacy policy
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignupPopUp;
