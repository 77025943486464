import React, { useState } from 'react';
import '../accountsetting.css';
import axios from 'axios'; // Import Axios
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import eyeopen from "../../../media/eyeopen.png"
import eyeclose from "../../../media/eyeclose.png"

const UpdatePassword = () => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};

// Create a validation schema using Yup
const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
        .required('New password is required')
        .min(8, 'Password must be at least 8 characters long'),
    confirmNewPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updateStatus, setUpdateStatus] = useState('Update');
  const [iseyeopen, setIseyeOpen] = useState(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] = useState(false);

  const handleToggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible((prevState) => !prevState);
  };

  const handleToggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible((prevState) => !prevState);
  };

  const handleToggleConfirmNewPasswordVisibility = () => {
    setIsConfirmNewPasswordVisible((prevState) => !prevState);
  };

  const user = JSON.parse(localStorage.getItem("viewer"));
  const userEmail =user.email

  const handleUpdatePassword = async (values, { setSubmitting, setFieldError, resetForm }) => {
    const { currentPassword, newPassword, confirmNewPassword } = values;
    const user = JSON.parse(localStorage.getItem('viewer'));
    const userEmail = user.email;

    try {
        // Send request to update password using Axios
        const response = await axios.post('https://api.youthevents.in/viewer/api/update_password/', {
            email: userEmail,
            currentPassword,
            newPassword,
            confirmNewPassword
        });

        // Handle success
        setUpdateStatus('Updated');
        resetForm(); // Reset the form after successful update
        console.log(response.data);
    } catch (error) {
        // Handle error
        console.error('Failed to update password:', error);
        setFieldError('updateError', 'Failed to update password Check Your old Password');
    } finally {
        setSubmitting(false);
    }
};

  return (
    <div className='update-password-div'>
      <h1 className='update-password-comp'>Update Password</h1>
      <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleUpdatePassword}
            >
                {({ isSubmitting, errors }) => (
                    <Form style={{display:"flex" ,flexDirection:"column",gap:"12px" , width:"100%", alignItems:"center"}}>
                         <div className='password-input'>
                            <Field
                                type={isCurrentPasswordVisible ? 'text' : 'password'}
                                id='currentPassword'
                                name='currentPassword'
                                placeholder='Enter Current Password'
                            />
                            <img src={isCurrentPasswordVisible ? eyeopen : eyeclose} alt="Toggle visibility" className='pass-eye' onClick={handleToggleCurrentPasswordVisibility} />
                            <ErrorMessage name='currentPassword' component='div' className='errorMessage2' />
                        </div>
                        <div className='password-input'>
                            <Field
                                type={isNewPasswordVisible ? 'text' : 'password'}
                                id='newPassword'
                                name='newPassword'
                                placeholder='Enter New Password'
                            />
                            <img src={isNewPasswordVisible ? eyeopen : eyeclose} alt="Toggle visibility" className='pass-eye' onClick={handleToggleNewPasswordVisibility} />
                            <ErrorMessage name='newPassword' component='div' className='errorMessage2' />
                        </div>
                        <div className='password-input'>
                            <Field
                                type={isConfirmNewPasswordVisible ? 'text' : 'password'}
                                id='confirmNewPassword'
                                name='confirmNewPassword'
                                placeholder='Confirm New Password'
                            />
                            <img src={isConfirmNewPasswordVisible ? eyeopen : eyeclose} alt="Toggle visibility" className='pass-eye' onClick={handleToggleConfirmNewPasswordVisibility} />
                            <ErrorMessage name='confirmNewPassword' component='div' className='errorMessage2' />
                        </div>

                        {/* Display error message for form submission failure */}
                        {errors.updateError && (
                            <div className='errorMessage2'>
                                {errors.updateError}
                            </div>
                        )}

                        {/* Submit button */}
                        <button type='submit' className="update">
                            {isSubmitting ? 'Updating...' : 'Update'}
                        </button>
                    </Form>
                )}
            </Formik>

    
    </div>
  );
};

export default UpdatePassword;