import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./searchresult.css"
import line from "../../media/line.svg";
import date from "../../media/date.svg";
import filter from "../../media/filter.svg";
import location from "../../media/location.svg";
import ImageSlider from '../MainImageSider/ImageSlider';
import TimePicker from '../Event calender/TimePicker';
import { Slider } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEventData } from '../../redux/filtersSlice';
import MobileFilter from '../EventType/MobileFilter';
import Tabfilter from '../EventType/Tabfilter';
import axios from 'axios';




const findMaxPrice = (events) => {
  if (!events || events?.length === 0) {
    return 0;
  }

  let maxPrice = -Infinity;

  events.forEach((event) => {
    event.ticket_types.forEach((ticketType) => {
      if (ticketType.ticketPrice > maxPrice) {
        maxPrice = ticketType.ticketPrice;
      }
    });
  });

  return maxPrice;
};


const SearchResults = () => {
  const { searchItem } = useParams();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(` https://api.youthevents.in/publisher/api/search/?name=${searchItem}`);
        setSearchResults(response.data);
        // setLoading(false);
      } catch (error) {
        console.log(error.message);
        // setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchItem]);

  console.log("serach es", searchResults)

  console.log('Search Item:', searchItem);

  const getImages = (num) => {
    const images = [];
    for (let i = 7; i <= num; i++) {
      images.push({
        img: `https://picsum.photos/seed/${i}/1280/500`
      });
    }
    return images;
  };
  const images = getImages(8);

  const [maxPrice, setMaxPrice] = useState(10000);
  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [discountRange, setDiscountRange] = useState([10, 50]);
  const [selectedCategory, setSelectedCategory] = useState('latest');
  const cityName = localStorage.getItem('selectedCity');

  const [topBanners, setTopBanners] = useState([]);

  const fetchData = async (cityName) => {
    try {
      const topBannersResponse = await axios.get(` https://api.youthevents.in/publisher/api/top-banners/?city=${cityName}`);
      setTopBanners(topBannersResponse.data);


    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
  useEffect(() => {
    fetchData(cityName);

  }, [cityName]);




  const renderAvailabilityStatus = (item) => {

    let totalTicketsLeft = 0;
    item.ticket_types.forEach((ticketType) => {
      totalTicketsLeft += ticketType.availableTickets;
    });


    if (totalTicketsLeft <= 30) {
      return (
        <div className="availability-status-red">
          <div className="status-tag">{totalTicketsLeft} Ticket Left</div>
        </div>
      );
    } else if (totalTicketsLeft < 100) {
      return (
        <div className="availability-status-yellow">
          <div className="status-tag">Filling Fast</div>
        </div>
      );
    }
    return (
      <div className="available">
        available
      </div>

    )
  }


  const getMinTicketPrice = (data) => {
    let minPrice = Infinity;
    data?.ticket_types?.forEach((ticketType) => {
      if (ticketType.ticketPrice < minPrice) {
        minPrice = ticketType.ticketPrice;
      }
    });
    return minPrice;
  };
  const [languagesOfIndia, setLanguagesOfIndia] = useState([
    'English',
    'Hindi',
    'Bengali',
    'Telugu',
    'Marathi',
    'Tamil',
    'Urdu',
    'Gujarati',
    'Kannada',
    'Odia',
    'Punjabi',
    'Malayalam',
    'Assamese',
    'Sanskrit',
  ]);

  const extractUniqueLanguages = (events) => {
    const languageSet = new Set();
    events?.forEach((event) => {
      const languages = event.language.split(',')?.map((lang) => lang.trim());
      languages.forEach((lang) => languageSet.add(lang));
    });
    return Array.from(languageSet);
  };


  useEffect(() => {
    if (searchResults?.length > 0) {
      const uniqueLanguages = extractUniqueLanguages(searchResults);
      setLanguagesOfIndia(uniqueLanguages)
      // Filter the `languagesOfIndia` list to only include languages present in events

    }
  }, []);

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleLanguageChange = (language) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleDiscountChange = (value) => {
    setDiscountRange(value);
  };

  const selectedDate = useSelector((state) => state.filters.date);

  // Convert the date to YYYY-MM-DD format
  const year = selectedDate?.getFullYear();
  const month = String(selectedDate?.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(selectedDate?.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;



  const filteredEvents = searchResults?.filter((event) => {
    const eventDate = event.date;
    const eventPrice = getMinTicketPrice(event)
    // const eventDiscount = event.discount;

    const dateFilterPassed = selectedDate === null || eventDate.toString() == formattedDate.toString();
    const languageFilterPassed = selectedLanguages?.length === 0 || (selectedLanguages).includes(event.language);
    const priceFilterPassed = eventPrice >= priceRange[0] && eventPrice <= priceRange[1];
    // const discountFilterPassed = eventDiscount >= discountRange[0] && eventDiscount <= discountRange[1];
    const categoryFilterPassed = selectedCategory === 'latest' || event.category === selectedCategory;

    console.log("data filter pass", dateFilterPassed)

    // return true
    return dateFilterPassed &&
      languageFilterPassed && priceFilterPassed
      &&
      // discountFilterPassed &&
      categoryFilterPassed;
  });

  const sortEvents = (events, category) => {
    if (!events || events.length === 0) {
        return [];
    }

    // Sort based on selected category
    switch (category) {
        case 'most-viewed':
            // Sort by views in descending order
            return [...searchResults].sort((a, b) => b.views - a.views);
        case 'most-sold':
            // Sort by Ticket_Sold in descending order
            return [...searchResults].sort((a, b) => b.Ticket_Sold - a.Ticket_Sold);
        case 'latest':
        default:
            // Sort by date in ascending order (latest events come first)
            return [...searchResults].sort((a, b) => new Date(a.date) - new Date(b.date));
    }
};

// Inside the render function
const sortedEvents = sortEvents(filteredEvents, selectedCategory);

  console.log("filter data", filteredEvents, formattedDate)

  const categories = [
    { key: 'latest', label: 'Latest' },
    { key: 'most-viewed', label: 'Most-Viewed' },
    { key: 'most-sold', label: 'Most-Sold' },
  ];

  useEffect(() => {
    const calculatedMaxPrice = findMaxPrice(searchResults);
    setMaxPrice(calculatedMaxPrice);
  }, [searchResults]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  };


  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + '...';
    } else {
      return str;
    }
  }
  return (
    <div className='search-result' style={{ paddingBottom: "80px" }}>

      <h2 className="results">Search Results For "{searchItem}" ({searchResults?.length}) Results</h2>
      <img src={line} alt="Line" className="general-line" />

      <Tabfilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        discountRange={discountRange}
        setDiscountRange={setDiscountRange}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        futureEvents={searchResults}
        style={{ display: "flex" }}
      />

      {/* mobile filter */}
      <MobileFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        discountRange={discountRange}
        setDiscountRange={setDiscountRange}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
      />


      <div className="filter-cards filter-cards2" >
        <div className="cards cards2 row">
          {sortedEvents.map((item, index) => (
            <Link
              key={item.id}
              to={{
                pathname: `/event-detail/${item.id}`
              }}
              className={`carousel-card2  `}
            >
              {renderAvailabilityStatus(item)}
              <div className="image1">
                <img src={item.thumbnail_image} alt={item.eventTitle} />
              </div>
              <div className="details1">
                <div className='tittle1'>{truncateString(item.eventTitle, 24)}</div>
                <div className="icon-text1">
                  <img src={date} alt="Date" className="icon1" />
                  <span className="date1">{formatDate(item.date)}</span>
                </div>
                <div className="icon-text1">
                  <img src={location} alt="Location" className="icon1" />
                  <span className="location1">{truncateString(item.location, 35)}</span>
                </div>
                <div className="price1">₹ {getMinTicketPrice(item)} Onwards</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <ImageSlider images={topBanners ? topBanners : images} style={{ margin: "10px" }} />
    </div>
  );
};

export default SearchResults;
