import React from 'react';
import QRCode from 'qrcode.react';
import "../yourorder.css"


const QRCodePopup = ({ ticket, onClose }) => {

  function convertTo12Hour(time) {
    const [hour, minute, second] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    return formattedTime;
}

  return (
    <div className="qr-code-popup">
      <div className="overlay" />

      <div className="popup-content3">
        <button className="close-button2" onClick={onClose}>
          Close
        </button>
        <div className="head-close">{ticket.event.eventTitle}</div>

        {ticket.booking_details.map((tic) => (
          <div key={tic.id} className="tickets ">
            <QRCode value={tic.id}  className='order-qr' />
            <div className="ticket-details">
              <div className="tictype">{tic.ticket_type.name}</div>
              <div className="ticno">{tic.quantity} Ticket</div>
              <div className="ticid">{tic.id}</div>

            </div>
            <div className="ticket-line"></div>
            <div className="event-detail">
              <div className="tic-event">{ticket.event.eventTitle}</div>
              <div className="tic-lang">{ticket.event.eventLanguage}</div>
              <div className="tic-time">{convertTo12Hour(ticket.event.eventTime)}</div>

            </div>
          </div>
        ))}

        {/* Display other ticket details if needed */}
      </div>
    </div>
  );
};

export default QRCodePopup;
