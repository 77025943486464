import React, { useState } from 'react';
import "./helpandsupport.css";
import line from "../../media/line.svg";
import raisetic from "../../media/raisetic.svg";
import prevticket from "../../media/prevticket.svg";
import raiseticblack from "../../media/raiseticblack.svg";
import prevticketblack from "../../media/prevticketblack.svg";
import PreviousTicket from './support components/PreviousTicket';
import RaiseTicket from './support components/RaiseTicket';
import ProfileBar from '../ProfileNavbar/ProfileNavbar';

const HelpAndSupport = () => {
    const [selectedTab, setSelectedTab] = useState(null);

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    return (
        <section className="general-section">
            <ProfileBar/>
            <h2 className="general-heading">Help And Support</h2>
            <img src={line} alt="Line" className="general-line" />

            <p className="advertise">Raising a ticket can be a valuable way to seek assistance or resolution in various situations, such as customer support, technical issues, or inquiries.</p>
            
            <div className="support-btn">
                <div 
                    className={`raise-query ${selectedTab === 'raise' ? 'active1' : ''}`}
                    onClick={() => handleTabClick('raise')}
                >
                    <img src={selectedTab === 'raise' ? raiseticblack : raisetic} alt="" />
                    Raise a Ticket
                </div>
                
                <div 
                    className={`previous-ticket ${selectedTab === 'previous' ? 'active1' : ''}`}
                    onClick={() => handleTabClick('previous')}
                >
                    <img src={selectedTab === 'previous' ? prevticketblack : prevticket} alt="" />
                    Previous Tickets
                </div>
            </div>

            <div className="component-render">
                {selectedTab === 'raise' && <RaiseTicket handleTabClick={handleTabClick}/>}
                {selectedTab === 'previous' && <PreviousTicket />}
            </div>
        </section>
    )
}

export default HelpAndSupport;
