import React, { useState, useEffect } from 'react';
import "./eventtype.css";
import TimePicker from '../Event calender/TimePicker';
import { Slider } from 'antd';


const Tabfilter = ({
  selectedCategory,
  setSelectedCategory,
  maxPrice,
  setMaxPrice,
  priceRange,
  setPriceRange,
  discountRange,
  setDiscountRange,
  selectedLanguages,
  setSelectedLanguages,
  futureEvents,
  style 
}) => {
    // const [discountRange, setDiscountRange] = useState([10, 50]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterPosition, setFilterPosition] = useState({ top: 0, left: 0 });

  const categories = [
    { key: 'latest', label: 'Latest' },
    { key: 'most-viewed', label: 'Most-Viewed' },
    { key: 'most-sold', label: 'Most-Sold' },
  ];


  //tab filter 
  const toggleFilter = (filter, position) => {
    if (activeFilter === filter) {
      setActiveFilter(null);
      setIsFilterOpen(false);
    } else {
      setActiveFilter(filter);
      setIsFilterOpen(true);
      setFilterPosition(position);
    }
  };
    const renderFilterDropdown = () => {
        switch (activeFilter) {
          case 'date':
            return (
              <div className="filter-dropdown" style={filterPosition}>
                {/* Dropdown content for Date filter */}
                <div className="calender">
                <TimePicker flexDirection={"column"} futureEvents={futureEvents} />
                </div>
              </div>
            );
          case 'language':
            return (
              <div className="filter-dropdown" style={filterPosition}>
                {/* Dropdown content for Language filter */}
                <div className="languages">
                  <div className="langu">Language</div>
                  <div className="laguage-checkbox">
                    {languagesOfIndia.map((language) => (
                      <div key={language} className="language-item">
                        <input
                          type="checkbox"
                          id={language}
                          name={language}
                          checked={selectedLanguages.includes(language)}
                          onChange={() => handleLanguageChange(language)}
                        />
                        <span className='select-language'>{language}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          // case 'discount':
          //   return (
          //     <div className="filter-dropdown" style={filterPosition}>
          //       {/* Dropdown content for Discount filter */}
          //       <div className="discount">
          //         <div className="discount-head">
          //           Discount
          //         </div>
          //         <div className="price-range">
    
    
          //           {discountRange[0]}% - {discountRange[1]}%
          //         </div>
          //         <div className="discount-slider">
          //           <Slider
          //             range
          //             step={10}
          //             defaultValue={[10, 50]}
          //             min={0}
          //             max={75}
          //             onChange={handleDiscountChange}
          //             railStyle={{ backgroundColor: "#959595" }}
          //             trackStyle={{ backgroundColor: "#959595" }}
          //           />
          //           <div className="ranges">
          //             <p>0%</p>
          //             <p>75%</p>
    
          //           </div>
          //         </div>
    
          //       </div>
          //     </div>
          //   );
          case 'price':
            return (
              <div className="filter-dropdown" style={filterPosition}>
                {/* Dropdown content for Price filter */}
                <div className="discount">
                  <div className="discount-head">
                    Price
                  </div>
                  <div className="price-range">
                    ₹ {priceRange[0]} - ₹ {priceRange[1]}
                  </div>
                  <div className="discount-slider">
                    <Slider
                      range
                      step={10}
                      defaultValue={[10, maxPrice]}
                      min={0}
                      max={maxPrice}
                      onChange={handlePriceChange}
                      railStyle={{ backgroundColor: "#959595" }}
                      trackStyle={{ backgroundColor: "#959595" }}
                    />
                    <div className="ranges">
                      <p>Free</p>
                      <p>{maxPrice}</p>
    
                    </div>
                  </div>
                </div>
              </div>
            );
          case 'view':
            return (
              <div className="filter-dropdown" style={filterPosition}>
                {/* Dropdown content for Most-Viewed filter */}
                <div className="view-sold">
                  {categories.map((category) => (
                    <div
                      key={category.key}
                      className={` ${selectedCategory === category.key ? 'selected1' : category.key}`}
                      onClick={() => setSelectedCategory(category.key)}
                    >
                      {category.label}
                    </div>
                  ))}
                </div>
              </div>
            );
          default:
            return null;
        }
      };
    
    const languagesOfIndia = [
        'English',
        'Hindi',
        'Bengali',
        'Telugu',
        'Marathi',
        'Tamil',
        'Urdu',
        'Gujarati',
        'Kannada',
        'Odia',
        'Punjabi',
        'Malayalam',
        'Assamese',
        'Sanskrit',
      ];
    
      // const [selectedLanguages, setSelectedLanguages] = useState([]);
    
      const handleLanguageChange = (language) => {
        if (selectedLanguages.includes(language)) {
          setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
        } else {
          setSelectedLanguages([...selectedLanguages, language]);
        }
      };
    
      const handlePriceChange = (value) => {
        setPriceRange(value);
      };
    
      const handleDiscountChange = (value) => {
        setDiscountRange(value);
      };
  return (
    <div style={{display:"flex",justifyContent:"center" , position:"relative"}}>
       <div className="tab-filters" style={style}>
          <div
            className={`date-filter ${activeFilter === 'date' ? 'active' : ''}`}
            onClick={(e) => {
              const top = e.target.offsetTop + e.target.offsetHeight;
              const left = "-30px"; // Calculate the left value
              toggleFilter('date', { top, left });
            }}          >          
            Date
          </div>
          <div
            className={`language-filter ${activeFilter === 'language' ? 'active' : ''}`}
            onClick={(e) => {
              const top = e.target.offsetTop + e.target.offsetHeight;
              const left = top * 0.20; // Calculate the left value
              toggleFilter('language', { top, left });
            }}          >          
            Language
          </div>
          {/* <div
            className={`discount-filter ${activeFilter === 'discount' ? 'active' : ''}`}
            onClick={(e) => {
              const top = e.target.offsetTop + e.target.offsetHeight;
              const left = top * 0.20; // Calculate the left value
              toggleFilter('discount', { top, left });
            }}          >
            Discount
          </div> */}
          <div
            className={`price-filter ${activeFilter === 'price' ? 'active' : ''}`}
            onClick={(e) => {
              const top = "43px";
              const left = "130px"; // Calculate the left value
              toggleFilter('price', { top, left });
            }}          >
            Price
          </div>
          <div
            className={`view-filter ${activeFilter === 'view' ? 'active' : ''}`}
            onClick={(e) => {
              const top = "43px";
              const left = "230px" // Calculate the left value
              toggleFilter('view', { top, left });
            }}          >          
            Sort
          </div>
        </div>
        {isFilterOpen && renderFilterDropdown()}
    </div>
  )
}

export default Tabfilter
