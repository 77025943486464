import React from 'react'
import "./Partnership.css"
import line from "../../media/line.svg";
import brand1 from "../../media/brandlogo/web.png"
import brand2 from "../../media/brandlogo/soul.png"
import brand3 from "../../media/brandlogo/buzz.svg"
import brand4 from "../../media/brandlogo/home.svg"
import brand5 from "../../media/brandlogo/braj.png"
import brand6 from "../../media/brandlogo/say.png"
import brand7 from "../../media/brandlogo/taneja.svg"
import brand8 from "../../media/brandlogo/fruits.png"
import brand9 from "../../media/brandlogo/lassi.png"
import brand10 from "../../media/brandlogo/give.png"
import brand11 from "../../media/brandlogo/eq.svg"
import brand12 from "../../media/brandlogo/neelam.svg"



import BrandSlider from './BrandSlider';


const PartnerShip = () => {
  const IMAGES = [
    {
      id: 1,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
    {
      id: 2,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
    {
      id: 3,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
    {
      id: 4,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
    {
      id: 5,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
    {
      id: 6,
      src: "/images/image.png",
      alt: "Placeholder image",
    },
  ];

  return (
    <section className="general-section">
      <h2 className="general-heading">Partnerships</h2>
      <img src={line} alt="Line" className="general-line" />

      <div className="all-brands">
        <img src={brand1} alt="" />
        <img src={brand2} alt="" />
        <img src={brand3} alt="" />
        <img src={brand4} alt="" />
        <img src={brand5} alt="" />
        <img src={brand6} alt="" />
        <img src={brand7} alt="" />
        <img src={brand8} alt="" />
        <img src={brand9} alt="" />
        <img src={brand10} alt="" />
        <img src={brand11} alt="" />
        <img src={brand12} alt="" />

      </div>

      {/* <div className="brand-cont">

        <BrandSlider images={IMAGES} slidesToShow={3} />
      </div> */}



    </section>
  )
}

export default PartnerShip