import { React, useState, useEffect } from 'react'
import "./Landingpage.css"
import ImageSlider from '../MainImageSider/ImageSlider'
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import CustomSection from '../../CustomSections/CustomSection';
import GrapYours from '../Grabyour/GrapYours';
import SignupPopUp from '../loginAndSignupScreems/SignupScreen';
import CitySelectionPopup from './CitySelectionPopup';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import line from "../../media/line.svg";


const getImages = (num) => {
  const images = [];
  for (let i = 7; i <= num; i++) {
    images.push({
      img: `https://picsum.photos/seed/${i}/1280/500`
    });
  }
  return images;
};


const LandingPage = () => {
  const { cityName } = useParams();
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [isCitySelectionPopupOpen, setIsCitySelectionPopupOpen] = useState(false);
  const userAuthenticated = useSelector((state) => state.filters.userAuthenticated);


  console.log("slected city", cityName)

  const openSignUp = () => {
    setIsSignUpOpen(true);
  };

  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  const openCitySelectionPopup = () => {
    setIsCitySelectionPopupOpen(true);
  };

  const closeCitySelectionPopup = () => {
    setIsCitySelectionPopupOpen(false);
  };

  useEffect(() => {

    const storedCity = localStorage.getItem('selectedCity');
    if (storedCity) {
      setSelectedCity(storedCity);
      fetchBasicEventDetails(storedCity);

    } else {
      openCitySelectionPopup();
    }
  }, []);



  const images = getImages(8);

  const [carouselData, setCarouseldata] = useState()
  const fetchBasicEventDetails = async (cityName) => {
    try {
      const response = await axios.get(` https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
      const basicDetails = response.data;
      console.log(basicDetails);
      setCarouseldata(basicDetails);
    } catch (error) {
      console.error('Error fetching basic event details:', error);
    }
  };


  const [topBanners, setTopBanners] = useState([]);
  const [baseBanners, setBaseBanners] = useState([]);

  const fetchData = async (cityName) => {
    try {
      const topBannersResponse = await axios.get(`https://api.youthevents.in/publisher/api/top-banners/?city=${cityName}`);
      setTopBanners(topBannersResponse.data);

      const baseBannersResponse = await axios.get(`https://api.youthevents.in/publisher/api/base-banners/?city=${cityName}`);
      setBaseBanners(baseBannersResponse.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
 

  console.log("base and top banner", topBanners, baseBanners)

  const Trendingevents = carouselData ? [...carouselData].sort((a, b) => b.views - a.views) : [];

  console.log("Trendingevents", Trendingevents)

  const findDistinctCategories = (data) => {
    const categoriesSet = new Set(data?.map(event => event.category));
    return Array.from(categoriesSet);
  };

  // Get distinct categories and count them
  const distinctCategories = findDistinctCategories(carouselData);
  const categoriesCount = distinctCategories?.length;

  console.log("Category count:", categoriesCount);
  console.log("Distinct categories:", distinctCategories);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

  useEffect(() => {
    // Create a function to handle window resize events
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [allcitydata, setAllcitydata] = useState([]);


  const fetchallEventDetails = async () => {
    try {
      const response = await axios.get(`https://api.youthevents.in/publisher/all-event-details/?city=${undefined}`);
      const basicDetails = response.data;
      console.log(basicDetails);
      setAllcitydata(basicDetails);
    } catch (error) {
      console.error('Error fetching basic event details:', error);
    }
  };

  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch data
        await Promise.all([
          fetchBasicEventDetails(cityName),
          fetchData(cityName),
          fetchallEventDetails()
        ]);
        // Set isLoading to false after a delay of 1 second (1000 milliseconds)
        setTimeout(() => {
          setIsLoading(false);
        }, 30);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Also set isLoading to false on error
        setIsLoading(false);
      }
    };
  
    // Call the loadData function
    loadData();
  }, [cityName]);
  
  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is loading
  }


  const renderContent = () => {
    if (carouselData?.length === 0) {
      return (
        <>
          <div className="commingsoon">
            <h3>New Events Coming to {cityName} Soon</h3>
            <p>Get Notified Enter Your Email</p>
          </div>
          <div className="email-noti">
            <input type="text" placeholder='Enter Your Email' />
            <button>Notify me</button>

          </div>
          <CustomSection heading={"Explore Events in Other Cities"} seeMoreLink={`/${selectedCity}/Recommended Events`} carouselData={allcitydata} />

        </>
      );
    } else {
      return (
        <>
          <ImageSlider images={topBanners ? topBanners : images} />

          {userAuthenticated ? (
            <>

            </>
          ) : (
            <>
              <div className='explore' >
                <div className='paragraph'>
                  <div >Unlock the City's Pulse: Your Ticket to Youthful Experiences</div>

                </div>

                <div className='signin' >
                  <div onClick={openSignUp} >To unlock more, Sign up now</div>
                </div>
                <SignupPopUp isOpen={isSignUpOpen} onClose={closeSignUp} />
              </div>
            </>
          )}

          {/* Check if the city selection popup should be displayed */}
          {isCitySelectionPopupOpen && (
            <CitySelectionPopup onSelectCity={setSelectedCity} onClose={closeCitySelectionPopup} />
          )}

          {categoriesCount <= 2 && !isSmallScreen && (
            <>
              {distinctCategories.map((categories) => (
                <CustomSection heading={`${categories} Near You`} seeMoreLink={`/${selectedCity}/${categories}`} carouselData={carouselData?.filter((data) => data.category === categories)
                } />
              ))}

            </>
          )}


          {/* crousel data heading and seemorelink has to be passed */}
          <CustomSection heading={"Parties Near You"} seeMoreLink={`/${selectedCity}/Parties Near You`} carouselData={carouselData} />
          {categoriesCount > 2 && categoriesCount <= 5 && !isSmallScreen && (
            <>
              <h3 className="checkoutthings"> Checkout by Categories</h3>
              <img src={line} alt="Line" className="section-line" style={{ marginBottom: "24px" }} />
              <div className="alllinks">
                {distinctCategories.map((category) => (
                  <Link to={`/${selectedCity}/${category}`} className="plink">
                    {category}
                  </Link>
                ))}

              </div>

            </>
          )}
          <CustomSection heading={"Recommended Events"} seeMoreLink={`/${selectedCity}/Recommended Events`} carouselData={carouselData} />
          <GrapYours images={baseBanners ? baseBanners : images} />
          <CustomSection heading={"Trending Events"} seeMoreLink={`/${selectedCity}/Recommended Events`} carouselData={Trendingevents} />
        </>
      );
    }
  };


  return (
    <div className={`landing-page ${carouselData?.length == 0 ? "no-data" : ""}`}>

      {renderContent()}

    </div>
  )
}

export default LandingPage
