import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Assuming user info is stored in localStorage
  const user = JSON.parse(localStorage.getItem("viewer"));

  // Function to fetch the transaction history
  const fetchTransactionHistory = async () => {
    try {
      // Make a GET request to the backend endpoint with the user ID as a query parameter
      const response = await axios.get('https://api.youthevents.in/viewer/transaction-history/', {
        params: {
          user_id: user.id,
        },
      });

      // Update the transactions state with the response data
      setTransactions(response.data.transactions);
      setLoading(false);
    } catch (err) {
      // Handle errors
      console.log("eoor",err)
      setError(err);
      setLoading(false);
    }
  };

  // Fetch transaction history when the component mounts
  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  // Display a loading message while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display an error message if an error occurred
  if (error) {
    return <div className='no-transaction'> {error.response.data.message}</div>;
  }
  return (
    <>
      <h2 className="hist">Transaction History</h2>
      <div className="transaction-history">
        {transactions?.map((transaction) => (
          <div key={transaction.transaction_id} className="data">
            <div className="ids">
              <span className="trans">Transaction ID: {transaction.transaction_id}</span>
              <span className="period">{new Date(transaction.date).toLocaleDateString()}</span>
              <span className="des">Event: {transaction.event_name}</span>
            </div>
            <div className="amounts">
              <span className="amount">Amount: ₹{transaction.amount_paid.toFixed(2)}</span>
              <span className="status">Status: {transaction.booking_status}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default TransactionHistory
