import { useState } from "react";
import left from "../../media/leftarrow.svg"
import right from "../../media/rightarrow.svg"


const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1);
  };

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1);
  };

  return (
    <>
        <div className="slider2">
          <div onClick={prevSlide} className="left2">
            <img src={left} alt="" />
            
          </div>
          <div onClick={nextSlide} className="right2">
            <img src={right} alt="" />
          </div>
          {slides.map((slide, index) => {
            return (
              <div
                className={index === current ? "slide2 active2" : "slide2"}
                key={index}
              >
                {index === current && <img src={slide.image ? `https://res.cloudinary.com/dlztstoso/image/upload/v1/${slide.image}` : slide.file} alt="slide" className="slider-img" />}
              </div>
            );
          })}
        </div>
    </>
  );
};

export default ImageSlider;
