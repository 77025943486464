import { React, useEffect, useState } from 'react'
import "../Sign.css"
import OtpInput from "react-otp-input";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../../redux/filtersSlice';



const EmailSignupScreen = ({onRegisterSuccess}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60); 
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [proceed, setProceed] = useState("Proceed");

  const startTimer = () => {
    setResendDisabled(true); // Disable resend button
    setCountdown(60); // Reset countdown
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer); // Clear the interval when countdown reaches 0
          setResendDisabled(false); // Enable resend button
        }
        return prevCountdown === 0 ? 0 : prevCountdown - 1;
      });
    }, 1000);
  };


  const handleSignup = () => {
    if (email && name && password && confirmPassword && password === confirmPassword) {
      console.log("otp request sending");
      setShowOTP(true);
      axios.post('https://api.youthevents.in/viewer/register/', { email, name, password })
        .then(response => {
          if (response.status === 201) {
            console.log("otp request sent");
            setErrorMessage('');
            startTimer(); 
          } else if (response.status === 200) {
            setShowOTP(false);
            setErrorMessage('User with this email already exists');
            console.error('User with this email already exists');
            setError(true)
          } else {
            setErrorMessage('Failed to initiate OTP generation. Please try again.');

          }
        })
        .catch(error => {
          setShowOTP(false);
          setErrorMessage('Failed to initiate signup. Please check your connection.');
          console.error('Failed to initiate signup. Please check your connection.', error);
        });
    } else {
      setError(true)
      setErrorMessage('Please fill in all fields correctly.');
    }
  };
  

  const resendotp = () => {
      console.log(" otp request sending")
      setShowOTP(true);
      axios.post(' https://api.youthevents.in/viewer/register/', { email, name, password})
        .then(response => {
          if (response.status === 201) {
            console.log(" otp request sent")
            startTimer(); 
            
          } else {
          }
        })
        .catch(error => {
        });
    
  };

  const handleProceed = () => {
    if (code.length === 4) {
      setProceed("Proceeding")
      axios.post(' https://api.youthevents.in/viewer/verify_otp/', { email, name, password, user_type: 'viewer', otp: code })
        .then(response => {
          if (response.status === 201) {
            // window.location.href('/publish-Sign-up')
            setEmail('');
            setName('');
            setPassword('');
            setConfirmPassword('');
            console.log('Google signup successful:', response.data);
            // Handle the successful signup response here
            const { token, user } = response.data;
            localStorage.setItem('viewerToken', token);
            localStorage.setItem('viewer', JSON.stringify(user));
            dispatch(setUserAuthenticated(user));
            onRegisterSuccess();
          } else {
            setErrorMessage('Invalid OTP. Please enter a valid OTP.');
            setCode("")
            setProceed("Proceed")
          }
        })
        .catch(error => {
          setErrorMessage('Failed to verify OTP. Please try again.');
          setCode("")
          setProceed("Proceed")

        });
    } else {
      setErrorMessage('Please enter a valid OTP.');
      setCode("")
      setProceed("Proceed")

    }
  };
  useEffect(() => {
    if (resendDisabled) {
      const timer = setTimeout(() => {
        setResendDisabled(false);
      }, 60000); // Wait for 60 seconds before enabling the resend button again
      return () => clearTimeout(timer);
    }
  }, [resendDisabled]);
  return (
    <>
      {!showOTP ? (
        <div>
          <h2 className='started'>Get Started</h2>
          <div className="google">
            <div className={`general ${error ? 'errored' : ''}`}>

              <input
                className=''
                type="Email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={`name ${error ? 'errored' : ''}`}>

              <input
                className=''
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={`password ${error ? 'errored' : ''}`}>

              <input
                className=''
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={`confirmpass ${error ? 'errored' : ''}`}>

              <input
                className=''
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="errorMessage">{errorMessage}</div>
            <div className="signup-btn-div">
              <button className='signup-btn' onClick={handleSignup}>Signup</button>

            </div>

          </div>
          <div className="privacy">
            privacy policy
          </div>
        </div>

      ) : (
        <div>

          <div className='otp-screen'>
            <h1 className='Enter-otp'>Enter OTP</h1>
            <div className="outer">

              <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={4}
                renderSeparator={<span style={{ width: "12px" }}>  </span>}
                isInputNum={true}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  border: "1px solid transparent",
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none"
                }}
              />
            </div>
            <div className="errorMessage">{errorMessage}</div>
            <span className='line'>
            didn’t receive the OTP?&nbsp;
            {resendDisabled ? (
              `Resend OTP in ${countdown} seconds`
            ) : (
              <span className="resend" onClick={resendotp}>click here to resend OTP</span> 
            )}
          </span>
            <button className='proceed' onClick={handleProceed}>{proceed}</button>
            <div className="privacy">
              privacy policy
            </div>
          </div>
        </div>
      )}

    </>

  )
}

export default EmailSignupScreen
