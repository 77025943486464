import './App.css';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Homescreen from './components/homeScreen/Homescreen';
import LandingPage from './components/LandingPage/LandingPage';
import Aboutus from './components/Aboutus/Aboutus';
import Contactus from './components/Contactus/Contactus';
import Termofuse from './components/Termofuse/Termofuse';
import PrivacyPolicy from './components/Privacy policy/PrivacyPolicy';
import FAQs from './components/FAQs/FAQs';
import AdvertiseWithus from './components/AdvertiseWithus/Advertisewithus';
import PartnerShip from './components/Partnerships/Partnership';
import EventGallery from './components/Event gallery/EventGallary';
import EventCalender from './components/Event calender/EventCalender';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Profile from './components/User Profile/Profile';
import AccountSettings from './components/Account Settings/AccountSetting';
import Notification from './components/Notification/Notification';
import HelpAndSupport from "./components/Help and support/HelpAndSupport"
import ClubsAndParty from './components/EventType/ClubsAndParty';
import Adventure from './components/EventType/Adventure';
import Community from './components/EventType/Community';
import Festivals from './components/EventType/Festivals';
import Music from './components/EventType/Music';
import PersonalGrowth from './components/EventType/PersonalGrowth';
import ShowCraft from './components/EventType/ShowCraft';
import Standups from './components/EventType/Standups';
import EventDetails from './components/Event details/EventDetails';
import YourOrders from './components/Your orders/YourOrders';
import SearchResults from './components/search results/SearchResults';
import SeeMore from './components/See more/SeeMore';
import PublishSign from './Publish Components/Publish Signup/PublishSignin';
import PHomepage from './Publish Components/Publish Homepage/PHomepage';
import Pnavbar from './Publish Components/Publish navbar/Pnavbar';
import PublishedEvents from './Publish Components/Published Events/PublishedEvents';
import Attendees from './Publish Components/Published Events/Attendees';
import Tickets from './Publish Components/Published Tickets/Tickets';
import Settings from './Publish Components/Publish Settings/Settings';
import EventPublish from './Publish Components/Publish an event/EventPublish';
import PProfile from './Publish Components/Publisher Profile/PProfile';
import PNotification from './Publish Components/Message and notifications/PNotification';
import PMessages from './Publish Components/Message and notifications/PMessages';
import PHelpSupport from './Publish Components/PHelpAndSupport/PHelpSupport';
import Numberscreen from './Publish Components/P ask for number/Numberscreen';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from './redux/filtersSlice';
import { gapi } from 'gapi-script';
import EventPublishEdit from './Publish Components/Edit an event/EventPublish';
import OrderedTickets from './components/Ordered Tickets/OrderedTickets';
import EmailOrderedTickets from './components/Ordered Tickets/EmailOrderedTicekt';
import ScrollToTop from './ScrolltoTop';




function App() {
  const dispatch = useDispatch();

  const isUserAuthenticated = () => {
    const token = localStorage.getItem('token');

    return token !== null;
  };

  const viewerToken = localStorage.getItem('viewerToken');
  if (viewerToken) {
    dispatch(setUserAuthenticated(true));

  }
  const clientId = '218720132551-kmgbgee2drhg58apmvgifealr1luvnlc.apps.googleusercontent.com'
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load("client:auth2", start)
  }, [])

  const storedCity = localStorage.getItem('selectedCity');

  const routePath = storedCity ? `/${storedCity}` : '/';



  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">


        <Routes>
          <Route
            path="/"
            element={
              storedCity ? (
                <Navigate to={`/${storedCity}`} replace />
              ) : (
                <>
                  <Navbar />
                  <LandingPage />
                  <Footer />
                  {/* <Numberscreen /> */}
                </>
              )
            }
          />
          {/* Render the city route */}
          <Route
            path="/:cityName"
            element={
              <>
                <Navbar />
                <LandingPage />
                <Footer />
                {/* <Numberscreen /> */}
              </>
            }
          />
          <Route
            path="/about-us"
            element={[
              <Navbar />,
              <Aboutus />,
              <Footer />
            ]}
          />
          <Route
            path="/contact-us"
            element={[
              <Navbar />,
              <Contactus />,
              <Footer />

            ]}
          />
          <Route
            path="/terms-of-use"
            element={[
              <Navbar />,
              <Termofuse />,
              <Footer />

            ]}
          />
          <Route
            path="/privacy-policy"
            element={[
              <Navbar />,
              <PrivacyPolicy />,
              <Footer />

            ]}
          />
          <Route
            path="/faqs"
            element={[
              <Navbar />,
              <FAQs />,
              <Footer />

            ]}
          />
          <Route
            path="/advertise-with-us"
            element={[
              <Navbar />,
              <AdvertiseWithus />,
              <Footer />

            ]}
          />
          <Route
            path="/partnerships"
            element={[
              <Navbar />,
              <PartnerShip />,
              <Footer />

            ]}
          />
          <Route
            path="/:cityName/event-gallery"
            element={[
              <Navbar />,
              <EventGallery />,
              <Footer />

            ]}
          />
          <Route
            path="/:cityName/events-calendar"
            element={[
              <Navbar />,
              <EventCalender />,
              <Footer />

            ]}
          />

          <Route
            path="/home-page"
            element={[
              <Homescreen />

            ]}
          />
          <Route
            path="/profile"
            element={[
              <Navbar />,
              <Profile />,
              <Footer />
            ]}
          />
          <Route
            path="/account-settings"
            element={[
              <Navbar />,
              <AccountSettings />,
              <Footer />
            ]}
          />
          <Route
            path="/notifications"
            element={[
              <Navbar />,
              <Notification />,
              <Footer />
            ]}
          />
          <Route
            path="/help-and-support"
            element={[
              <Navbar />,
              <HelpAndSupport />,
              <Footer />
            ]}
          />

          <Route
            path="/your-orders"
            element={[
              <Navbar />,
              <YourOrders />,
              <Footer />
            ]}
          />
          <Route
            path="/ordered-ticket"
            element={[
              <Navbar />,
              <OrderedTickets />,
              <Footer />
            ]}
          />
          <Route
            path="/email-ordered-ticket"
            element={[
              <Navbar />,
              <EmailOrderedTickets />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Parties"
            element={[
              <Navbar />,
              <ClubsAndParty />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Adventure"
            element={[
              <Navbar />,
              <Adventure />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Community"
            element={[
              <Navbar />,
              <Community />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Festivals"
            element={[
              <Navbar />,
              <Festivals />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Music"
            element={[
              <Navbar />,
              <Music />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Personal growth"
            element={[
              <Navbar />,
              <PersonalGrowth />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Showcraft"
            element={[
              <Navbar />,
              <ShowCraft />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/Standup comedy"
            element={[
              <Navbar />,
              <Standups />,
              <Footer />
            ]}
          />

          <Route path="/event-detail/:eventid"
            element={[
              <Navbar />,
              <EventDetails />,
              <Footer />
            ]}
          />
          <Route
            path="/search/:searchItem"
            element={[
              <Navbar />,
              <SearchResults />,
              <Footer />
            ]}
          />
          <Route
            path="/:cityName/:heading"
            element={[
              <Navbar />,
              <SeeMore />,
              <Footer />
            ]}
          />
          {/* <Route
            path="/publish-Sign-up"
            element={[
              <Pnavbar/>,
              <PublishSign/>,
              <Footer />
            ]}
          />
           <Route
            path="/publish-dashboard"
            element={isUserAuthenticated() ? [<Pnavbar />, <PHomepage />, <Footer />] : <Navigate to="/publish-Sign-up" />}
          />
          <Route
            path="/published-events"
            element={isUserAuthenticated() ? [<Pnavbar />, <PublishedEvents />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
         
          <Route
            path="/published-ticket"
            element={isUserAuthenticated() ? [<Pnavbar />, <Tickets />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
           <Route
            path="/publish-settings"
            element={isUserAuthenticated() ? [<Pnavbar />, <Settings />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
           <Route
           path="/attendees/:id"
            element={isUserAuthenticated() ? [<Pnavbar />, <Attendees />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
           <Route
            path="/publisher/profile"
            element={isUserAuthenticated() ? [<Pnavbar />, <PProfile />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
           <Route
            path="/publish/event"
            element={isUserAuthenticated() ? [<Pnavbar />, <EventPublish />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />

           <Route
            path="/publish/messages"
            element={isUserAuthenticated() ? [<Pnavbar />, <PMessages />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />

           <Route
            path="/publish/notification"
            element={isUserAuthenticated() ? [<Pnavbar />, <PNotification />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
          <Route
            path="/publisher/help-and-support"
            element={isUserAuthenticated() ? [<Pnavbar />, <PHelpSupport />, <Footer />] : <Navigate to="/publish-Sign-up" />}

          />
           <Route path="/manage-event/:id" 
            element={isUserAuthenticated() ? [<Pnavbar />, <EventPublishEdit />, <Footer />] : <Navigate to="/publish-Sign-up" />}
            /> */}


        </Routes>



      </div>
    </BrowserRouter>

  );
}

export default App;
