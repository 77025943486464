import { React, useState } from 'react'
import "../Sign.css";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../../redux/filtersSlice';

const GoogleSignupScreen = ({ googleResponse , onRegisterSuccess }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    console.log("google res in g signup",googleResponse)

    const handleGoogleRegister = () => {
        const { tokenId, profileObj } = googleResponse;

        axios.post(' https://api.youthevents.in/viewer/api/register/google-signup/', JSON.stringify({
            token_id: tokenId,
            email: profileObj?.email,
            name: profileObj?.name,
            image: profileObj?.imageUrl,
            password:password
            // Add any other required user details here
        }), {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            console.log('Google signup successful:', response.data);
            // Handle the successful signup response here
            const { token, user } = response.data;
            localStorage.setItem('viewerToken', token);
            localStorage.setItem('viewer', JSON.stringify(user));
            dispatch(setUserAuthenticated(user));
            onRegisterSuccess();
        })
        .catch(error => {
          
                console.error('Error signing up with Google:', error);
                // Handle errors during the Google signup process

        });


    }


    return (
        <div>
            <h2 className='started'>Get Started</h2>
            <div className="google">
                <div className="name">

                    <input
                        className=''
                        type="text"
                        placeholder="Name"
                        value={googleResponse.profileObj.name}
                        onChange={(e) => setName(e.target.value)}
                        readOnly
                    />
                </div>
                <div className="password">

                    <input
                        className=''
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="confirmpass">

                    <input
                        className=''
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="signup-btn-div">
                    <button className='signup-btn' onClick={handleGoogleRegister}>Signup</button>
                </div>
            </div>
            <div className="privacy">
                privacy policy
            </div>
        </div>
    )
}

export default GoogleSignupScreen
