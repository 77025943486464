import React from "react"
import ContentLoader from "react-content-loader"


const EventDetailLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1080}
    height={600}
    viewBox="0 0 1080 600"
    backgroundColor="#212727"
    foregroundColor="#242424"
    style={{ width: '100%', height: "100%", maxWidth: "1080px" }}

    {...props}
  >
    <rect x="-23" y="116" rx="0" ry="0" width="1040" height="33" />
    <rect x="5" y="175" rx="0" ry="0" width="499" height="173" />
    <rect x="525" y="358" rx="0" ry="0" width="499" height="137" />
    <rect x="10" y="379" rx="0" ry="0" width="493" height="27" />
    <rect x="12" y="424" rx="0" ry="0" width="490" height="26" />
    <rect x="11" y="461" rx="0" ry="0" width="496" height="31" />
    <rect x="517" y="517" rx="0" ry="0" width="499" height="37" />
    <rect x="2" y="8" rx="0" ry="0" width="1040" height="88" />
    <rect x="527" y="173" rx="0" ry="0" width="500" height="174" />
    <rect x="581" y="222" rx="0" ry="0" width="4" height="10" />
  </ContentLoader>
)

export default EventDetailLoader

