import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import x from '../../media/x.svg';

const VerifyOtp = ({ closePopup, verificationType, contactInfo }) => {
    const [code, setCode] = useState('');
    const handleChange = (code) => setCode(code);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [errorMessage, setErrorMessage] = useState('');
    const [proceed, setProceed] = useState('Verify');
    const user = JSON.parse(localStorage.getItem("viewer"));

    console.log("object,",verificationType , contactInfo)

    useEffect(() => {
        // Send OTP when the component mounts
        sendOtp();
    }, []);

    const sendOtp = async () => {
        try {
            // Send OTP to the specified contact info
            const response = await axios.post('https://api.youthevents.in/viewer/send-otp/', {
                verificationType,
                contactInfo,
            });

            // Handle the response
            if (response.status === 201) {
                // Start countdown for resending OTP
                setResendDisabled(true);
                startCountdown();
            } else {
                setErrorMessage('Failed to send OTP.');
            }
        } catch (error) {
            setErrorMessage('Error sending OTP.');
            console.log("errore",error)
        }
    };

    const startCountdown = () => {
        setCountdown(60);
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(intervalId);
                    setResendDisabled(false);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleVerify = async () => {
        try {
            // Verify the OTP using the specified verificationType and contactInfo
            const response = await axios.post('https://api.youthevents.in/viewer/verify-otp/', {
                verificationType,
                contactInfo,
                code,
                user:user.id,
            });

            // Handle the response
            if (response.status === 200) {

                const updatedUserData = {
                    ...user, // Copy existing user data
                    secondary_email: response.data.secondary_email,
                    is_secondary_email_verified: response.data.is_secondary_email_verified,
                    secondary_mobile: response.data.secondary_mobile,
                    is_secondary_mobile_verified: response.data.is_secondary_mobile_verified
                };
                
                // Save the updated user data back to local storage
                localStorage.setItem("viewer", JSON.stringify(updatedUserData));
                setProceed('Verified');
                closePopup();
            } else {
                setErrorMessage('OTP verification failed.');
            }
        } catch (error) {
            setErrorMessage('Error verifying OTP.');
        }
    };

    return (
        <div className='signup-popup3' style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <button className='close-button' onClick={closePopup}>
                <img src={x} alt='' />
            </button> {/* Close button in the top-right corner */}
            <div>
                <div className='otp-screen'>
                    <h1 className='Enter-otp3'>We have sent a verification OTP to your device. Please check.</h1>
                    <div className='outer'>
                        <OtpInput
                            value={code}
                            onChange={handleChange}
                            numInputs={4}
                            renderSeparator={<span style={{ width: '12px' }}> </span>}
                            isInputNum
                            shouldAutoFocus
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                border: '1px solid transparent',
                                borderRadius: '8px',
                                width: '50px',
                                height: '50px',
                                fontSize: '18px',
                                color: '#000',
                                fontWeight: '400',
                                caretColor: 'blue',
                            }}
                            focusStyle={{
                                border: '1px solid #CFD3DB',
                                outline: 'none',
                            }}
                        />
                    </div>
                    <div className='errorMessage'>{errorMessage}</div>
                    <span className='line'>
                        Didn’t receive the OTP?&nbsp;
                        {resendDisabled ? (
                            `Resend OTP in ${countdown} seconds`
                        ) : (
                            <span className='resend' onClick={sendOtp}>Click here to resend OTP</span>
                        )}
                    </span>
                    <button className='proceed3' onClick={handleVerify}>{proceed}</button>
                    <div className='privacy' style={{ marginTop: '30px' }}>
                        Privacy policy
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
