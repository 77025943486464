import { React, useEffect, useState } from 'react'
import organiser from '../../../media/organiser.svg';
import direction from '../../../media/direction.svg';
import invoice from '../../../media/invoice.svg';
import org from '../../../media/org.svg';
import blackorg from '../../../media/blackorg.png';
import ContactOrganizerPopup from './ContactOrgainiserpopup';
import help from '../../../media/help.svg';
import helpblack from '../../../media/helpblack.svg';
import axios from 'axios';



const FailedOrders = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };



    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Assuming user info is stored in localStorage
    const user = JSON.parse(localStorage.getItem("viewer"));

    // Function to fetch the transaction history
    const fetchTransactionHistory = async () => {
        try {
            // Make a GET request to the backend endpoint with the user ID as a query parameter
            const response = await axios.get('https://api.youthevents.in/viewer/transaction-history/', {
                params: {
                    user_id: user.id,
                },
            });

            // Update the transactions state with the response data
            const pendingTransactions = response.data.transactions.filter(transaction => transaction.booking_status === "Pending");

            // Update the transactions state with the filtered data
            setTransactions(pendingTransactions);
            setLoading(false);
        } catch (err) {
            // Handle errors
            console.log("eoor", err)
            setError(err);
            setLoading(false);
        }
    };

    console.log("transactions", transactions)
    // Fetch transaction history when the component mounts
    useEffect(() => {
        fetchTransactionHistory();
    }, []);

    // Display a loading message while data is being fetched

    return (
        <>


            <div className="order-ticket ">
                <div className="blur"></div>

                <div className="banner">
                    <h3>Regrettably, Super Bash 2k23 has been canceled due to unforeseen circumstances</h3>
                    <p>Expect your refund within 2-3 business days.</p>
                    <div className="contacts" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>  <img src={isHovered ? blackorg : org} alt="" /> Contact the Organizers  </div>
                </div>


                <div className="booking-detail">
                    <div className="event-image"></div>
                    <div className="event-buttons">
                        <div className="book-detail">
                            <span className="booking-id">Booking Details</span>
                            <span className="order-id"><b>Order id :</b>123456</span>
                            <span className="booking-date">1 Sep 2023</span>
                        </div>
                        <div className="invoice-location">
                            <div className="invoice">
                                <img src={invoice} alt="" />
                            </div>
                            <div className="locationn-btn">
                                <img src={direction} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="booking-info">

                    <div className="contact-organiser">
                        <button onClick={togglePopup}>
                            <img src={organiser} alt="" />
                            Contact the Organizer
                        </button>
                    </div>
                    {showPopup && <ContactOrganizerPopup onClose={togglePopup} />}

                    <div className="event-name">
                        <div className="book-name">"event.name"</div>
                        <div className="book-language">event.language</div>
                    </div>
                    <div className="date-location">
                        <div className="location-event">"event.location"</div>
                        <div className="event-date">""event.date""</div>
                    </div>
                    <div className="stoke"></div>
                    <div className="ticket-counts1">
                        <div className="ticket-type1">

                            <div className="ticket-name1">
                                General Addmission
                            </div>

                            <div className="ticket-number1">
                                1 Ticket
                            </div>

                            <div className="ticket-price1">₹ 1000</div>
                        </div>
                        <div className="ticket-type1">

                            <div className="ticket-name1">
                                Dance Floor Pass
                            </div>

                            <div className="ticket-number1">
                                2 Ticket
                            </div>

                            <div className="ticket-price1">₹ 14000</div>
                        </div>
                    </div>
                    <div className="total-price1">
                        <div className="prix">
                            ₹ 15000
                        </div>
                    </div>


                </div>
            </div>


            {/*  ----------------------------------------------------------------------------------------------------- */}

            {transactions.map((transaction) => (

                <div className="order-ticket">
                    <div className="blur"></div>

                    <div className="banner">
                        <p>Transaction Failed</p>
                        <h3>Order id : {transaction.transaction_id} , {new Date(transaction.date).toLocaleDateString()} : {transaction.event_name}</h3>
                        <div className="contacts" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> <img src={isHovered ? helpblack : help} alt="" /> Help and Support  </div>

                    </div>
                    <div className="booking-detail">
                        <div className="event-image"></div>
                        <div className="event-buttons">
                            <div className="book-detail">
                                <span className="booking-id">Booking Details</span>
                                <span className="order-id"><b>Order id :</b>123456</span>
                                <span className="booking-date">1 Sep 2023</span>
                            </div>
                            <div className="invoice-location">
                                <div className="invoice">
                                    <img src={invoice} alt="" />
                                </div>
                                <div className="locationn-btn">
                                    <img src={direction} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="booking-info">

                        <div className="contact-organiser">
                            <button onClick={togglePopup}>
                                <img src={organiser} alt="" />
                                Contact the Organizer
                            </button>
                        </div>
                        {showPopup && <ContactOrganizerPopup onClose={togglePopup} />}

                        <div className="event-name">
                            <div className="book-name">"event.name"</div>
                            <div className="book-language">event.language</div>
                        </div>
                        <div className="date-location">
                            <div className="location-event">"event.location"</div>
                            <div className="event-date">""event.date""</div>
                        </div>
                        <div className="stoke"></div>
                        <div className="ticket-counts1">
                            <div className="ticket-type1">

                                <div className="ticket-name1">
                                    General Addmission
                                </div>

                                <div className="ticket-number1">
                                    1 Ticket
                                </div>

                                <div className="ticket-price1">₹ 1000</div>
                            </div>
                            <div className="ticket-type1">

                                <div className="ticket-name1">
                                    Dance Floor Pass
                                </div>

                                <div className="ticket-number1">
                                    2 Ticket
                                </div>

                                <div className="ticket-price1">₹ 14000</div>
                            </div>
                        </div>
                        <div className="total-price1">
                            <div className="prix">
                                ₹ 15000
                            </div>
                        </div>


                    </div>
                </div>

            ))}

        </>
    )
}

export default FailedOrders
