import React, { useState } from 'react';
import "./newsletter.css"
import x from "../../media/x.svg"
import WelcomePopup from './Welcomepopup';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const NewsletterPopup = ({ onClose }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

    const [email, setEmail] = useState('');
    const [subscribed, setSubscribed] = useState(false);
  
    const handleSubscribe = async (values, { setSubmitting }) => {
      try {
          // Send POST request to the server
          await axios.post('https://api.youthevents.in/viewer/subscribe-to-newsletter/', { email: values.email });

          // If successful, set the subscribed state to true
          setSubscribed(true);
      } catch (error) {
          console.error('Error subscribing to newsletter:', error);

          // Optionally, handle error by displaying a message or other feedback
          setSubmitting(false);
      }
  };
  

    const renderContent = () => {
      if (subscribed) {
        // User has subscribed, show the thank you message
        return (
          <WelcomePopup onClose={onClose}/>
        );
      } else {
        // User has not subscribed, show the subscription form
        return (
          <div className="popup-content">
           
           <div className="top-para">
                    Join Your Newsletter Now.
                <div className="news-btn">
                    <button className="close-button-news" onClick={onClose}><img src={x} alt="" style={{height:"17px",width:"17px"}} /></button> {/* Close button in the top-right corner */}
                </div>
                </div>

                <div className="lines" style={{marginTop:"40px"}}>
                    Stay in the loop with the latest events, updates, and
                    exclusive offers from YouthEvents. Subscribe to our
                    newsletter and never miss out on exciting experiences!

                    Simply provide your email address below to start receiving
                    our newsletter:
                </div>
                <Formik
                        initialValues={{ email: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubscribe}
                    >
                        {({ isSubmitting }) => (
                            <Form className="enter-main">
                                <Field
                                    name="email"
                                    type="email"
                                    placeholder="Enter your email"
                                />
                                {/* Display error message for email field */}
                                <ErrorMessage name="email" component="div" className="error-message" />
                                
                                {/* Subscribe button */}
                                <div className="subscribe">
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                <div className="lines" style={{ marginTop: "38px" }}>
                    By subscribing, you agree to receive promotional emails from
                    YouthEvents. You can unsubscribe at any time by clicking the
                    "Unsubscribe" link in our emails. <br />

                    Thank you for joining our community of event enthusiasts. <br />
                    Get ready for a world of thrilling events and
                    unforgettable moments!
                </div>

                <span className="privacy" style={{marginTop:"40px"}}>Privacy policy</span>
          </div>
        );
      }
    };
  
    return (
      <div className="newsletter-popup">
       
        {renderContent()}
        </div>
    );
  };
  
  export default NewsletterPopup;


