import React, { useEffect, useState } from 'react';
import './ticketcheckout.css';
import x from '../../media/x.svg';
import sub from '../../media/sub.svg';
import add from '../../media/add.svg';
import location from '../../media/locationblack.svg';
import axios from 'axios';
import SignupPopUp from '../loginAndSignupScreems/SignupScreen';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import AskForNumEmail from '../Event details/AskForNumEmail';
// import Razorpay from 'razorpay';




const TicketCheckout1 = ({ event, onClose, history }) => {

  const userAuthenticated = useSelector((state) => state.filters.userAuthenticated);

  console.log("event details ", event)




  const [openCategory, setOpenCategory] = useState(null);
  const [ticketCounts, setTicketCounts] = useState({});
  const [step, setStep] = useState(1);
  const [enteredPromoCode, setEnteredPromoCode] = useState('');
  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice(ticketCounts));
  const [promoCodeApplied, setPromoCodeApplied] = useState(false); // Track whether promo code has been applied
  const [promomessage, setPromomessage] = useState(null)
  const [apply, setApply] = useState("Apply")
  const [isPromoSuccess, setIsPromoSuccess] = useState(false);


  function calculateTotalPrice(ticketCounts) {
    let total = 0;
    for (const categoryId in ticketCounts) {
      const count = ticketCounts[categoryId];
      const category = event.ticket_types.find((category) => category.id === Number(categoryId));
      if (category) {
        total += count * category.ticketPrice;
      }
    }
    console.log("tiotal pruce", total)
    return total;

  }

  const handlePromoCodeChange = (e) => {
    setPromoCodeApplied(false)
    setEnteredPromoCode(e.target.value);
    setPromomessage(null)
    setApply("Apply")
    setIsPromoSuccess(false);
    setTotalPrice(calculateTotalPrice(ticketCounts));
  };

  const applyPromoCode = () => {
    const appliedPromoCode = event.promo_codes.find(
      (code) => code.promoCodeName.toUpperCase() === enteredPromoCode.toUpperCase()
    );

    if (appliedPromoCode) {
      const { promo_type, Discount_amount, discountPercentage, minimumPurchase } = appliedPromoCode;

      // Calculate discount based on promo code type
      let discountAmount = 0;

      if (promo_type === 'Fixed Base') {
        discountAmount = Discount_amount;
      } else if (promo_type === 'Percentage Base') {
        discountAmount = (discountPercentage / 100) * totalPrice;
      }

      // Check if the total price meets the minimum purchase requirement
      if (totalPrice < minimumPurchase) {
        setPromomessage("Total amount must be greater than the minimum purchase required for the promo code.");
        setIsPromoSuccess(false);
        return;
      }

      // Ensure discount does not exceed the total price
      if (discountAmount > totalPrice) {
        setPromomessage("Discount amount cannot exceed total price.");
        setIsPromoSuccess(false);
        return;
      }

      // Apply discount and update state
      setTotalPrice(totalPrice - discountAmount);
      setPromoCodeApplied(true);
      setPromomessage("Promo code applied successfully.");
      setApply("Applied");
      setIsPromoSuccess(true);
    } else {
      // Invalid promo code
      setPromomessage("Invalid promo code.");
      setIsPromoSuccess(false);
      setTotalPrice(calculateTotalPrice(ticketCounts));
    }
  };




  const handleCategoryClick = (categoryId) => {
    if (openCategory === categoryId) {
      setOpenCategory(null);
    } else {
      setOpenCategory(categoryId);
    }
  };

  const handleTicketIncrement = (categoryId) => {
    setTicketCounts((prevCounts) => {
      const newCount = (prevCounts[categoryId] || 0) + 1;
      const newTicketCounts = { ...prevCounts, [categoryId]: newCount };

      // Update the total price by adding the price of the selected ticket category
      const category = event?.ticket_types.find((category) => category.id === categoryId);
      if (category) {
        setTotalPrice(totalPrice + category.ticketPrice);
      }

      return newTicketCounts;
    });
  };

  const handleTicketDecrement = (categoryId) => {
    if (ticketCounts[categoryId] > 0) {
      setTicketCounts((prevCounts) => {
        const newCount = prevCounts[categoryId] - 1;
        const newTicketCounts = { ...prevCounts };

        if (newCount === 0) {
          // Remove the category from ticketCounts if the count reaches 0
          delete newTicketCounts[categoryId];
        } else {
          newTicketCounts[categoryId] = newCount;
        }

        // Update the total price by subtracting the price of the selected ticket category
        const category = event?.ticket_types.find((category) => category.id === categoryId);
        if (category) {
          setTotalPrice(totalPrice - category.ticketPrice);
        }

        return newTicketCounts;
      });
    }
  };



  const handleProceedClick = () => {
    // Check if there are any selected tickets
    const selectedTicketCount = Object.values(ticketCounts).reduce((total, count) => total + count, 0);
    if (selectedTicketCount > 0) {
      // Proceed to the next step
      setStep(2);
    } else {
      // Show an error message or handle the case where no tickets are selected
    }
  };

  const handleBackClick = () => {
    // Go back to the previous step
    setStep(1);
  };

  console.log("ticket count is", ticketCounts);

  const renderAvailabilityStatus = (item) => {
    if (item.ticketleft <= 30) {
      return (
        <div className="render-left">
          <div className="">{item.ticketleft} Ticket Remaining</div>
        </div>
      );
    } else if (item.fastFilling) {
      return (
        <div className="render-fast">
          <div className="">Filling Fast</div>
        </div>
      );
    }
    return null; // Don't render anything if conditions are not met
  };


  // const handleCheckoutClick = () => {

  //   const user = JSON.parse(localStorage.getItem("viewer"));
  //   const viewerToken = localStorage.getItem("viewerToken")

  //   const bookingData = {
  //     event_id: event.id,
  //     ticket_category_ids: Object.keys(ticketCounts).map(Number),
  //     quantities: Object.values(ticketCounts),
  //     amount_paid: totalPrice,
  //     user: user.id,
  //   };

  //   // Make a POST request to book the tickets
  //   axios.post(' https://api.youthevents.in/publisher/book_ticket/', bookingData, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('viewerToken')}`, // Include the viewer token
  //     },
  //   })
  //     .then(response => {
  //       // Handle the successful booking response here
  //       console.log('Ticket booking successful:', response.data);
  //       // You can perform further actions, such as redirecting to a confirmation page
  //     })
  //     .catch(error => {
  //       // Handle errors during the booking process
  //       console.error('There was an error booking the tickets:', error);
  //       // You can display an error message or perform other error handling actions
  //     });
  // };


  const [iframeSrc, setIframeSrc] = useState(null)

  const complete_order = (paymentID, orderID, signature)=>{
    axios({
        method: 'post',
        url: 'https://api.youthevents.in/razorpay/order/complete/',
        data: {
            "payment_id": paymentID,
            "order_id": orderID,
            "signature": signature,
            "amount": totalPrice
        }
    })
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{
        console.log(error.response.data);
    })
}



  const handlePaymentAndBooking = async () => {



    const user = JSON.parse(localStorage.getItem("viewer"));
    const paymentData = {
      // Provide necessary payment details
      event_id: event.id,
      ticket_category_ids: Object.keys(ticketCounts).map(Number),
      quantities: Object.values(ticketCounts),
      amount_paid: totalPrice,
      user: user.id,
    };

    try {
      // Initiate payment using Payment API
       await axios.post('https://api.youthevents.in/publisher/pay/', paymentData).then((response) => {

        // get order id
        console.log("response",response)
        var order_id = response.data.data.id

        // handle payment
        const options = {
          key: "rzp_test_JOC0wRKpLH1cVW", // Enter the Key ID generated from the Dashboard
          name: "Youthevents",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {

            //complete order
            complete_order(
              response.razorpay_payment_id,
              response.razorpay_order_id,
              response.razorpay_signature
            )
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        rzp1.open();
      })
        .catch((error) => {
          console.log(error);
        });
      // const paymentStatus = paymentResponse.status; // Assuming the payment response contains status
      // console.log("response", paymentResponse)

      // if (paymentStatus === 201 && paymentResponse.data.data.instrumentResponse.redirectInfo.url) {
      //   // Open the payment URL in a popup window
      //   const paymentUrl = paymentResponse.data.data.instrumentResponse.redirectInfo.url;
      //   window.open(paymentUrl, '_blank');
      //   // window.open(paymentUrl, 'Payment', 'width=600,height=700');
      //   // setIframeSrc(paymentUrl);

      // } else {
      //   // Handle case where there's no redirect URL or payment initiation failed
      //   console.error('Payment initiation failed or no redirect URL provided:', paymentResponse.data);
      // }
      // } catch (error) {
      //   // Handle errors
      //   console.error('Error initiating payment:', error);
      // }

    }catch (error) {
        // Handle errors
        console.error('Error initiating payment:', error);
      }
    }


    console.log("i frame rl ", iframeSrc)
    useEffect(() => {
      // Assuming your app's URL will have a parameter 'paymentStatus=success' upon successful payment
      const urlParams = new URLSearchParams(window.location.search);
      const paymentStatus = urlParams.get('paymentStatus');

      if (paymentStatus === 'success') {
        // If the payment status is successful, proceed to book the tickets
        bookTickets();
      }
    }, [event.id, ticketCounts, totalPrice]); // Ensure dependencies are correctly listed for useEffect

    const bookTickets = async () => {

      const user = JSON.parse(localStorage.getItem("viewer"));

      const bookingData = {
        event_id: event.id,
        ticket_category_ids: Object.keys(ticketCounts).map(Number),
        quantities: Object.values(ticketCounts),
        amount_paid: totalPrice,
        user: user.id,
        // Include other necessary booking details
      };

      try {
        // Make a POST request to book the tickets
        const bookingResponse = await axios.post('https://api.youthevents.in/publisher/book_ticket/', bookingData);
        console.log('Ticket booking successful:', bookingResponse.data);
        // Here, handle the successful booking, such as redirecting to a confirmation page
      } catch (error) {
        // Handle errors during the booking process
        console.error('There was an error booking the tickets:', error);
        // You can display an error message or perform other error handling actions
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
      return formattedDate;
    };

    const [isSignUpOpen, setIsSignUpOpen] = useState(false);


    const openSignUp = () => {
      setIsSignUpOpen(true);
    };

    const closeSignUp = () => {
      setIsSignUpOpen(false);
    };
    const validationSchema = Yup.object().shape({
      fullName: Yup.string()
        .required('Full Name is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'Phone number must contain only digits')
        .min(10, 'Phone number must be at least 10 digits')
        .max(10, 'Phone number must not exceed 10 digits')
        .required('Phone number is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
      // Handle form submission
      const user = JSON.parse(localStorage.getItem("viewer"));
      const paymentData = {
        event_id: event.id,
        ticket_category_ids: Object.keys(ticketCounts).map(Number),
        quantities: Object.values(ticketCounts),
        amount_paid: totalPrice,
        fullName: values.fullName,
        email: values.email,
        phoneNumber: values.phoneNumber,
      };

      try {
        // Initiate payment using Payment API
        const paymentResponse = await axios.post('https://api.youthevents.in/publisher/email-pay/', paymentData);
        const paymentStatus = paymentResponse.status; // Assuming the payment response contains status
        console.log("response", paymentResponse)

        if (paymentStatus === 201 && paymentResponse.data.data.instrumentResponse.redirectInfo.url) {
          // Open the payment URL in a popup window
          const paymentUrl = paymentResponse.data.data.instrumentResponse.redirectInfo.url;
          window.open(paymentUrl, '_blank');
          // window.open(paymentUrl, 'Payment', 'width=600,height=700');
          // setIframeSrc(paymentUrl);
        } else {
          // Handle case where there's no redirect URL or payment initiation failed
          console.error('Payment initiation failed or no redirect URL provided:', paymentResponse.data);
        }
      } catch (error) {
        console.error('Error sending email:', error);
        // Handle errors, display error message or perform other actions
      }

      setSubmitting(false);
    };

    const [showaskpopup, setShowaskpopup] = useState(false)

    const openaskpopup = () => {
      setStep(3);
      setShowaskpopup(true)

    }

    const closeAskpopup = () => {
      setShowaskpopup(false)
      onClose()
    }

    const isSecondaryContactPresent = () => {
      // Retrieve the user data from local storage
      const user = JSON.parse(localStorage.getItem("viewer"));

      // Check if secondary_email or secondary_mobile is present and return true if either is present
      if (user && (user.secondary_email == null || user.secondary_email && user.is_secondary_email_verified == false || user.secondary_mobile || user.secondary_mobile == null && user.is_secondary_mobile_verified == false)) {
        return true;
      }

      // Return false if neither is present
      return false;
    };

    console.log("secondary present", isSecondaryContactPresent())

    // const handleSubmit = async (values, { setSubmitting }) => {
    //   // Handle form submission
    //   console.log('Form values:', values);

    //   try {
    //     // Send Axios request to send email with ticket information
    //     const response = await axios.post('https://api.youthevents.in/publisher/send-ticket-email/', {
    //      event_id: event.id,
    //     ticket_category_ids: Object.keys(ticketCounts).map(Number),
    //     quantities: Object.values(ticketCounts),
    //     amount_paid: totalPrice,
    //     full_name: values.fullName,
    //     recipient_email: values.email,
    //     phoneNumber: values.phoneNumber,
    //       // Include other necessary data for sending the ticket email
    //     });

    //     console.log('Email sent:', response.data);

    //     // Proceed with checkout or any other action after sending the email

    //   } catch (error) {
    //     console.error('Error sending email:', error);
    //     // Handle errors, display error message or perform other actions
    //   }

    //   setSubmitting(false); // Reset submitting state
    // };

    const getLocationStyle = () => {
      // Check if the length of the event location exceeds 50 characters
      if (event?.eventLocation && event.eventLocation.length > 70) {
        return {
          fontSize: '12px'
        };
      }
      // Return an empty object if the length does not exceed 50 characters
      return {};
    };

    return (
      <>
        {isSignUpOpen && (
          <div className="popup-overlay">

            <SignupPopUp isOpen={isSignUpOpen} onClose={closeSignUp} />
          </div>
        )}


        <div className="ticket-checkout">
          {step === 1 && (
            <>
              <button onClick={onClose} className='ticket-checkout-button'>
                <img src={x} alt="" />
              </button>
              <h2 className="ckeck-heading">{event?.eventTitle}</h2>
              <div className="check-detail">
                <div className="ckeck-location" style={getLocationStyle()}>
                  <img src={location} alt="" />
                  {event?.eventLocation}
                </div>
                <div className="ckeck-date-location">
                  <div className="check-date">{formatDate(event?.eventDate)}</div>
                  <div className="location-language">{event?.language}</div>
                </div>
              </div>
              {event?.ticket_types.map((category) => (
                <div className="ticket-category" key={category.id}>
                  <div className="outer-category">
                    <div className="category-inner">
                      <div className="category-detail">
                        {renderAvailabilityStatus(category)} {/* Render availability status */}
                        <span className="category-name">{category.name}</span>
                        <span className="category-price">₹ {category.ticketPrice}</span>
                        <div className="detail-dropdown">
                          <div
                            className={`category-description ${openCategory === category.id ? 'open' : ''}`}
                            onClick={() => handleCategoryClick(category.id)}
                          >
                            {openCategory === category.id ? 'Close' : 'Details'}
                          </div>
                        </div>
                      </div>
                      {ticketCounts[category.id] === undefined || ticketCounts[category.id] === 0 ? (
                        <div className="add-ticket">
                          <div className="add" onClick={() => handleTicketIncrement(category.id)}>
                            Add
                          </div>
                        </div>
                      ) : (
                        <div className="increment-decrement">
                          <div className="decrement" onClick={() => handleTicketDecrement(category.id)}>
                            <img src={sub} alt="" />
                          </div>
                          <div className="ticket-count">{ticketCounts[category.id]}</div>
                          <div className="increment" onClick={() => handleTicketIncrement(category.id)}>
                            <img src={add} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                    {openCategory === category.id && (
                      <div className="description">{category.details}</div>
                    )}
                  </div>
                </div>
              ))}
              <div className="proceed-btn" onClick={handleProceedClick}>
                Proceed
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <button onClick={onClose} className='ticket-checkout-button'>
                <img src={x} alt="" />
              </button>
              <h1 className="Check-details">Check Ticket Details</h1>
              <div className="price-detail" style={{ marginBottom: "15px" }}>
                <div className="checkname">
                  <div className="checkall">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <div className="event-title">{event?.eventTitle}</div>
                      <div className="event-langu">{event?.language}</div>
                    </div>
                  </div>
                  <div className="checkall">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <div className="event-loc">
                        <img src={location} alt="" />
                        {event?.eventLocation}
                      </div>
                      <div className="event-dat">{formatDate(event?.eventDate)}</div>
                    </div>
                  </div>
                </div>
                <div className="promo-area">
                  <div className="promo-text">
                    <input
                      type="text"
                      placeholder="Promocode"
                      value={enteredPromoCode}
                      onChange={handlePromoCodeChange}
                    />
                    {promomessage &&
                      <div className={`promomessage ${isPromoSuccess ? 'promo-success' : ''}`} >{promomessage}</div>}
                  </div>
                  <div className="apply" onClick={applyPromoCode}>
                    {apply}
                  </div>
                </div>
                <div className="total">Total No of Tickets :</div>
                <div className="ticket-counts">
                  {event?.ticket_types.map((category) => (
                    <div className="ticket-type" key={category.id}>
                      {ticketCounts[category.id] > 0 && (
                        <>
                          <div className="ticket-name">
                            {category.name}
                          </div>

                          <div className="ticket-number">
                            {ticketCounts[category.id]} Ticket
                          </div>

                          <div className="ticket-price">₹ {category.ticketPrice * ticketCounts[category.id]}</div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {promoCodeApplied && enteredPromoCode && (
                  <div className="Promo-applied">
                    <div className="code-discount">Promo Code Discount</div>
                    <div className="code">{enteredPromoCode.toUpperCase()}</div>

                    {event.promo_codes.map((code) => {
                      if (code.promoCodeName.toUpperCase() === enteredPromoCode.toUpperCase()) {
                        let discountDescription = '';

                        if (code.promo_type === 'Fixed Base') {
                          discountDescription = `- ₹ ${code.Discount_amount}`;
                        } else if (code.promo_type === 'Percentage Base') {
                          const percentage = code.discountPercentage;
                          const discount = (percentage / 100) * totalPrice;
                          discountDescription = `- ₹ ${discount.toFixed(2)} (${percentage}% off)`;
                        }

                        return (
                          <div key={code.id} className="code-price">
                            {discountDescription}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}

                <div className="total-price"> ₹ {totalPrice}</div>
              </div>

              {!userAuthenticated ? (
                <Formik
                  initialValues={{
                    fullName: '',
                    email: '',
                    phoneNumber: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form style={{ width: "100%" }}>
                      <div className="notsign-details">
                        <div className="not-details">
                          <Field type="text" name="fullName" className="not-input" placeholder="Enter Full Name" />
                          <ErrorMessage name="fullName" component="div" className="error-message" />
                          <Field type="email" name="email" className="not-input" placeholder="Enter Email Address" />
                          <ErrorMessage name="email" component="div" className="error-message" />
                          <Field type="text" name="phoneNumber" className="not-input" placeholder="Enter Phone no." />
                          <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                        </div>
                        <div className="or or2">or</div>
                        <div className="continue-sign" onClick={openSignUp}>
                          Continue with Sign-in
                        </div>
                      </div>
                      <button className="checkout proceed-btn" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Checkout'}
                      </button>
                    </Form>
                  )}
                </Formik>

              ) : (
                <div className="checkout proceed-btn" onClick={isSecondaryContactPresent() ? openaskpopup : handlePaymentAndBooking}>
                  Checkout
                </div>
              )}



              {iframeSrc && (
                <div className="ticket-checkout-popup">
                  <iframe src={iframeSrc} className="payment-iframe" />
                </div>
              )}



            </>
          )}

          {step === 3 && (
            <>
              {showaskpopup && <AskForNumEmail handlePaymentAndBooking={handlePaymentAndBooking} onClose={closeAskpopup} />}
            </>

          )}
        </div>
      </>
    );
  };

  export default TicketCheckout1;
