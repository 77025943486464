import React from 'react'
import "../helpandsupport.css"
import x from "../../../media/x.svg"



const Getbackpopup = ({ onClose, onCheckClick }) => {
    return (
        <div className="popup1">
            <button className="close-button" onClick={onClose}><img src={x} alt="" /></button> {/* Close button in the top-right corner */}
            <div className="content">
                <h3 className="great">Great 🙌 </h3>
                <p className="greatp" style={{marginTop:"0"}}>"Thank you for submitting your ticket! Our team is on it, and we'll get back to you shortly. Your request is important to us."</p>

            </div>
            <div className="check" onClick={onCheckClick}>
                Check
            </div>

        </div>
    )
}

export default Getbackpopup
