import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './filtersSlice'; 

const store = configureStore({
  reducer: {
    filters: filterReducer,
    // Add other reducers here if needed
  },
});

export default store;
