import React, { useState, useEffect } from 'react';
import axios from 'axios';
import line from '../../../media/line.svg';

const ViewerNotificationSetting = () => {
    const [notificationPreferences, setNotificationPreferences] = useState(null);

    useEffect(() => {
        fetchNotificationPreferences();
    }, []);

    const fetchNotificationPreferences = async () => {
        const user = JSON.parse(localStorage.getItem("viewer"));
        try {
            const response = await axios.get(' https://api.youthevents.in/viewer/notification-preferences/', {
                headers: {
                    user:user.id
                }
            });
            setNotificationPreferences(response.data);
        } catch (error) {
            console.error('Error fetching notification preferences:', error);
        }
    };

    const toggleNotificationPreference = async (preference) => {
        try {
            const user = JSON.parse(localStorage.getItem("viewer"));

            const updatedPreferences = { ...notificationPreferences, [preference]: !notificationPreferences[preference] };
            const response = await axios.post(' https://api.youthevents.in/viewer/notification-preferences/', updatedPreferences, {
                headers: {
                    user:user.id
                }
            });
            setNotificationPreferences(response.data);
        } catch (error) {
            console.error('Error toggling notification preference:', error);
        }
    };

    return (
        <div className='noti-main'>
            <h3 className="noti-setting">Notification Setting</h3>

            <div className="noti-head">
                Event Reminders
                <div className="whiteline">
                    <img src={line} alt="" />
                </div>
            </div>
            <div className="upcoming">Upcoming Events
                <div className={`slider1 ${notificationPreferences?.upcoming_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('upcoming_notif')}>
                    <div className={`slider-button ${notificationPreferences?.upcoming_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming">Event Recommendations
                <div className={`slider1 ${notificationPreferences?.event_recommendations_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('event_recommendations_notif')}>
                    <div className={`slider-button ${notificationPreferences?.event_recommendations_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming">New Event Alerts
                <div className={`slider1 ${notificationPreferences?.new_event_alerts_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('new_event_alerts_notif')}>
                    <div className={`slider-button ${notificationPreferences?.new_event_alerts_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="noti-head">
                Account
                <div className="whiteline">
                    <img src={line} alt="" />
                </div>
            </div>
            <div className="upcoming">Activity
                <div className={`slider1 ${notificationPreferences?.activity_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('activity_notif')}>
                    <div className={`slider-button ${notificationPreferences?.activity_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming">Promotions and Offers
                <div className={`slider1 ${notificationPreferences?.promotions_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('promotions_notif')}>
                    <div className={`slider-button ${notificationPreferences?.promotions_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming">Help and Support
                <div className={`slider1 ${notificationPreferences?.help_support_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('help_support_notif')}>
                    <div className={`slider-button ${notificationPreferences?.help_support_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming">General updates
                <div className={`slider1 ${notificationPreferences?.general_updates_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('general_updates_notif')}>
                    <div className={`slider-button ${notificationPreferences?.general_updates_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="noti-head" >
                Ticket
                <div className="whiteline">
                    <img src={line} alt="" />
                </div>
            </div>

            <div className="upcoming">Ticket Updates
                <div className={`slider1 ${notificationPreferences?.ticket_updates_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('ticket_updates_notif')}>
                    <div className={`slider-button ${notificationPreferences?.ticket_updates_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

            <div className="upcoming" style={{marginBottom:"72px"}}>Organizers Updates
                <div className={`slider1 ${notificationPreferences?.organizers_updates_notif ? 'actives' : ''}`} onClick={() => toggleNotificationPreference('organizers_updates_notif')}>
                    <div className={`slider-button ${notificationPreferences?.organizers_updates_notif ? 'actives' : ''}`}></div>
                </div>
            </div>

        </div>
    );
};

export default ViewerNotificationSetting;
