import React, { useState } from "react";
import "./ImageSlider.css"
import { Link } from "react-router-dom";
import styles from "../Event gallery/Image.module.css";
import postloc from "../../media/postloc.png"
import cleft from "../../media/cleft.png"
import cright from "../../media/cright.png"


const ImageSlider = (props) => {
  // const images = [1, 2, 3, 4, 5];
  const { images } = props;
  const [x, setX] = useState(0);
  const lastImageX = -(images?.length - 1) * 100;

  const styled = {
    transform: `translateX(${x}%)`
  };

  const prevSlide = () => {
    setX(x === 0 ? lastImageX : x + 100);
  };
  const nextSlide = () => {
    setX(x === lastImageX ? 0 : x - 100);
  };


  const [play, setplay] = useState(true);
  const [ind, setindex] = useState(0);
  const handleClick = (ind) => {
    // console.log(ind);
    if (ind + 1 > images.length - 1) {
      setindex(0);
    } else {
      setindex(ind);
    }
  };

  function handleleftArrayclick() {
    // console.log("hii" + ind);
    if (ind === 0) {
      setindex(images.length - 1);
    } else {
      let pg = ind - 1;
      setindex(pg);
    }
  }

  function handlerightArrayclick() {
    console.log("hii" + ind);
    if (ind === images.length - 1) {
      setindex(0);
    } else {
      let pg = ind + 1;
      setindex(pg);
    }
  }
  console.log("images", images)

  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  };

  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
        return str?.substring(0, maxLength - 3) + '...';
    } else {
        return str;
    }
}
  return (
    <section className="slider">
      <button onClick={handleleftArrayclick} className="prev_btn">
       <img src={cleft} alt="" className="cleft"/>
      </button>
      <div className="for-text">

        <h3 className="book-now"> {images[ind]?.logo_image && (
          <img src={images[ind].logo_image} alt="" />
        )} {truncateString(images[ind]?.event.eventTitle , 42)}</h3>
        <h3 className="book-now-location">{images[ind]?.tagline}</h3>
        <span className="available-ticket">Tickets Available Now</span>
        <span className="location-date">{truncateString(images[ind]?.event.eventLocation , 32)} : <div className="edate">{formatDate(images[ind]?.event.eventDate)}</div></span>
        <div className="book-now-btn">
          <Link to={`/event-detail/${images[ind]?.event.id}`} className="book" >Book Now</Link>
        </div>

      </div>
      <img
        src={images[ind]?.banner_image || ''}
        alt=""
        className="topbannerimg"
      />
      <button onClick={handlerightArrayclick} className="next_btn">
      <img src={cright} alt="" className="cright"/>
      </button>
    </section>
  );
};

export default React.memo(ImageSlider);
