import React, { useState, useEffect } from 'react';
import './CustomCarousel.css';
import image from "../../media/sliderimg.png"
import date from "../../media/date.svg"
import location from "../../media/location.svg"
import leftarrow from "../../media/leftarrow.svg"
import rightarrow from "../../media/rightarrow.svg"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEventData } from '../../redux/filtersSlice';
import axios from 'axios';

function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    } else {
        return str;
    }
}

function CustomCarousel({ data }) {
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardsToShow, setCardsToShow] = useState(1);

    useEffect(() => {
        // Update the number of cards to show based on data length
        setCardsToShow(1);
        updateDefaultCardsToShow()
    }, [data]);


    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? data.length - cardsToShow : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex >= data.length - cardsToShow ? 0 : prevIndex + 1
        );
    };
    const updateDefaultCardsToShow = () => {
        const carouselContainer = document.querySelector('.carousel-container');
        if (carouselContainer) {
            const containerWidth = carouselContainer.offsetWidth;
            if (containerWidth <= 430) {
                setCardsToShow(1);
            } else if (containerWidth <= 845) {
                setCardsToShow(2);
            } else {
                setCardsToShow(4);
            }
        }
    };

    useEffect(() => {
        updateDefaultCardsToShow();
        window.addEventListener('resize', updateDefaultCardsToShow);
        return () => {
            window.removeEventListener('resize', updateDefaultCardsToShow);
        };
    }, []);

    const getMinTicketPrice = (data) => {
        let minPrice = Infinity;
        data?.ticket_types?.forEach((ticketType) => {
            if (ticketType.ticketPrice < minPrice) {
                minPrice = ticketType.ticketPrice;
            }
        });
        return minPrice;
    };

    const renderAvailabilityStatus = (item) => {
        let totalTicketsLeft = 0;
        item.ticket_types.forEach((ticketType) => {
            totalTicketsLeft += ticketType.availableTickets;
        });

        if (totalTicketsLeft <= 30) {
            return (
                <div className="availability-status-red">
                    <div className="status-tag">{totalTicketsLeft} Ticket Left</div>
                </div>
            );
        } else if (totalTicketsLeft < 100) {
            return (
                <div className="availability-status-yellow">
                    <div className="status-tag">Filling Fast</div>
                </div>
            );
        }
        return (
            <div className="available">
                available
            </div>
        )
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };

    return (
        <div className="carousel-container">
            <div className="carousel">
                {data?.slice(currentIndex, currentIndex + cardsToShow).map((item, index) => (
                    <Link
                        to={{ pathname: `/event-detail/${item.id}` }}
                        key={item.id}
                        className={`carousel-card  `}
                    >
                        {renderAvailabilityStatus(item)}
                        <div className="image">
                            <img src={item.thumbnail_image} alt={item.eventName} />
                        </div>
                        <div className="details">
                            <div className='tittle'>{truncateString(item.eventName, 24)}</div>
                            <div className="icon-text">
                                <img src={date} alt="Date" className="icon" />
                                <span className="date">{formatDate(item.date)}</span>
                            </div>
                            <div className="icon-text">
                                <img src={location} alt="Location" className="icon" />
                                <span className="location">{truncateString(item.location, 35)}</span>
                            </div>
                            <div className="price">₹ {getMinTicketPrice(item)} Onwards</div>
                        </div>
                    </Link>
                ))}
            </div>
            {data?.length > 4 && (
                <>
                    <button onClick={handlePrev} className="prev-button">
                        <img src={leftarrow} alt="&lt;" />
                    </button>
                    <button onClick={handleNext} className="next-button">
                        <img src={rightarrow} alt="&gt;" />
                    </button>

                </>
            )}

        </div>
    );
}

export default CustomCarousel;
