import React, { useEffect, useState, useRef } from 'react'
import "./Orderedtickets.css"
import { Route, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import CustomSection from '../../CustomSections/CustomSection';
import line from "../../media/line.svg";
import downloadin from "../../media/downloadin.svg";
import wl from '../../media/wl.svg';
import view from '../../media/view.svg';
import QRCode from 'qrcode.react';
import QRPop from './QrPop';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Spin } from 'antd';



const EmailOrderedTickets = () => {

    const  cityName  = localStorage.getItem('selectedCity');
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchBasicEventDetails = async (cityName) => {
        try {
            const response = await axios.get(`https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
            const basicDetails = response.data;
            console.log(basicDetails);
            setEvents(basicDetails);
        } catch (error) {
            console.error('Error fetching basic event details:', error);
        }
    };

    const user = JSON.parse(localStorage.getItem("viewer"));


    useEffect(() => {
        fetchBasicEventDetails(cityName);

    }, [cityName]);


    const selectedCity = localStorage.getItem('selectedCity');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const merchantTransactionId = queryParams.get('Ticketid');

    useEffect(() => {
        bookTickets()
    }, []); // Ensure dependencies are correctly listed for useEffect
    const [ticektdata, setTicketData] = useState(null);

    const bookTickets = async () => {

        setLoading(true);

        const bookingData = {
            transactionId: merchantTransactionId,

        };

        try {
            // Make a POST request to book the tickets
            const bookingResponse = await axios.post('https://api.youthevents.in/publisher/email-return-to-me/', bookingData);
            console.log('Ticket booking successful:', bookingResponse.data);
            setTicketData(bookingResponse.data)
            console.log('Ticket booking successful:', ticektdata);
            // Here, handle the successful booking, such as redirecting to a confirmation page
        } catch (error) {
            // Handle errors during the booking process
            console.error('There was an error booking the tickets:', error);
            // You can display an error message or perform other error handling actions
        }
        finally {
            setLoading(false); // Set loading to false after request is complete
        }
    };

    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showQRPopup, setShowQRPopup] = useState(false);

    const openQRPopup = (message) => () => {
        setSelectedMessage(message);
        setShowQRPopup(true)
    };



    const closeQRPopup = () => {
        setShowQRPopup(false);
        setSelectedMessage(null);

    };
    function convertTo12Hour(time) {
        console.log('Time:', time);
        const [hour, minute, second] = time?.split(':')?.map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    }


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };

    const [selectedOrder, setSelectedOrder] = useState(null);
    const pdfTicketRef = useRef(null);

    const downloadPDF = async (order) => {
        // Set the selected order state
        setSelectedOrder(order);

        // Allow time for the PDFTicket component to render with the selected order data
        await new Promise((resolve) => setTimeout(resolve, 2000));

        try {
            // Capture the PDFTicket component as a canvas
            const canvas = await html2canvas(pdfTicketRef.current, {
                useCORS: true,
                scrollY: -window.scrollY,
                willReadFrequently: true
            });

            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/png');

            // Calculate the width and height of the canvas
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Create a new jsPDF instance with custom page size matching the canvas dimensions
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt', // Set units to points
                format: [canvasWidth, canvasHeight] // Use canvas width and height as PDF format
            });

            // Add the image to the PDF, filling the entire custom page
            pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);


            // Save the PDF
            pdf.save('ticket.pdf');

            // Clear selected order state
            setSelectedOrder(null);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };



    console.log("object", selectedOrder)


    return (
        <section className="general-section">
            <h2 className="general-heading">Ticket Confirmed</h2>
            <img src={line} alt="Line" className="general-line" />
            <div className="upper-access">
                <div className="accessyour">
                    You can access the tickets anytime from your orders in the profile section.
                </div>
                {loading ? (
                    <div className="example" style={{ justifyContent: "flex-end" }}>
                        <Spin />
                    </div>
                ) : (
                    <div className="downloadticekt"  >
                        Download Ticket PDF
                        <img src={downloadin} alt="" onClick={() => downloadPDF(ticektdata)} />
                    </div>
                )}
            </div>
            <div className="yourticket">
                <div className="left-tic">
                    {loading ? (
                        <div className="example">
                            <Spin />
                        </div>
                    ) : (
                        <>
                            <div className="ticket-upper">

                                <img src={ticektdata?.event_details?.thumbnail_image} alt="" className="ticketimage" />
                                <div className="bottomleft">
                                    <div className="ticket-titlte">{ticektdata?.event_details?.event_title} </div>
                                    <div className="ticket-address">
                                        <img src={wl} alt="" />
                                        {ticektdata?.event_details?.event_location}
                                    </div>
                                    <div className="ticket-counts1" style={{ width: "100%" }}>
                                        {ticektdata?.booking_details?.map((ticket) => (
                                            <div className="ticket-type1">
                                                <div className="ticket-name1">
                                                    {ticket?.ticket_type_name}
                                                </div>

                                                <div className="ticket-number1" >
                                                    {ticket?.quantity} Ticket
                                                </div>

                                                <div className="ticket-price1" >₹ {ticket?.ticket_price}</div>

                                                {/* <QRCode value={ticket.id} /> */}
                                            </div>
                                        ))}

                                    </div>
                                    <div className="total-price1" style={{ width: "90%" }}>
                                        <div className="prix">
                                            ₹ {ticektdata?.ticket_booking?.amount_paid}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="ticket-booking">
                                <div className="ticekt-details">
                                    <div className="book-language book-languagess">{ticektdata?.event_details?.event_language}</div>
                                    <div className="event-date event-datess">{formatDate(ticektdata?.event_details?.event_date)}</div>
                                    <div className="event-times event-timesss">

                                        {ticektdata?.event_details?.event_time ? convertTo12Hour(ticektdata?.event_details?.event_time) : ""}
                                    </div>

                                </div>
                                <div className="book-detailss">
                                    <span className="booking-id booking-idss">Booking Details</span>
                                    <span className="order-id"><b>Order id :</b>{ticektdata?.ticket_booking?.id}</span>
                                    <span className="booking-date booking-datess">{formatDate((ticektdata?.ticket_booking?.booking_time)?.split("T")[0])}</span>
                                </div>
                            </div>
                        </>
                    )}

                </div>
                <div className="right-tic">
                    {loading ? (
                        <div className="example">
                            <Spin />
                        </div>
                    ) : (
                        <>

                            {ticektdata?.booking_details?.map((ticket) => (
                                <div className="ticket-frame">
                                    <div className="qr-wrapper">

                                        <div className="qr-code qr-codes">
                                            <QRCode value={10} style={{ height: "100px", width: "100px" }} />
                                        </div>
                                        <div className="book-detailssss">
                                            <div className="ticket-names">{ticket.ticket_type_name} </div>
                                            <div className="ticket-no">{ticket.quantity} Ticket </div>
                                            <div className="viewss" onClick={openQRPopup(ticket)}><img src={view} alt="" /> View</div>
                                        </div>
                                    </div>
                                    <div className="tickets-id">{ticket.ticket_id} </div>
                                </div>
                            ))}

                        </>
                    )}

                </div>
            </div>
            <div style={{ zIndex: "-999", position: "absolute", opacity: "0" }}>

                {selectedOrder && (

                    <div className="pdf-ticket" ref={pdfTicketRef}>
                        <div className="pdf-head">
                            <div className="head-left">
                                <div className="logo-head">
                                    <img src='https://res.cloudinary.com/dlztstoso/image/upload/v1714112474/ye_zbk0ri.svg' alt="" />
                                    <div className="head-titles">
                                        <h2>Youth Events</h2>
                                        <h3>This is a YE Tag Line.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="head-right">
                                <h3>Hello, {selectedOrder?.ticket_booking.full_name}</h3>
                                <p>Your Ticket to the Event</p>
                            </div>

                        </div>
                        <div className="silver-detail">
                            <p>Scan the QR Code during Event Check-in .</p>
                        </div>
                        <div className="pdf-detail">
                            <div className="pdf-left">
                                <img style={{ objectFit: "cover" }} src={selectedOrder.event_details.thumbnail_image} alt="" />
                                <div className="pdf-detailing">
                                    <h1>{selectedOrder.event_details.event_title} </h1>
                                    <h2>{selectedOrder.event_details.event_title}</h2>
                                    <h3>{selectedOrder.event_details.event_language}</h3>
                                    <h4>{formatDate(selectedOrder.event_details.event_date)}</h4>
                                    <p>{convertTo12Hour(selectedOrder.event_details.event_time)}</p>

                                </div>
                            </div>

                            <div className="mid-line"></div>

                            <div className="pdf-right">
                                <h2>Booking Details</h2>
                                <h3>Order id : {selectedOrder.ticket_booking.id}</h3>
                                <p>{formatDate((selectedOrder.ticket_booking.booking_time)?.split("T")[1])}</p>


                                <div className="ticket-counts1">

                                    {selectedOrder.booking_details.map((ticket) => (
                                        <div className="ticket-type1">
                                            <div className="ticket-name1 pdfticketname1">
                                                {ticket.ticket_type_name}
                                            </div>

                                            <div className="ticket-number1 pdfticketnumber1">
                                                {ticket.quantity} Ticket
                                            </div>

                                            <div className="ticket-price1 pdfticketprice1">₹ {ticket.ticket_price}</div>

                                            {/* <QRCode value={ticket.id} /> */}
                                        </div>
                                    ))}


                                </div>
                                <div className="total-price1 pdftickettotal1">
                                    Amount Paid
                                    <div className="prix pdfticketprix1">
                                        ₹ {selectedOrder.ticket_booking.amount_paid}
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="qr-area">

                            {selectedOrder.booking_details.map((ticket) => (
                                <div className="pdf-qrdetail">
                                    <QRCode value={ticket.ticket_id} style={{ height: "200px", width: "200px" }} />
                                    <div className="pdfqrinfo">
                                        <h2>{ticket.ticket_type_name} </h2>
                                        <h3>{ticket.quantity} Ticket</h3>
                                        <p>{ticket.ticket_id}</p>
                                    </div>

                                </div>

                            ))}

                            {/* map this one */}


                        </div>

                        <div className="silver-detail" style={{ height: "125px" }}>

                        </div>


                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="email-container" >
                            <tr>
                                <td className="email-header">
                                    <img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/Main_pybu5a.svg" alt="Youth Events Logo" />
                                </td>

                                <td className="email-content">
                                    <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit aliqua.</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="social-icons">
                                        <tr style={{ display: "contents", width: "100%" }}>
                                            <td align="center" >
                                                <a href="your-instagram-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/instagram_jvnq1c.svg" alt="Instagram" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-email-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112473/x_hfoe3p.svg" alt="Email" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-facebook-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112472/fb_d3xqvr.svg" alt="Facebook" /></a>
                                            </td>
                                            <td align="center" >
                                                <a href="your-youtube-url"><img src="https://res.cloudinary.com/dlztstoso/image/upload/v1714112474/youtube_lyxo72.svg" alt="YouTube" /></a>
                                            </td>
                                        </tr>


                                    </table>

                                    <p className='tp' >
                                        <a href="mailto:your-email@example.com">mailtoyouthevent.info</a>
                                    </p>

                                </td>
                            </tr>


                        </table>
                        <table className='righttable'>
                            <tbody style={{ marginLeft: "52px", width: "100%" }}>
                                <tr >
                                    <td>All rights reserved</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                )}
            </div>

            {showQRPopup && <QRPop ticket={selectedMessage} onClose={closeQRPopup} eventdata={ticektdata?.event_details} />}

            <CustomSection heading={"You May Also Like"} seeMoreLink={`/${selectedCity}/You May Also Like`} carouselData={events} />
        </section>
    )
}

export default EmailOrderedTickets
