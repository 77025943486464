import React from "react";
import "./Calendar.css";
const Calendar = ({ onDaySelect, year, month, selectedDate, futureEvents  }) => {

  console.log("future date in calendeer",futureEvents)
  const isFutureEvent = (year, month, day) => {
    const eventDates = futureEvents?.map(event => {
      const eventDate = new Date(event.date);
      return {
        year: eventDate.getFullYear(),
        month: eventDate.getMonth(),
        day: eventDate.getDate()
      };
    });
  
    return eventDates?.some(event => {
      return event.year === year && event.month === month && event.day === day;
    });
  };

  const tableTitles = ["M", "T", "W", "T", "F", "S", "S"];
  const countDaysInMonth = (y, m) => {
    return 33 - new Date(y, m, 33).getDate();
  };
  const getFirstDayOffset = (y, m) => {
    return new Date(y, m, 2).getDay();
  };
  const renderTitle = () => {
    const mapFn = (title, i) => {
      return (
        <div className="table-cell" key={i}>
          {title}
        </div>
      );
    };
    return <div className="table-row">{tableTitles.map(mapFn)}</div>;
  };
  function toMatrix(arr, width) {
    return arr.reduce(function (rows, key, index) {
      index % width === 0 ? rows.push([key]) : rows[rows.length - 1].push(key);
      return rows;
    }, []);
  }
  const renderDays = () => {
    let items = [];
    const days = countDaysInMonth(year, month);
    const offset = getFirstDayOffset(year, month);
  
    for (let j = 1; j < offset - 1; j++) {
      items.push(<div className="table-cell" key={j * -1}></div>);
    }
  
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
  
    for (let i = 1; i <= days; i++) {
      function handleDaySelect() {
        const selectedDate = new Date(year, month, i);
        if (selectedDate >= currentDate) {
          onDaySelect(i);
        }
      }
  
      const isToday =
        year === currentYear && month === currentMonth && i === currentDay;
  
      const isPickedDate =
        selectedDate &&
        year === selectedDate.getFullYear() &&
        month === selectedDate.getMonth() &&
        i === selectedDate.getDate();
  
      const isPastDate =
        year < currentYear ||
        (year === currentYear && month < currentMonth) ||
        (year === currentYear && month === currentMonth && i < currentDay);
  
      const dayClasses = isToday
        ? "table-cell calendar-date today"
        : isPickedDate
        ? "table-cell calendar-date picked"
        : isPastDate
        ? "table-cell calendar-date past-date"
        : "table-cell calendar-date";
  
      const isDisabled = isPastDate || isPickedDate;
      const isEventDate = isFutureEvent(year, month, i);
      console.log("is event date",isEventDate)
      const eventDateClass = isEventDate ? "future-event" : "";
      items.push(
        <div
        className={`${dayClasses}  ${eventDateClass}`}
          onClick={isDisabled ? null : handleDaySelect} // Disable click on past or picked dates
          key={i}
        >
          {i}
        </div>
      );
    }
  
    return toMatrix(items, 7).map((i) => {
      return <div className="table-row">{i}</div>;
    });
  };
  
  
  return (
    <div className="calendar">
      <div className="table">
        {renderTitle()}
        {renderDays()}
      </div>
    </div>
  );
};

Calendar.defaultProps = {
  onDaySelect: () => {},
  year: new Date().getFullYear(),
  month: new Date().getMonth()
};

export default Calendar;
