import React, { useState, useEffect } from 'react';
import './accountsetting.css';
import line from '../../media/line.svg';
import emailnoti from '../../media/emailnoti.svg';
import updatepass from '../../media/updatepass.svg';
import logout from '../../media/logout.svg';
import notisetting from '../../media/notisetting.svg';
import transaction from '../../media/transaction.svg';
import EmailNotification from './settings components/EmailNotification';
import UpdatePassword from './settings components/UpdatePassword';
import ProfileBar from '../ProfileNavbar/ProfileNavbar';
import NotificationSetting from './settings components/NotificationSetting';
import TransactionHistory from './settings components/TransactionHistory';
import axios from 'axios';

const AccountSettings = () => {
  const [selectedOption, setSelectedOption] = useState("updatePassword");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderOptionComponent = () => {
    if (selectedOption === 'updatePassword') {
      return <UpdatePassword />;
    } else if (selectedOption === 'emailNotification') {
      return <EmailNotification />;
    } else if (selectedOption === 'notificationSetting') {
      return <NotificationSetting />;
    } else if (selectedOption === 'Transaction') {
      return <TransactionHistory />;
    }

    return null;
  };

  const handleLogoutClick = () => {
    // Logic for handling logout click
    axios.post(' https://api.youthevents.in/viewer/api/logout/')  // Use the URL defined for logout
    .then(response => {
        console.log('Logged out successfully');
        localStorage.removeItem('isUserAuthenticated');
        localStorage.removeItem("viewerToken")
        localStorage.removeItem("viewer")
        window.location.href = '/'
    })
    .catch(error => {
        // Handle errors
        console.error('Error logging out:', error);
    });
   
   
};

  return (
    <section className="general-section">
      <ProfileBar />
      <h2 className="general-heading">Account Settings</h2>
      <img src={line} alt="Line" className="general-line" />
      <div className="settings">
        <div className="left-div">
          <div
            className={`update-password ${selectedOption === 'updatePassword' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('updatePassword')}
          >
            <img src={updatepass} alt="" />
            Update Password
          </div>
          {windowWidth < 700 && selectedOption === 'updatePassword' && (
            <div className="mobile-view">
              {renderOptionComponent()}
            </div>
          )}
          <div
            className={`email-notification ${selectedOption === 'emailNotification' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('emailNotification')}
          >
            <img src={emailnoti} alt="" />
            Email Notifications
          </div>
          {windowWidth < 700 && selectedOption === 'emailNotification' && (
            <div className="mobile-view">
              {renderOptionComponent()}
            </div>
          )}
          <div
            className={`notification-setting ${selectedOption === 'notificationSetting' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('notificationSetting')}
          >
            <img src={notisetting} alt="" />
            Notification Settings
          </div>
          {windowWidth < 700 && selectedOption === 'notificationSetting' && (
            <div className="mobile-view">
              {renderOptionComponent()}
            </div>
          )}
          <div
            className={`Transaction ${selectedOption === 'Transaction' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('Transaction')}
          >
            <img src={transaction} alt="" />
            Transaction History
          </div>
          {windowWidth < 700 && selectedOption === 'Transaction' && (
            <div className="mobile-view">
              {renderOptionComponent()}
            </div>
          )}
          <div
          onClick={handleLogoutClick}
            className={`logout ${selectedOption === 'log-out' ? 'selected' : ''}`}
          >
            <img src={logout} alt="" />
            Logout
          </div>
        </div>
        {windowWidth >= 600 && <div className="right-div">{renderOptionComponent()}</div>}
      </div>
    </section>
  );
};

export default AccountSettings;
