import React from 'react'
import "./aboutus.css"
import line from "../../media/line.svg";


const Aboutus = () => {
      return (
            <section className="general-section">
                  <h2 className="general-heading">About Us</h2>
                  <img src={line} alt="Line" className="general-line" />

                  <h1 className='about-heading'>Welcome to YouthEvents - Where Experiences Shape Your Youth!</h1>

                  <div className="about-para">

                        <p className='about-para-p'  >At YouthEvents, we're dedicated to curating experiences that empower and inspire
                              the youth. We believe that every moment has the potential to create memories that
                              last a lifetime. Our platform is designed to bring together young minds, vibrant
                              cultures, and exciting events that ignite passions and fuel personal growth.</p>
                  </div>
                  <div className="for-right">

                        <div className="our-mission">
                              <h2>Our Mission</h2>
                              <p>At YouthEvents, our mission is to be the catalyst for youth empowerment through
                                    immersive and engaging experiences. We strive to offer a dynamic range of events
                                    that cater to various interests, from arts and culture to adventure and exploration.
                                    With YouthEvents, you're not just attending an event; you're embarking on a journey
                                    of self-discovery, connection, and personal development.</p>
                        </div>
                  </div>

                  <div className="for-left">

                        <div className="whyus">
                              <h2>Why Choose YouthEvents</h2>
                              <p> <b>Youth-Centric:</b>  Our platform is exclusively focused on providing experiences that
                                    resonate with the energy and enthusiasm of the youth.</p>

                              <p> <b>Diversity:</b>  We celebrate the diverse interests of the youth, offering a kaleidoscope of
                                    events that reflect their passions and aspirations.</p>

                              <p> <b>Inspiration:</b>  We believe that every event has the power to inspire, motivate,
                                    and shape the future of our youth.</p>

                              <p> <b>Safety:</b> Your safety and security are paramount to us. We ensure a safe and
                                    welcoming environment for all our attendees.</p>
                        </div>
                  </div>


            </section>
      )
}

export default Aboutus
