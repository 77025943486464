import React, { useEffect, useState } from 'react';
import OtpInput from "react-otp-input";
import "../Sign.css";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../../redux/filtersSlice';

const LoginOTPScreen = ({ Mobile, onRegisterSuccess }) => {
    const dispatch = useDispatch();
    const [code, setCode] = useState("");
    const handleChange = (code) => setCode(code);
    const [showSignupPopup, setShowSignupPopup] = useState(true);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [proceed, setProceed] = useState("Proceed");
    const [Signin, setSignin] = useState("SignUp");
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);


    const startTimer = () => {
        setResendDisabled(true); // Disable resend button
        setCountdown(60); // Reset countdown
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                    clearInterval(timer); // Clear the interval when countdown reaches 0
                    setResendDisabled(false); // Enable resend button
                }
                return prevCountdown === 0 ? 0 : prevCountdown - 1;
            });
        }, 1000);
    };

    const handleProceed = () => {
        if (code.length === 4) {
            setProceed("Proceeding")
            axios.post(' https://api.youthevents.in/viewer/verify-viewer-otp/', { phone_number: Mobile, otp: code })
                .then(response => {
                    if (response.status === 200) {
                        console.log("OTP Verified successfully");
                        const { token, user } = response.data;
                        localStorage.setItem('viewerToken', token);
                        localStorage.setItem('viewer', JSON.stringify(user));
                        dispatch(setUserAuthenticated(user));
                        onRegisterSuccess();

                    } else if (response.status === 201) {
                        setShowSignupPopup(false);
                        setErrorMessage("")
                        setProceed("Proceed")

                    } else {
                        setErrorMessage('Invalid OTP. Please enter a valid OTP.');
                        setCode("")
                        setProceed("Proceed")

                    }
                })
                .catch(error => {
                    setErrorMessage('Failed to verify OTP. Please try again.');
                    setCode("")
                    setProceed("Proceed")

                });
        } else {
            setErrorMessage('Please enter a valid OTP.');
            setCode("")
            setProceed("Proceed")

        }
    };



    const resendotp = () => {
        const mobile = Mobile;

        axios.post(' https://api.youthevents.in/viewer/register-mobile/', mobile, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                startTimer();
                // Handle the successful registration response here
                console.log('User registered successfully:', response.data);
            })
            .catch(error => {
                // Handle errors during the registration process
                console.error('There was an error registering the user:', error);
            });
    };

    const handleMobileRegister = () => {

        if (password == confirmPassword) {
            setSignin("Signin up...")

            axios.post(' https://api.youthevents.in/viewer/register-viewer-from-Mobile/', JSON.stringify({
                name: name,
                password: password,
                phone_number: Mobile
            }), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    console.log('Google signup successful:', response.data);
                    // Handle the successful signup response here
                    const { token, user } = response.data;
                    localStorage.setItem('viewerToken', token);
                    localStorage.setItem('viewer', JSON.stringify(user));
                    dispatch(setUserAuthenticated(user));
                    onRegisterSuccess();

                })
                .catch(error => {

                    console.error('Error signing up with Google:', error);
                    // Handle errors during the Google signup process
                    setSignin("Signup")

                });

        } else {
            setErrorMessage('password does not match');
            setSignin("Signup")
        }




    }

    useEffect(() => {
        if (resendDisabled) {
            const timer = setTimeout(() => {
                setResendDisabled(false);
            }, 60000); // Wait for 60 seconds before enabling the resend button again
            return () => clearTimeout(timer);
        }
    }, [resendDisabled]);

    return (
        <div>
            {showSignupPopup ? (
                <div className='otp-screen'>
                    <h1 className='Enter-otp'>Enter OTP</h1>
                    <div className="outer">
                        <OtpInput
                            value={code}
                            onChange={handleChange}
                            numInputs={4}
                            renderSeparator={<span style={{ width: "12px" }}> </span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                border: "1px solid transparent",
                                borderRadius: "8px",
                                width: "50px",
                                height: "50px",
                                fontSize: "18px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                            }}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                    </div>
                    <div className="errorMessage">{errorMessage}</div>
                    <span className='line'>
                        didn’t receive the OTP?&nbsp;
                        {resendDisabled ? (
                            `Resend OTP in ${countdown} seconds`
                        ) : (
                            <span className="resend" onClick={resendotp}>click here to resend OTP</span>
                        )}
                    </span>
                    <button className='proceed' onClick={handleProceed}>{proceed}</button>
                    <div className="privacy">
                        privacy policy
                    </div>
                </div>
            ) : (
                <div className='signup-popup signup-popup1'>
                    <h2 className='started'>Get Started</h2>
                    <div className="google">
                        <div className="name">
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="password">
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="confirmpass">
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <div className="errorMessage">{errorMessage}</div>

                        <div className="signup-btn-div">
                            <button className='signup-btn' onClick={handleMobileRegister}>{Signin}</button>
                        </div>
                    </div>
                    <div className="privacy">
                        privacy policy
                    </div>
                </div>
            )}
        </div>
    );
}

export default LoginOTPScreen;
