import './eventdetail.css';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import plus from "../../media/plus.svg"
import minus from "../../media/minus.svg"
import share from "../../media/share.svg"
import copy from "../../media/copy.svg"
import ln from "../../media/ln.png"
import red from "../../media/red.png"
import sah from "../../media/sah.png"
import twitterIcon from "../../media/twitter.svg";
import facebookIcon from "../../media/facebook.svg";
import instagramIcon from "../../media/instagram.svg";
import location from "../../media/location.svg";
import CustomSection from '../../CustomSections/CustomSection';
import TicketCheckout1 from '../Ticket checkout/TicketCheckout1';
import ImageSlider from './ImageSlider2';
import ImageSlider3 from './ImageSilder3';
import axios from "axios"
import { useParams } from 'react-router-dom'; // Import useParams
import EventDetailLoader from './EventDetailsLoader';


function EventDetails() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [event, setEvent] = useState(null);
    const [isTicketCheckoutOpen, setIsTicketCheckoutOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');

    const { eventid } = useParams();
    useEffect(() => {
        // Fetch event details when the component mounts
        const fetchEventDetails = async () => {
            try {
                const response = await axios.get(` https://api.youthevents.in/publisher/full-event-details/${eventid}/`);
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };

        fetchEventDetails();
    }, []);

    useEffect(() => {

        const storedCity = localStorage.getItem('selectedCity');
        if (storedCity) {
            fetchBasicEventDetails(storedCity);
            setSelectedCity(storedCity);
        }
    }, []);

    const [carouselData, setCarouseldata] = useState()
    const fetchBasicEventDetails = async (cityName) => {
        try {
            const response = await axios.get(` https://api.youthevents.in/publisher/basic-event-details/?city=${cityName}`);
            const basicDetails = response.data;
            console.log(basicDetails);
            setCarouseldata(basicDetails);
        } catch (error) {
            console.error('Error fetching basic event details:', error);
        }
    };


    if (!event) {
        return (
            <div className="event-details">

                <EventDetailLoader />;
            </div>
        )

    }

    console.log("event data is", event)


    function Accordian() {


        const toggleAccordion = (index) => {
            if (activeIndex === index) {
                // Clicking the active accordion item again to close it
                setActiveIndex(-1);
            } else {
                setActiveIndex(index);
            }
        };


        return (
            <div id='faqs' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {/* Container div with black border */}
                <div className='accordian-item'>
                    {event.terms_conditions.map((item, index) => (
                        <div
                            key={index}
                            className={`accordion-item ${index === activeIndex ? 'active5' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="accordion-question">
                                {item.title}
                                <span className="accordion-icon">
                                    {index === activeIndex ? (
                                        <img src={minus} alt="Minus" /> // Use the minus image
                                    ) : (
                                        <img src={plus} alt="Plus" /> // Use the plus image
                                    )}
                                </span>
                            </div>
                            {index === activeIndex && (
                                <div className="accordion-answer"> {/* Split the answer text by "•" and wrap each item with a line break */}
                                    {item.description?.split('•').map((text, i) => (
                                        <div key={i}>
                                            {/* • */}
                                            {text.trim()}
                                        </div>
                                    ))}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    const openTicketCheckout = () => {
        setIsTicketCheckoutOpen(true);
    };

    const closeTicketCheckout = () => {
        setIsTicketCheckoutOpen(false);
    };

    const openInGoogleMaps = () => {
        if (event && event.eventLocation) {
            const addressQuery = encodeURIComponent(event.eventLocation);
            window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`, '_blank');
        }
    };

    const copyToClipboard = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl)
            .then(() => {
                alert('URL copied to clipboard!');
            })
            .catch((error) => {
                console.error('Failed to copy URL: ', error);
            });
    };

    // let totalTicketsLeft = 0;
    // event.ticket_types.forEach((ticketType) => {
    //     totalTicketsLeft += ticketType.availableTickets;
    // });


    const formatDate = (dateString) => {
        const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    };

    const shareToTwitter = () => {
        const currentUrl = window.location.href;
        const shareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}`;
        window.open(shareUrl, '_blank');
    };

    const shareToFacebook = () => {
        const currentUrl = window.location.href;
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
        window.open(shareUrl, '_blank');
    };

    const shareToReddit = () => {
        const currentUrl = window.location.href;
        const eventTitle = event ? event.eventTitle : 'Event';
        const shareUrl = `https://www.reddit.com/submit?url=${currentUrl}&title=${encodeURIComponent(eventTitle)}`;
        window.open(shareUrl, '_blank');
    };

    // Function to share on LinkedIn
    const shareToLinkedIn = () => {
        const currentUrl = window.location.href;
        const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`;
        window.open(shareUrl, '_blank');
    };

    const renderAvailabilityStatus = (item) => {

        let totalTicketsLeft = 0;
        item?.ticket_types?.forEach((ticketType) => {
            totalTicketsLeft += ticketType.availableTickets;
        });


        if (totalTicketsLeft <= 30) {
            return (
                <div className="left availability-red">
                    <div className="status-tag">Only {totalTicketsLeft} Ticket Left</div>
                </div>
            );
        } else if (totalTicketsLeft < 100) {
            return (
                <div className="left availability-yellow">
                    <div className="status-tag">Filling Fast</div>
                </div>
            );
        }
        return (
            <div className="left">
                available
            </div>

        )
    }
    function convertTo12Hour(time) {
        const [hour, minute, second] = time?.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    const openShareMenu = () => {
        // Check if the Web Share API is supported
        if (navigator.share) {
            // Define the data to share
            const shareData = {
                title: event?.eventTitle,
                text: `Check out this event: ${event?.eventTitle}`,
                url: window.location.href, // The current page URL
            };

            // Trigger the share menu with the data
            navigator.share(shareData)
                .then(() => {
                    console.log('Content shared successfully');
                })
                .catch((error) => {
                    console.error('Error sharing content:', error);
                });
        } else {
            // If the Web Share API is not supported, provide a fallback (optional)
            alert('Sharing is not supported in your browser');
        }
    };

    return (
        <div className='event-details'>
            {isTicketCheckoutOpen && (
                <div className="ticket-checkout-popup">
                    <TicketCheckout1 event={event} onClose={closeTicketCheckout} />
                </div>
            )}
            <div className="card-detail">
                <img src={event?.thumbnail_image} alt="" className="thumb" />
                <div className="event-typo">
                    <div className="time-date">
                        <div className="event-dates">{formatDate(event?.eventDate)}</div>
                        <div className="event-time">{convertTo12Hour(event?.eventTime)} onwards</div>
                        <div className="event-category">{event?.event_category}</div>
                    </div>
                    <div className={`event-tittle ${event.eventTitle.length > 22 ? 'large-font' : ''}`}>
                        {event?.eventTitle}
                    </div>
                    <div className="event-language">
                        {event?.language} | {event?.eventDuration} Hours
                    </div>

                </div>
                <div className="ticket-left">
                    <div >
                        {renderAvailabilityStatus(event)}
                    </div>
                    <div className="shareswap">
                        <div className="grab-your" onClick={openTicketCheckout}>
                            Grab Your Tickets
                        </div>
                        <img src={sah} alt="" className="sharesa" onClick={openShareMenu}/>

                    </div>


                </div>
            </div>
            <div className="event-location">
                <div className="addres">
                    <img src={location} alt="" />{event?.eventLocation}
                </div>
                <div className="on-map" onClick={openInGoogleMaps}>
                    View on Map
                </div>
            </div>
            <div className="about-insight">
                <div className="about-event">
                    <div className="event-about">
                        <h2>About</h2>
                        <p>{event?.eventDescription}</p>
                    </div>

                </div>
                <div className="insight">
                    <span className="gallery-head">
                        Insight
                    </span>
                    <ImageSlider3 imgdetails={event.insights} />
                </div>
            </div>
            <div className="term-gallery">
                <div className="term">
                    <span className="terms-condition">
                        Terms and conditions
                    </span>
                    {Accordian()}
                </div>
                <div className="gallery-share">
                    <div className="gallery">
                        <span className="gallery-head">

                            Gallery
                        </span>
                        <ImageSlider slides={event.gallery_images} />
                    </div>
                    <div className="share">
                        <span className='shar' >
                            <img src={share} alt="" />
                            Share
                        </span>
                        <div className="shares">

                            <div className="share-option" onClick={copyToClipboard}><img src={copy} alt="Copy" /></div>
                            {/* <div className="share-option" onClick={shareToInstagram}><img src={instagramIcon} alt="Instagram" /></div> */}
                            <div className="share-option" onClick={shareToFacebook}><img src={facebookIcon} alt="Facebook" /></div>
                            <div className="share-option" onClick={shareToTwitter}><img src={twitterIcon} alt="Twitter" /></div>
                            <div className="share-option" onClick={shareToLinkedIn}><img src={ln} alt="Twitter" /></div>
                            <div className="share-option" onClick={shareToReddit}><img src={red} alt="Twitter" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomSection heading={"You May Also Like"} seeMoreLink={`/${selectedCity}/Recommended Events`} carouselData={carouselData} />

        </div>
    );
}

export default EventDetails;
