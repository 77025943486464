import { useEffect, useState } from "react";
import styles from "./ImageSlider.module.css";
import Imagecomp2 from "./Imagecomp2";
import Para from "./Para";
import leftarrow from "../../media/leftarrow.svg"
import rightarrow from "../../media/rightarrow.svg"



function ImageSlider3({imgdetails}) {
  const [play, setplay] = useState(true);
  const [ind, setindex] = useState(0);
  const handleClick = (ind) => {
    // console.log(ind);
    if (ind + 1 > imgdetails.length) {
      setindex(0);
    } else {
      setindex(ind);
    }
  };
  
  function handleleftArrayclick() {
    // console.log("hii" + ind);
    if (ind === 0) {
      setindex(imgdetails.length - 1);
    } else {
      let pg = ind - 1;
      setindex(pg);
    }
  }

  function handlerightArrayclick() {
    console.log("hii" + ind);
    if (ind === imgdetails.length -1 ) {
      setindex(0);
    } else {
      let pg = ind + 1;
      setindex(pg);
    }
  }
  return (
    <div className={styles.cont}>
       <div className={styles.footer}>

          <div className={styles.eventprev} onClick={handleleftArrayclick}>
            <img src={leftarrow} alt="" />
          </div>

          <div className={styles.eventnext} onClick={handlerightArrayclick}>
            <img src={rightarrow} alt="" />
          </div>


      </div>
      <div className={styles.cont1}>
        <div>
          <img
            style={{ width: "100%", height: "300px", objectFit:"cover" }}
            src={`https://res.cloudinary.com/dlztstoso/image/upload/v1/${imgdetails[ind]?.image}`}
            alt=""
          />
        </div>
        <div className="box2">
          <Para
            name={imgdetails[ind]?.title}
            subTitle={imgdetails[ind]?.subTitle}
            description={imgdetails[ind]?.description}
          />
        </div>
      </div>
     
    </div>
  );
}

export default ImageSlider3;
