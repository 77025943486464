import React, { useEffect, useState } from 'react'
import "../helpandsupport.css"
import dot from "../../../media/dot.svg"
import axios from 'axios'

const PreviousTicket = () => {


  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserTickets = async () => {

      const user = JSON.parse(localStorage.getItem("viewer"));

      try {
        // Fetch user tickets from the backend
        const response = await axios.get(' https://api.youthevents.in/viewer/user-tickets/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            user: user.email
          },
        });

        // Update the tickets state with the fetched data
        setTickets(response.data.tickets.reverse());
      } catch (error) {
        // Handle errors
        setError(error.response.data.error);
      }
    };

    fetchUserTickets();
  }, []);

  const formatDate = (dateString) => {
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  };


  console.log("tickets", tickets)
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <h2 className='Ticket-heading'>Previous Tickets</h2>
      <div className='raise-div'>
        {tickets.length > 0 ? (
          <>
            {tickets.map((ticket, index) => (

              <div className="ticket-id" key={index}>
                <div className="close">
                  <span className="id">#TKT-12345</span>
                  <span className="open-close"><img src={dot} alt='' /> {ticket.status}</span>
                </div>
                <div className="inner-cover">

                  <div className="info">
                    <div className="issue-btn">

                      <div className="issue">
                        <h3>Issue:</h3>
                        <p>{ticket.subject}</p>
                      </div>

                    </div>
                    <div className="date-submitted">
                      <h3>Date Submitted:</h3>
                      <p>{formatDate(ticket.created_at)}</p>
                    </div>
                    <div className="issue-description">
                      <p><b>Description:</b>{ticket.message}</p>

                    </div>

                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='noe' style={{fontSize:"22px"}}>
            No  Previous Ticket Raised 
          </div>
        )}


      </div>
    </div>
  )
}

export default PreviousTicket
